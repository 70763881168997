// src/styles/CompetitionStyles.js

import { createGlobalStyle } from "styled-components";
import { styleReset } from "react95";

const CompetitionStyles = createGlobalStyle`
  ${styleReset}

  
*, *::before, *::after {
}



html, body {
  font-family: 'ms_sans_serif';
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.desktopBackground};
  overflow-x: hidden; /* Prevents horizontal scroll */
  overflow-y: auto;   /* Allows vertical scroll */
}


  body::-webkit-scrollbar { 
    display: none;  /* Chrome, Safari, and Opera */
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }

  .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    text-align: center;
    background: none;
  }

  .carousel img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .custom-table {
    display: block;
    width: 100%;
    max-height: 600px;  // Adjust based on your UI requirements
    overflow-y: auto;   // Enables scrolling on the y-axis
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer 10+ */

    /* For Webkit browsers like Chrome, Safari, and Opera */
    &::-webkit-scrollbar { 
      display: none;
    }
  }

  .custom-table {
    display: block;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar { 
      display: none;
    }
  }

  .custom-thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .custom-tbody {
    display: block;
    width: 100%;
  }

  .custom-tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .custom-th, .custom-td {
    display: table-cell;
  }

  .custom-td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .carousel-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  /* Custom styles for CompetitionDetailsPage */

.competition-item-wrapper {
  width: 100%;
  /* Remove min-height: 100vh; */
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: flex-start; /* Aligns items to the top */
  justify-content: center;
}





  .competition-window {
    height: auto;
    width: 60%;
    max-width: 800px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1290px) {
      width: 98%;
    }
  }

  .window-header-styled {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 2%;
  }

  .countdown-timer {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    font-size: 2rem;
    justify-content: center;
  }

  .styled-frame {
    display: flex;
  }

  .window-content-styled {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .competition-content {
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }

  .competition-content-2 {
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .token-avatar {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .competition-details {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    p {
      font-weight: bold;
    }
  }

  .competition-details-2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    p {
      margin-top: 5%;
      font-size: 3rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      text-align: center;
    }
  }

.competition-details-3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 5%;
    font-size: clamp(2rem, 5vw, 3rem);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .competition-details-3 {
    flex-direction: column;
    align-items: center;
  }

  .competition-details-3 p {
    white-space: normal; /* Allow text wrapping */
  }
}


  .contract-address {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  .group-box-styled {
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 10px;
    width: 120px;
    display: flex;
    justify-content: center;
  }

    .group-box-styled-ticket-price {
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 10px;
    width: 120px;
    height: 95px;
    display: flex;
    justify-content: center;
  }

  .group-box-styled-2 {
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 10px;
    width: 250px;
    height: 95px;
    display: flex;
    justify-content: center;
  }

  .group-box-styled-3 {
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 10px;
    width: 250px;
    height: 75px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .token-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .token-name-wrapper {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    height: 100%;

    p {
      font-size: 2rem;
      font-weight: bold;
    }
  }

    .token-text-wrapper {
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 100%;
  }

  .wrapper {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }

  .wrapper-2 {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .progress-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .responsive-frame {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem;
    padding: 0.5rem;

    @media (max-width: 1290px) {
      flex-direction: column;
    }
  }

  .large-button {
    font-size: 3rem;
    padding: 1rem 2rem;
    width: 100%;
    height: 120px;
    variant: primary;
    width: 250px;
  }

  .number-input-wrapper {
    margin-top: 0.5rem;
  }

  .group-boxes-container {
    display: flex;
    gap: 10px;
    width: 95%;
    margin: 10px auto;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .group-boxes-container2 {
    display: flex;
  
    gap: 10px;
    width: 95%;
    margin: 10px auto;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

    .group-boxes-container3 {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    margin: 10px auto;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  .group-box-styled-responsive {
    flex: .7;
    min-width: 100px;
    display: flex;
    width: 85%;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 10px;
    }

    p {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  .window-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  }

 .PopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 1000;
  }





`;

export default CompetitionStyles;
