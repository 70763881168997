import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const GlobalJackpotDisplayWrapper = styled.div`
  font-weight: bold;
  cursor: pointer;
  justify-content: center;
  display: flex;
  text-align: center;
  letter-spacing: 5px;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 400% 100%;
  animation: gradientMove 3s ease-in-out infinite;

  @keyframes gradientMove {
    0%,
    100% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 0%;
    }
  }
`;

const GlobalJackpotDisplay = ({
  jackpotAmount,
  onClick,
  liveUpdatesEnabled,
}) => {
  const [jackpotAnimationState, setJackpotAnimationState] = useState("initial");
  const [isNarrowScreen, setIsNarrowScreen] = useState(
    window.innerWidth <= 1920
  );
  const [backgroundGradient, setBackgroundGradient] = useState("");
  const [fontSize, setFontSize] = useState("4rem"); // Default font size

  // Function to generate a random hex color
  const getRandomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  // Function to create a dynamic gradient with random colors
  const generateRandomGradient = () => {
    return `linear-gradient(45deg, ${getRandomColor()}, ${getRandomColor()}, ${getRandomColor()}, ${getRandomColor()})`;
  };

  useEffect(() => {
    // Set initial random gradient
    setBackgroundGradient(generateRandomGradient());

    // Function to update screen width and font size dynamically
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth <= 1024);

      // Dynamically set font size based on screen width
      const newFontSize = Math.max(2.5, window.innerWidth / 500) + "rem"; // Scales proportionally
      setFontSize(newFontSize);
    };

    handleResize(); // Run initially
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (liveUpdatesEnabled) {
      setBackgroundGradient(generateRandomGradient());
      setJackpotAnimationState("updated");
    }
  }, [jackpotAmount, liveUpdatesEnabled]);

  const jackpotVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.2 },
    tap: { scale: 0.8 },
    updated: {
      scale: [1, 1.2, 1],
      transition: { duration: 0.4 },
    },
  };

  useEffect(() => {
    setJackpotAnimationState("updated");
  }, [jackpotAmount]);

  const calculateDisplayAmount = (amount) => {
    if (amount == null) return "0.00";
    const numericAmount = Number(amount);
    if (numericAmount < 1)
      return (Math.floor(numericAmount * 1000) / 1000).toString();
    if (numericAmount < 10)
      return (Math.floor(numericAmount * 100) / 100).toFixed(2);
    if (numericAmount < 100)
      return (Math.floor(numericAmount * 10) / 10).toFixed(1);
    return Math.floor(numericAmount).toString();
  };

  const displayAmount = calculateDisplayAmount(jackpotAmount);

  return (
    <motion.div
      variants={jackpotVariants}
      initial="initial"
      animate={jackpotAnimationState}
      whileHover="hover"
      whileTap="tap"
      onAnimationComplete={() => {
        if (jackpotAnimationState === "updated") {
          setJackpotAnimationState("initial");
        }
      }}
      onClick={onClick}
    >
      <GlobalJackpotDisplayWrapper
        style={{
          backgroundImage: backgroundGradient, // Apply dynamic gradient to text
          fontSize: fontSize, // Apply dynamic font size
        }}
      >
        GLOBAL JACKPOT {isNarrowScreen && <br />}
        {displayAmount} SOL
      </GlobalJackpotDisplayWrapper>
    </motion.div>
  );
};

export default GlobalJackpotDisplay;
