import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  Window,
  WindowContent,
  WindowHeader,
  Avatar,
  Frame,
  Counter,
  GroupBox,
  ProgressBar,
} from "react95";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import Marquee from "react-fast-marquee";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Predefined logos with corresponding link field names
const LOGOS = [
  { src: "/logos/solana.svg", linkField: "solana_link" },
  { src: "/logos/website.svg", linkField: "website_link" },
  { src: "/logos/telegram.svg", linkField: "telegram_link" },
  { src: "/logos/x.svg", linkField: "x_link" },
  { src: "/logos/discord.svg", linkField: "discord_link" },
];

// Function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Rainbow effect text for statuses (FAILED, FINISHED, LIVE)
const RainbowText = styled.span`
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(to right, ${({ colors }) => colors.join(", ")});
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 400% 100%;
  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

// Rainbow text header for live titles
const RainbowTextHeader = styled.span`
  will-change: transform;
  backface-visibility: hidden;
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(to right, ${({ colors }) => colors.join(", ")});
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 400% 100%;
  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

// TitleText container (max-width 250px on desktop, 200px on mobile)
const TitleText = styled.span`
  flex: 1 1 0;
  min-width: 0;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;

// MarqueeSpacer adds space at the end of the marquee text.
const MarqueeSpacer = styled.span`
  display: inline-block;
  width: 10px;
`;

// Styled components for logos
const LogoContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100px;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const LogoImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
`;

// Smaller logo variant for mobile social icons
const MobileLogoImage = styled(LogoImage)`
  width: 18px;
  height: 18px;
`;

// MarqueeContainer to constrain the marquee width
const MarqueeContainer = styled.div`
  flex: 1;
  min-width: 0;
  max-width: 350px;
  overflow: hidden;
  @media (max-width: 768px) {
    max-width: 250px;
  }
  & > div {
    max-width: 100%;
  }
`;

// Jackpot text styling
const JackpotText = styled.p`
  font-size: clamp(2rem, 2.13vw, 3.5rem) !important;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  background: ${({ isJackpot, colors }) =>
    isJackpot ? `linear-gradient(to right, ${colors.join(", ")})` : "inherit"};
  -webkit-background-clip: ${({ isJackpot }) =>
    isJackpot ? "text" : "inherit"};
  background-clip: ${({ isJackpot }) => (isJackpot ? "text" : "inherit")};
  color: ${({ isJackpot }) => (isJackpot ? "transparent" : "inherit")};
  animation: ${({ isJackpot }) =>
    isJackpot ? "rainbow_animation 3s ease-in-out infinite" : "none"};
  background-size: 400% 100%;
  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

const CompetitionItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CompetitionWindow = styled(Window)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 2px;
  max-width: 100%;
  box-sizing: border-box;
`;

const CountdownTimer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
`;

const StyledFrame = styled(Frame)`
  display: flex;
`;

const WindowContentStyled = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
`;

const CompetitionContent = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TokenAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const CompetitionDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
  p {
    font-weight: bold;
  }
`;

const CompetitionDetails2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  p {
    font-size: clamp(1rem, 2.13vw, 2.13rem);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

// Added onClick to prevent event propagation when copying
const ContractAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const GroupBoxStyled = styled(GroupBox)`
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100px;
  display: flex;
  justify-content: center;
`;

const GroupBoxStyled2 = styled(GroupBox)`
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px;
  width: 225px;
  display: flex;
  justify-content: center;
`;

// This styled component is used in mobile layout for both group boxes.
const GroupBoxStyled3 = styled(GroupBox)`
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
`;

const TokenDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const TokenNameWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  p {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-direction: column;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  position: relative;
`;

// New styled components for the mobile layout
const MobileRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px; /* Gap between left and right columns */
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    display: none;
  }
`;

// Added gap to space out the pfp, contract address and social icons
const MobileLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const MobileRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

// Social icons container in mobile layout (no gap)
const MobileSocials = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

const formatPrizeAmount = (amount) => {
  const num = parseFloat(amount);
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

const calculateRemainingTime = (endTime) => {
  const now = new Date().getTime();
  const distance = new Date(endTime).getTime() - now;
  if (distance < 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return { days, hours, minutes, seconds };
};

const truncateAddress = (address) =>
  `${address.slice(0, 4)}...${address.slice(-4)}`;

const formatTicketPrice = (price) => {
  const num = parseFloat(price);
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

// CompetitionItem Component
const CompetitionItem = ({ competition }) => {
  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(competition.end_time)
  );
  const [copied, setCopied] = useState(false);
  const [feePercentages, setFeePercentages] = useState(null);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // For mobile: measure jackpot box width and apply it to ticket price box
  const jackpotRef = useRef(null);
  const [jackpotWidth, setJackpotWidth] = useState(null);
  useEffect(() => {
    if (jackpotRef.current) {
      setJackpotWidth(jackpotRef.current.offsetWidth);
    }
  }, [isMobile, competition]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const poolPercentage = competition.pool_percentage;
  const tokenBuyPercentage = competition.token_buy_percentage;
  const minimumPool = competition.minimum_pool;
  const currentAmount = formatPrizeAmount(competition.prize_pool || 0);
  const progressPercentage = Math.min(
    ((currentAmount / minimumPool) * 100).toFixed(1),
    100
  );

  useEffect(() => {
    const fetchFeePercentages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/fee-percentages`);
        setFeePercentages(response.data);
      } catch (error) {
        console.error("Error fetching fee percentages:", error);
        setError("Failed to fetch fee percentages.");
      }
    };
    fetchFeePercentages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime(competition.end_time));
    }, 1000);
    return () => clearInterval(interval);
  }, [competition.end_time]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  // Memoize random colors for consistency during re-renders
  const randomColors = useMemo(() => {
    return [
      getRandomColor(),
      getRandomColor(),
      getRandomColor(),
      getRandomColor(),
    ];
  }, []);

  const formatTime = (time) => {
    return `${time.days.toString().padStart(2, "0")}${time.hours
      .toString()
      .padStart(2, "0")}${time.minutes
      .toString()
      .padStart(2, "0")}${time.seconds.toString().padStart(2, "0")}`;
  };

  const timeString = formatTime(remainingTime);
  const isLive = progressPercentage === 100 && timeString !== "00000000";
  const isFinished = timeString === "00000000";

  const computedTitleBase =
    competition.header && competition.header.trim() !== ""
      ? `${competition.header.trim().replace(/\s+/g, "_")}_${
          competition.token_symbol
        }_${tokenBuyPercentage}_${poolPercentage}`
      : `${competition.token_symbol}_${tokenBuyPercentage}_${poolPercentage}_${
          feePercentages ? feePercentages.TOTAL_FEES_PERCENTAGE : "Loading..."
        }`;

  const computedTitle = isLive
    ? `${computedTitleBase}_LIVE.exe`
    : isFinished
    ? `${computedTitleBase}_FINISHED.exe`
    : `${computedTitleBase}.exe`;

  // Create a common style object for mobile group boxes using the measured jackpot width
  const commonWidthStyle =
    jackpotWidth !== null
      ? { width: jackpotWidth, minWidth: jackpotWidth }
      : {};

  // Mobile Layout
  if (isMobile) {
    return (
      <CompetitionItemWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CompetitionWindow>
          <WindowHeaderStyled>
            <MarqueeContainer>
              <Marquee play={!isHovered} gradient={false} speed={20}>
                {isLive ? (
                  <RainbowTextHeader colors={randomColors}>
                    <TitleText>{computedTitle}</TitleText>
                    <MarqueeSpacer />
                  </RainbowTextHeader>
                ) : (
                  <>
                    <TitleText>{computedTitle}</TitleText>
                    <MarqueeSpacer />
                  </>
                )}
              </Marquee>
            </MarqueeContainer>
            <CountdownTimer>
              <StyledFrame>
                <Counter value={timeString} size="sm" />
              </StyledFrame>
            </CountdownTimer>
          </WindowHeaderStyled>

          <WindowContentStyled>
            <div>
              {/* Token Name */}
              <TokenNameWrapper>
                <p>{competition.token_name}</p>
              </TokenNameWrapper>
              {/* Two-column row: Left (pfp, contract & socials) and Right (jackpot & ticket price) */}
              <MobileRow>
                <MobileLeft>
                  <TokenAvatar>
                    <img
                      src={competition.token_icon}
                      alt={competition.token_symbol}
                    />
                  </TokenAvatar>
                  {/* Social buttons under contract address, using smaller icons */}
                  <MobileSocials>
                    <LogoContainer>
                      {LOGOS.map((logo, index) => {
                        const link = competition[logo.linkField];
                        if (!link) return null;
                        return (
                          <a
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MobileLogoImage
                              src={logo.src}
                              alt={`logo-${index}`}
                            />
                          </a>
                        );
                      })}
                    </LogoContainer>
                  </MobileSocials>
                  <p>
                    <ContractAddress onClick={(e) => e.stopPropagation()}>
                      {competition.token && truncateAddress(competition.token)}
                      <CopyToClipboard
                        text={competition.token}
                        onCopy={() => setCopied(true)}
                      >
                        <FaCopy
                          className="copy-icon"
                          onClick={(e) => e.stopPropagation()}
                        />
                      </CopyToClipboard>
                    </ContractAddress>
                  </p>
                </MobileLeft>
                <MobileRight>
                  {/* Jackpot group box with a ref to measure its width */}
                  <div ref={jackpotRef}>
                    <GroupBoxStyled3 label="Jackpot" style={commonWidthStyle}>
                      <JackpotText
                        isJackpot={progressPercentage >= 100}
                        colors={randomColors}
                      >
                        {currentAmount} SOL
                      </JackpotText>
                    </GroupBoxStyled3>
                  </div>
                  {/* Ticket Price group box gets the same width as the Jackpot box */}
                  <GroupBoxStyled3
                    label="Ticket Price"
                    style={commonWidthStyle}
                  >
                    <p>{formatTicketPrice(competition.ticket_price)} SOL</p>
                  </GroupBoxStyled3>
                </MobileRight>
              </MobileRow>
              {/* Progress Bar */}
              <ProgressWrapper>
                <ProgressBar
                  value={progressPercentage}
                  hideValue={
                    progressPercentage === 100 || timeString === "00000000"
                  }
                />
                {timeString === "00000000" ? (
                  progressPercentage < 100 ? (
                    <RainbowText colors={randomColors}>FAILED</RainbowText>
                  ) : (
                    <RainbowText colors={randomColors}>FINISHED</RainbowText>
                  )
                ) : progressPercentage === 100 ? (
                  <RainbowText colors={randomColors}>LIVE!</RainbowText>
                ) : null}
              </ProgressWrapper>
            </div>
          </WindowContentStyled>
        </CompetitionWindow>
      </CompetitionItemWrapper>
    );
  }

  // Desktop Layout (unchanged except for marquee play control)
  return (
    <CompetitionItemWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CompetitionWindow>
        <WindowHeaderStyled>
          <MarqueeContainer>
            <Marquee play={!isHovered} gradient={false} speed={20}>
              {isLive ? (
                <RainbowTextHeader colors={randomColors}>
                  <TitleText>{computedTitle}</TitleText>
                  <MarqueeSpacer />
                </RainbowTextHeader>
              ) : (
                <>
                  <TitleText>{computedTitle}</TitleText>
                  <MarqueeSpacer />
                </>
              )}
            </Marquee>
          </MarqueeContainer>
          <CountdownTimer>
            <StyledFrame>
              <Counter value={timeString} size="sm" />
            </StyledFrame>
          </CountdownTimer>
        </WindowHeaderStyled>
        <WindowContentStyled>
          <div>
            <TokenNameWrapper>
              <p>{competition.token_name}</p>
            </TokenNameWrapper>
            <CompetitionContent>
              <TokenDetails>
                <TokenAvatar>
                  <img
                    src={competition.token_icon}
                    alt={competition.token_symbol}
                  />
                </TokenAvatar>
                <p>
                  <ContractAddress onClick={(e) => e.stopPropagation()}>
                    {competition.token && truncateAddress(competition.token)}
                    <CopyToClipboard
                      text={competition.token}
                      onCopy={() => setCopied(true)}
                    >
                      <FaCopy
                        className="copy-icon"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </CopyToClipboard>
                  </ContractAddress>
                </p>
              </TokenDetails>
              <Wrapper>
                <CompetitionDetails2>
                  <GroupBoxStyled2 label="Jackpot">
                    <JackpotText
                      isJackpot={progressPercentage >= 100}
                      colors={randomColors}
                    >
                      {currentAmount} SOL
                    </JackpotText>
                  </GroupBoxStyled2>
                </CompetitionDetails2>
                <CompetitionDetails>
                  <GroupBoxStyled label="Ticket Price">
                    <p>{formatTicketPrice(competition.ticket_price)} SOL</p>
                  </GroupBoxStyled>
                  <LogoContainer>
                    {LOGOS.map((logo, index) => {
                      const link = competition[logo.linkField];
                      if (!link) return null;
                      return (
                        <a
                          key={index}
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <LogoImage src={logo.src} alt={`logo-${index}`} />
                        </a>
                      );
                    })}
                  </LogoContainer>
                </CompetitionDetails>
              </Wrapper>
            </CompetitionContent>
            <ProgressWrapper>
              <ProgressBar
                value={progressPercentage}
                hideValue={
                  progressPercentage === 100 || timeString === "00000000"
                }
              />
              {timeString === "00000000" ? (
                progressPercentage < 100 ? (
                  <RainbowText colors={randomColors}>FAILED</RainbowText>
                ) : (
                  <RainbowText colors={randomColors}>FINISHED</RainbowText>
                )
              ) : progressPercentage === 100 ? (
                <RainbowText colors={randomColors}>LIVE!</RainbowText>
              ) : null}
            </ProgressWrapper>
          </div>
        </WindowContentStyled>
      </CompetitionWindow>
    </CompetitionItemWrapper>
  );
};

export default CompetitionItem;
