import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import {
  Window,
  WindowContent,
  WindowHeader,
  ProgressBar,
  Button,
  Counter,
  GroupBox,
  NumberInput,
} from "react95";
import GlobalJackpotDisplay from "../components/GlobalJackpotDisplay";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CompetitionStyles from "../styles/CompetitionStyles";
import * as web3 from "@solana/web3.js";
import { useProgram } from "../App";
import BN from "bn.js";
import CustomReact95Toast from "../components/CustomReact95Toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationContext } from "../context/NotificationContext";
import { createPortal } from "react-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const { Buffer } = require("buffer");

// Styled components
const GlobalJackpotPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const JackpotWindow = styled(Window)`
  height: auto;
  width: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (max-width: 1290px) {
    width: 98%;
  }
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 2%;
`;

const WindowContentStyled = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const GroupBoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
`;

const GroupBoxStyledResponsive = styled(GroupBox)`
  flex: 1;
  min-width: 100px;
  text-align: center;
  p {
    font-size: 2rem;
    font-weight: bold;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
  padding-bottom: 2%;
  margin-bottom: 3%;
`;

const ResponsiveDiv = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const ResponsiveDiv2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

// Modal overlay and processing popup styled components (used for the popup)
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProcessingPopup = styled(Window)`
  width: 300px;
  text-align: center;
`;

const GlobalJackpotPage = () => {
  const [userData, setUserData] = useState(null);
  const [timeString, setTimeString] = useState("");
  const [counterSize, setCounterSize] = useState("lg");
  const [ticketAmount, setTicketAmount] = useState(1);
  // Replace simple confirmation flag with a multi-phase popup:
  // null = no popup, "confirm" = show confirmation UI, "processing" = show processing UI
  const [popupPhase, setPopupPhase] = useState(null);
  const [purchaseType, setPurchaseType] = useState("buy"); // 'buy' or 'topOff'

  const { wallet, publicKey } = useWallet();
  const { connection } = useConnection();
  const { program } = useProgram();
  const navigate = useNavigate();
  const { socketRef, liveUpdatesEnabled, jackpot, totalTickets, participants } =
    useContext(NotificationContext);

  const [jackpotConfig, setJackpotConfig] = useState({
    price_per_ticket: "",
    end_time: "",
  });

  const fetchUserData = async () => {
    if (!publicKey) return;
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/users/${publicKey.toString()}/global-jackpot`
      );
      console.log("fetchUserData response:", response.data);
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    // On mount or whenever publicKey changes
    fetchUserData();
  }, [publicKey]);

  useEffect(() => {
    // Resize logic
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCounterSize("md");
      } else {
        setCounterSize("lg");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleInputChange = (value) => {
    if (!isNaN(value) && value > 0) {
      setTicketAmount(value);
    } else {
      setTicketAmount(1);
    }
  };

  const handleBuyClick = () => {
    setPurchaseType("buy");
    setPopupPhase("confirm");
  };

  const handleTopOffClick = () => {
    setPurchaseType("topOff");
    setPopupPhase("confirm");
  };

  const handleCancelBuy = () => {
    setPopupPhase(null);
  };

  // -------------
  // MAIN PURCHASE / TOP OFF TX
  // -------------
  const handleConfirmBuy = async () => {
    // Switch popup UI to processing phase
    setPopupPhase("processing");

    // Check if the competition/jackpot has finished
    const currentTime = new Date();
    const jackpotEndTime = new Date(jackpotConfig.end_time);
    if (currentTime >= jackpotEndTime) {
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Competition Finished"
            message="The competition has finished. Purchases are no longer allowed."
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
      setPopupPhase(null);
      return;
    }

    if (!publicKey || !wallet) {
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Wallet"
            message="Please connect your wallet to proceed."
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
      console.error("Wallet not connected.");
      setPopupPhase(null);
      return;
    }

    try {
      console.log("Checking participant Devnet balance...");
      const participantBalanceLamports = await connection.getBalance(publicKey);
      const participantBalanceSOL =
        participantBalanceLamports / web3.LAMPORTS_PER_SOL;
      console.log("Participant Devnet Balance:", participantBalanceSOL, "SOL");

      // 1) Derive PDAs
      console.log("Deriving PDAs...");
      const [globalJackpotPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("global_jackpot")],
        program.programId
      );
      const [globalJackpotTicketPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("global_jackpot_ticket"), publicKey.toBuffer()],
        program.programId
      );
      const [protocolConfigPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_config")],
        program.programId
      );
      const [protocolFeeAccountPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_fee_account")],
        program.programId
      );
      const [influencerFeeAccountPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("influencer_fee_account")],
        program.programId
      );
      console.log("PDAs Derived:", {
        globalJackpotPDA: globalJackpotPDA.toBase58(),
        globalJackpotTicketPDA: globalJackpotTicketPDA.toBase58(),
        protocolConfigPDA: protocolConfigPDA.toBase58(),
        protocolFeeAccountPDA: protocolFeeAccountPDA.toBase58(),
        influencerFeeAccountPDA: influencerFeeAccountPDA.toBase58(),
      });

      // 2) Fetch protocol and influencer fee percentages
      const configResponse = await axios.get(
        `${API_BASE_URL}/api/public-global-jackpot-config`
      );
      const {
        protocol_fee_percentage,
        influencer_fee_percentage,
        price_per_ticket,
      } = configResponse.data;

      console.log("Fetched config:", {
        protocol_fee_percentage,
        influencer_fee_percentage,
        price_per_ticket,
      });

      // 3) Prepare amounts in lamports
      const ticketPriceLamports = new BN(
        parseFloat(price_per_ticket) * web3.LAMPORTS_PER_SOL
      );
      const ticketCountBN = new BN(ticketAmount);
      const purchaseAmount = ticketPriceLamports.mul(ticketCountBN);

      // ------------
      // Use ON-CHAIN leftover: userData.totalContribution (in SOL)
      // ------------
      const onChainPartialSol = userData?.totalContribution
        ? parseFloat(userData.totalContribution)
        : 0;

      console.log("User's on-chain partial leftover (SOL):", onChainPartialSol);

      // Price per ticket in SOL
      const pTicket = parseFloat(price_per_ticket);
      let neededForOneTicket = Math.max(0, pTicket - onChainPartialSol);

      // Convert that needed portion into lamports
      const topOffAmountBN = new BN(
        Math.floor(neededForOneTicket * web3.LAMPORTS_PER_SOL)
      );

      // 4) Fees
      const protocolFee =
        purchaseType === "buy"
          ? purchaseAmount.mul(new BN(protocol_fee_percentage)).div(new BN(100))
          : topOffAmountBN
              .mul(new BN(protocol_fee_percentage))
              .div(new BN(100));

      const influencerFee =
        purchaseType === "buy"
          ? purchaseAmount
              .mul(new BN(influencer_fee_percentage))
              .div(new BN(100))
          : topOffAmountBN
              .mul(new BN(influencer_fee_percentage))
              .div(new BN(100));

      const totalFees = protocolFee.add(influencerFee);
      const totalPayment = topOffAmountBN.add(totalFees);

      console.log("Calculated Payment and Fees:", {
        purchaseType,
        ticketPriceLamports: ticketPriceLamports.toString(),
        purchaseAmount: purchaseAmount.toString(),
        onChainPartialSol,
        neededForOneTicket,
        topOffAmountBN: topOffAmountBN.toString(),
        protocolFee: protocolFee.toString(),
        influencerFee: influencerFee.toString(),
        totalFees: totalFees.toString(),
        totalPayment: totalPayment.toString(),
      });

      // 5) Create the transaction + instructions
      const transaction = new web3.Transaction();

      if (purchaseType === "buy") {
        const instruction = await program.methods
          .purchaseGlobalJackpotTickets(ticketCountBN)
          .accounts({
            globalJackpot: globalJackpotPDA,
            globalJackpotTicket: globalJackpotTicketPDA,
            participant: publicKey,
            protocolFeeAccount: protocolFeeAccountPDA,
            influencerFeeAccount: influencerFeeAccountPDA,
            protocolConfig: protocolConfigPDA,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();
        transaction.add(instruction);
      } else if (purchaseType === "topOff") {
        const instruction = await program.methods
          .topOffGlobalJackpot()
          .accounts({
            globalJackpot: globalJackpotPDA,
            globalJackpotTicket: globalJackpotTicketPDA,
            participant: publicKey,
            protocolFeeAccount: protocolFeeAccountPDA,
            influencerFeeAccount: influencerFeeAccountPDA,
            protocolConfig: protocolConfigPDA,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();
        transaction.add(instruction);
      }

      // 6) Finalize & send TX
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      console.log("Prepared transaction:", transaction);

      const signedTransaction = await wallet.adapter.signTransaction(
        transaction
      );
      console.log("Signed transaction:", signedTransaction);

      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize(),
        { skipPreflight: false, preflightCommitment: "processed" }
      );
      console.log("Transaction signature:", signature);

      console.log("Waiting for finalization...");
      await connection.confirmTransaction(signature, "finalized");
      console.log("Transaction finalized on-chain.");

      // 7) Log to backend
      const payload = {
        user_id: publicKey.toBase58(),
        amount: totalPayment.toString(),
        ticket_count: purchaseType === "buy" ? ticketAmount : undefined,
        topOff: purchaseType === "topOff",
        txSignature: signature,
        blockhash,
        details: {
          ticketPriceLamports: ticketPriceLamports.toString(),
          purchaseAmount: purchaseAmount.toString(),
          onChainPartialSol,
          neededForOneTicket,
          topOffAmount: topOffAmountBN.toString(),
          protocolFee: protocolFee.toString(),
          influencerFee: influencerFee.toString(),
          totalFees: totalFees.toString(),
          totalPayment: totalPayment.toString(),
        },
      };
      console.log("Sending tx details to backend:", payload);

      const response = await axios.post(
        `${API_BASE_URL}/api/global-jackpot/purchase`,
        payload
      );

      if (response.data.success) {
        console.log("Transaction completed successfully:", response.data);
        await fetchUserData();
      }

      if (!response.data.success) {
        console.error("Backend logging failed:", response.data);
        toast(
          (toastProps) => (
            <CustomReact95Toast
              title="Warning"
              message="Transaction successful, but backend logging failed."
              closeToast={toastProps.closeToast}
            />
          ),
          {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: false,
            closeButton: false,
            hideProgressBar: false,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          }
        );
      } else {
        console.log("Transaction completed successfully:", response.data);
      }
    } catch (error) {
      console.error("Error processing transaction:", error);
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Error"
            message="Error processing transaction. Please try again."
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    } finally {
      setPopupPhase(null);
    }
  };

  // -------------
  // FETCH JACKPOT CONFIG
  // -------------
  useEffect(() => {
    const fetchJackpotConfig = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/public-global-jackpot-config`
        );
        console.log("Fetched Public Jackpot Config:", response.data);

        const {
          price_per_ticket,
          end_time,
          protocol_fee_percentage,
          influencer_fee_percentage,
        } = response.data;

        const endTimeValue = parseInt(end_time, 10);
        const date = new Date(endTimeValue * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const endTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

        setJackpotConfig({
          price_per_ticket: price_per_ticket || "",
          end_time: endTimeString,
          protocol_fee_percentage: protocol_fee_percentage || 0,
          influencer_fee_percentage: influencer_fee_percentage || 0,
        });
      } catch (error) {
        console.error("Error fetching jackpot config:", error);
      }
    };
    fetchJackpotConfig();
  }, [API_BASE_URL]);

  // -------------
  // TIMER LOGIC
  // -------------
  useEffect(() => {
    if (!jackpotConfig.end_time) return;

    const jackpotEndTime = new Date(jackpotConfig.end_time);
    let timerInterval;

    const updateTimer = () => {
      const now = new Date();
      const distance = jackpotEndTime - now;

      if (distance < 0) {
        setTimeString("00000000");
        clearInterval(timerInterval);
        return;
      }

      const totalDays = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const daysString = String(totalDays).padStart(3, "0");
      const hoursString = String(hours).padStart(2, "0");
      const minutesString = String(minutes).padStart(2, "0");
      const secondsString = String(seconds).padStart(2, "0");
      setTimeString(daysString + hoursString + minutesString + secondsString);
    };

    updateTimer(); // run immediately
    timerInterval = setInterval(updateTimer, 1000);
    return () => clearInterval(timerInterval);
  }, [jackpotConfig.end_time]);

  // -------------
  // UI PREVIEW CALCS
  // -------------
  const ticketPrice = jackpotConfig.price_per_ticket
    ? parseFloat(jackpotConfig.price_per_ticket)
    : 1;
  const protocolFeePercentage = jackpotConfig.protocol_fee_percentage || 0;
  const influencerFeePercentage = jackpotConfig.influencer_fee_percentage || 0;

  // For "buy" preview
  const protocolFee = (
    ticketPrice *
    ticketAmount *
    (protocolFeePercentage / 100)
  ).toFixed(2);
  const influencerFee = (
    ticketPrice *
    ticketAmount *
    (influencerFeePercentage / 100)
  ).toFixed(2);
  const totalFees = (
    parseFloat(protocolFee) + parseFloat(influencerFee)
  ).toFixed(2);
  const totalCost = (
    ticketPrice * ticketAmount +
    parseFloat(totalFees)
  ).toFixed(2);

  // 1) Grab the total user contribution from the backend
  const totalContributionSOL = userData?.totalContribution
    ? parseFloat(userData.totalContribution)
    : 0;

  // 2) Deduct full tickets until we have a leftover that's < ticketPrice
  let leftover = totalContributionSOL;
  while (leftover >= ticketPrice) {
    leftover -= ticketPrice;
  }
  // Or use modulo for a one-liner:
  // let leftover = totalContributionSOL % ticketPrice;

  // 3) The leftover partial is how much the user already has toward the next ticket
  console.log("Full totalContribution:", totalContributionSOL);
  console.log("Computed leftover partial:", leftover);

  const progressPercentage = userData
    ? Number(userData.progressTowardsNextTicket)
    : 0;
  const topOffAmount = userData
    ? Number(userData.neededForOneTicket).toFixed(3)
    : "0";

  console.log(
    "Using backend-calculated progressPercentage:",
    progressPercentage
  );
  console.log("Using backend-calculated topOffAmount (SOL):", topOffAmount);

  // Now, calculate the fees based on the topOffAmount
  const topOffProtocolFee = (
    Number(topOffAmount) *
    (protocolFeePercentage / 100)
  ).toFixed(4);
  const topOffInfluencerFee = (
    Number(topOffAmount) *
    (influencerFeePercentage / 100)
  ).toFixed(4);
  const topOffTotalFees = (
    parseFloat(topOffProtocolFee) + parseFloat(topOffInfluencerFee)
  ).toFixed(4);
  const topOffTotalCost = (
    Number(topOffAmount) + parseFloat(topOffTotalFees)
  ).toFixed(4);

  return (
    <GlobalJackpotPageWrapper>
      <CompetitionStyles />
      <GlobalJackpotDisplay
        jackpotAmount={jackpot}
        liveUpdatesEnabled={liveUpdatesEnabled}
      />

      <JackpotWindow>
        <WindowHeaderStyled>
          <span>GlobalJackpotPage.exe</span>
          <Button
            onClick={() => navigate(-1)}
            style={{ marginLeft: "auto", padding: "0 10px" }}
          >
            X
          </Button>
        </WindowHeaderStyled>

        <WindowContentStyled>
          <div style={{ position: "relative" }}>
            <ResponsiveImage
              src="/default-banner.png"
              alt="Global Jackpot Banner"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "contain",
                paddingBottom: "2%",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/default-banner.png";
              }}
            />

            <div
              style={{
                position: "absolute",
                top: "70%",
                left: "50%",
                transform: "translateX(-50%)",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Counter
                  value={timeString}
                  size={counterSize}
                  style={{ textAlign: "left" }}
                />
              </div>
            </div>
          </div>

          <ResponsiveDiv>
            <GroupBoxesContainer>
              <GroupBoxStyledResponsive label="Your Tickets">
                <p>{userData ? Math.floor(userData.tickets) : "?"}</p>
              </GroupBoxStyledResponsive>
              <GroupBoxStyledResponsive label="Total Participants">
                <p>{participants}</p>
              </GroupBoxStyledResponsive>
              <GroupBoxStyledResponsive label="Total Tickets">
                <p>{totalTickets}</p>
              </GroupBoxStyledResponsive>
            </GroupBoxesContainer>
          </ResponsiveDiv>

          <ResponsiveDiv2>
            <GroupBox className="group-box-styled-2" label="Buy Tickets">
              <NumberInput
                value={ticketAmount}
                step={1}
                min={1}
                max={1000}
                onChange={(value) => handleInputChange(value)}
                width={130}
              />
            </GroupBox>
            <Button
              className="large-button"
              primary
              onClick={handleBuyClick}
              style={{ marginTop: "5px" }}
            >
              Buy
            </Button>
          </ResponsiveDiv2>

          <p style={{ marginTop: "20px", fontWeight: "bold" }}>
            Progress towards next ticket:
          </p>
          <ProgressBar value={progressPercentage} />
          <Button fullWidth onClick={handleTopOffClick}>
            Top Off
          </Button>
        </WindowContentStyled>
      </JackpotWindow>

      {/* Popup Modal */}
      {popupPhase &&
        createPortal(
          <ModalOverlay>
            <ProcessingPopup>
              {popupPhase === "confirm" && (
                <>
                  <WindowHeader className="window-header-styled">
                    <span>ConfirmPurchase.exe</span>
                    <Button
                      onClick={handleCancelBuy}
                      style={{ marginLeft: "auto", padding: "0 10px" }}
                    >
                      X
                    </Button>
                  </WindowHeader>
                  <WindowContent>
                    <div className="group-boxes-container3">
                      {purchaseType === "buy" && (
                        <>
                          <GroupBox
                            className="group-box-styled"
                            label="Total Tickets"
                          >
                            <p>{ticketAmount}</p>
                          </GroupBox>
                          <GroupBox
                            className="group-box-styled"
                            label="Total Fees"
                          >
                            <p>{totalFees} SOL</p>
                          </GroupBox>
                          <GroupBox
                            className="group-box-styled"
                            label="Total Cost"
                          >
                            <p>{totalCost} SOL</p>
                          </GroupBox>
                        </>
                      )}
                      {purchaseType === "topOff" && (
                        <>
                          <GroupBox
                            className="group-box-styled"
                            label="Top Off"
                          >
                            <p>{topOffAmount} SOL</p>
                          </GroupBox>
                          <GroupBox
                            className="group-box-styled"
                            label="Total Fees"
                          >
                            <p>{topOffTotalFees} SOL</p>
                          </GroupBox>
                          <GroupBox
                            className="group-box-styled"
                            label="Total Cost"
                          >
                            <p>{topOffTotalCost} SOL</p>
                          </GroupBox>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={handleConfirmBuy}
                        style={{ width: "100px" }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </WindowContent>
                </>
              )}
              {popupPhase === "processing" && (
                <>
                  <WindowHeader style={{ display: "flex" }}>
                    Processing.exe
                  </WindowHeader>
                  <WindowContent>
                    <p>
                      Your transaction is being processed.
                      <br />
                      Please wait...
                    </p>
                  </WindowContent>
                </>
              )}
            </ProcessingPopup>
          </ModalOverlay>,
          document.body
        )}
    </GlobalJackpotPageWrapper>
  );
};

export default GlobalJackpotPage;
