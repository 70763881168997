import React, { useState } from "react";
import styled from "styled-components";
import {
  Window,
  WindowContent,
  WindowHeader,
  Button,
  TextInput,
} from "react95";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const ResponsiveWindow = styled(Window)`
  width: 100%;
  max-width: 600px;
  pointer-events: auto;
  overflow-y: auto;
  padding: 0;
  box-sizing: border-box;
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;

const FAQContent = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
`;

const SearchContainer = styled.div`
  margin-bottom: 10px;
`;

const FAQItem = styled.div`
  margin-bottom: 1rem;
  padding: 10px;
  border: 1px solid;
  border-radius: 2px;
  box-sizing: border-box;
  width: 100%;
`;

const Question = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Arrow = styled.span`
  display: inline-block;
  margin-right: 8px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  transform: ${({ open }) => (open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease-in-out;
`;

const containerVariants = {
  open: {
    height: "auto",
    opacity: 1,
    transition: {
      height: { type: "spring", damping: 8, stiffness: 130 },
      opacity: { duration: 0.2 },
    },
  },
  collapsed: {
    height: 0,
    opacity: 0,
    transition: {
      height: { type: "spring", damping: 15, stiffness: 150 },
      opacity: { duration: 0.2 },
    },
  },
};

const contentVariants = {
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.1,
      duration: 0.2,
    },
  },
  collapsed: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.1,
    },
  },
};

const AnimatedAnswerContainer = styled(motion.div)`
  overflow: hidden;
  margin-top: 0.5rem;
`;

const AnimatedAnswer = styled(motion.div)`
  width: 100%;
  background-color: white;
  color: black;
  border: 2px inset #dfdfdf;
  border-left-color: #868a8e;
  border-top-color: #868a8e;
  border-right-color: white;
  border-bottom-color: white;
  padding: 6px 10px;
  font-size: 1rem;
  font-family: inherit;
  word-wrap: break-word;
  white-space: pre-wrap;
  min-height: 20px;
  cursor: default;
  box-sizing: border-box;
  overflow-wrap: break-word;
  max-width: 100%;
  transform-origin: top center;
`;

/*
  Highlights each match of 'searchTerm' by wrapping it in <mark>.
  We use a 'dangerouslySetInnerHTML' approach, but that's simplest
  for highlighting inline text.

  The 'gi' flag makes the search case-insensitive and global.
*/
function getHighlightedAnswer(answer, searchTerm) {
  if (!searchTerm) {
    return answer; // No highlight if empty search
  }
  const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special regex chars
  const regex = new RegExp(`(${escapedTerm})`, "gi");
  return answer.replace(regex, "<mark>$1</mark>");
}

const faqItems = [
  {
    question: "What is Blind Faith?",
    answer:
      "Blind Faith is a decentralized platform hosting Solana-based token competitions. Participants buy tickets to enter, with each purchase split between acquiring a chosen token and fueling a prize pool. Every competition is recorded immutably on-chain, and the winner is selected through verifiable randomness, ensuring a fair and transparent process with no human interference.",
  },
  {
    question: "Is the winner selection process fair?",
    answer:
      "Yes! Winners are selected using Switchboard VRF (Verifiable Random Function), ensuring complete randomness without human interference. This process is publicly verifiable on the Solana blockchain.",
  },
  {
    question: "How does Blind Faith prevent cheating?",
    answer:
      "Blind Faith ensures fairness through:\n1. Immutable on-chain transactions.\n2. Verifiable randomness via Switchboard VRF.\n3. Transparent fee and reward distribution.\nAll of these make manipulation impossible.",
  },
  {
    question: "How do fees work on Blind Faith?",
    answer:
      "Blind Faith caps total fees at 5% of the ticket price—this maximum can never be exceeded. Currently, the fee split is 2% to the protocol, 1% to the global jackpot, 1% to the competition’s creator, and 1% to marketing. Though these individual allocations may change, they will never sum to more than 5% in total.\n\nIf you purchase a Global Jackpot ticket directly (or 'Top Off'), only the protocol and marketing fees apply—no global jackpot or influencer fees are taken for those direct Jackpot purchases. (See “What is the Global Jackpot, and how does it work?” for more on topping off.)",
  },
  {
    question: "How do I participate in a competition?",
    answer:
      "To join a competition:\n1. Connect your Solana wallet to the platform.\n2. Browse available competitions on the homepage.\n3. Select a competition to view its details.\n4. Click the BUY button to purchase a ticket and secure your entry.",
  },
  {
    question: "What tokens are purchased when buying a ticket?",
    answer:
      "The token purchased when buying a ticket is determined by the competition creator. Each competition is associated with a specific Solana-based token. When a ticket is purchased, a portion of the ticket price is used to buy this token on behalf of the participant. The details of the token and its allocation percentage can be viewed on the competition page before purchasing.",
  },
  {
    question: "How is the ticket price and allocation determined?",
    answer:
      "Ticket prices and allocation percentages are set by the competition creator. Some competitions focus more on token purchases, while others prioritize a larger prize pool. Always check the details before purchasing a ticket.",
  },
  {
    question: "How are ticket numbers determined?",
    answer:
      "Ticket numbers are assigned sequentially in the order transactions are finalized on the blockchain. When a user purchases a ticket, the transaction must be fully confirmed before a ticket number is assigned to ensure fairness and prevent conflicts. This guarantees that each ticket number is unique and properly recorded on-chain.",
  },
  {
    question:
      "What's the maximum number of tickets I can buy for a competition?",
    answer:
      "The maximum number of tickets a single user can purchase for a competition is 100. This limit ensures fairness and prevents any single participant from having excessive control. The limit is enforced on-chain, meaning transactions exceeding this cap will be rejected.",
  },
  {
    question: "How do you create a competition?",
    answer:
      "To create a competition:\n1. Connect your Solana wallet to the platform.\n2. Navigate to the 'Create Competition' page.\n3. Enter the required details, such as ticket price, token allocation, number of winners, and end time.\n4. Click the 'Create' button and confirm the transaction in your wallet.\n5. Your competition will be deployed on-chain and made available for users to join.",
  },
  {
    question: "What is the Global Jackpot, and how does it work?",
    answer:
      "The Global Jackpot is a continuously growing prize pool that accumulates contributions from all competitions. You can buy a full ticket directly into the Jackpot, or if you’ve already partially covered its cost through other competition entries, you can ‘top off’ the remaining amount until you’ve fully paid for one ticket. Once the ticket is fully paid, you’re automatically entered—so you never lose partial contributions toward the next Global Jackpot ticket!",
  },
  {
    question: "How do I claim my winnings?",
    answer:
      "If you win a competition:\n1. Go to the competition details page.\n2. Click the 'Claim Prize' button.\n3. Your winnings will be automatically transferred to your wallet.",
  },
  {
    question: "Can I refund my ticket purchase?",
    answer:
      "No, all ticket purchases are final and cannot be refunded under any circumstances. Once you commit to buying a ticket, the transaction is irreversible.",
  },

  {
    question: "Where can I get support?",
    answer:
      "Support is available via our <a href='https://discord.gg/P7tb9NBPmn' target='_blank' rel='noopener noreferrer' style='text-decoration: underline; color: #1d59e8;'>Discord</a> and <a href='https://t.me/blindfaithofficial' target='_blank' rel='noopener noreferrer' style='text-decoration: underline; color: #1d59e8;'>Telegram</a> channels. You can also contact us via email support@blind.faith. For the fastest response, we recommend reaching out on Telegram or Discord.",
  },
];

const FAQPage = () => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);

  // Track user’s search input
  const [searchQuery, setSearchQuery] = useState("");

  // Filter the FAQ items if user typed something
  const filteredFaqItems = faqItems.filter((item) => {
    const lowerSearch = searchQuery.toLowerCase();
    return (
      item.question.toLowerCase().includes(lowerSearch) ||
      item.answer.toLowerCase().includes(lowerSearch)
    );
  });

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Wrapper>
      <ResponsiveWindow>
        <WindowHeaderStyled>
          <span>FAQ</span>
          <Button onClick={() => navigate(-1)}>X</Button>
        </WindowHeaderStyled>

        <FAQContent>
          {/* SEARCH BOX */}
          <SearchContainer>
            <TextInput
              placeholder="Search FAQs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
            />
          </SearchContainer>

          {/* DISPLAY FILTERED ITEMS */}
          {filteredFaqItems.map((item, index) => (
            <FAQItem key={index}>
              <Question onClick={() => handleToggle(index)}>
                <Arrow open={openIndex === index} />
                {item.question}
              </Question>
              <AnimatePresence initial={false}>
                {openIndex === index && (
                  <AnimatedAnswerContainer
                    variants={containerVariants}
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                  >
                    <AnimatedAnswer
                      variants={contentVariants}
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      // Use dangerouslySetInnerHTML to render highlighted text
                      dangerouslySetInnerHTML={{
                        __html: getHighlightedAnswer(item.answer, searchQuery),
                      }}
                    />
                  </AnimatedAnswerContainer>
                )}
              </AnimatePresence>
            </FAQItem>
          ))}

          {/* Optional: Message if no items matched the search */}
          {filteredFaqItems.length === 0 && (
            <p style={{ marginTop: "1rem" }}>
              No questions found. Try a different search.
            </p>
          )}
        </FAQContent>
      </ResponsiveWindow>
    </Wrapper>
  );
};

export default FAQPage;
