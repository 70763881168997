// src/pages/CompetitionDetailsPage.js

import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { createPortal } from "react-dom";
import {
  Window,
  WindowContent,
  WindowHeader,
  Avatar,
  Frame,
  Counter,
  GroupBox,
  ProgressBar,
  Button,
  NumberInput,
  Tabs,
  Tab,
  TabBody,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Tooltip,
} from "react95";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import CustomReact95Toast from "../components/CustomReact95Toast";
import CompetitionStyles from "../styles/CompetitionStyles";
import styled from "styled-components";
import { useProgram } from "../App";
import { NotificationContext } from "../context/NotificationContext";
import BN from "bn.js";
import { toast } from "react-toastify";
import { useError } from "../context/ErrorContext";
import {
  VersionedTransaction,
  Transaction,
  sendAndConfirmTransaction,
  LAMPORTS_PER_SOL,
  SystemProgram,
  PublicKey,
} from "@solana/web3.js";
import * as web3 from "@solana/web3.js";
import { useAlert } from "../context/AlertContext";
import BfSticker1 from "../assets/gif/bfsticker1.gif";

import { API_URLS, getSwapInstruction } from "@raydium-io/raydium-sdk-v2";
import bs58 from "bs58";
import { getOrCreateAssociatedTokenAccount } from "@solana/spl-token";
import Marquee from "react-fast-marquee";

// Add this line to use the API_BASE_URL environment variable
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const { Buffer } = require("buffer");

// Predefined logos with corresponding link field names
const LOGOS = [
  { src: "/logos/solana.svg", linkField: "solana_link" },
  { src: "/logos/website.svg", linkField: "website_link" },
  { src: "/logos/telegram.svg", linkField: "telegram_link" },
  { src: "/logos/x.svg", linkField: "x_link" },
  { src: "/logos/discord.svg", linkField: "discord_link" },
];
const shareIcon = "/logos/share.svg";

// Function to prepend https:// if missing
const formatURL = (url) => {
  if (!url) return "";
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

// Function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Styled components for logos
const LogoContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 50%;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const LogoImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

// Rainbow effect text for the entire header title
const RainbowTextHeader = styled.span`
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(to right, ${({ colors }) => colors.join(", ")});
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 400% 100%;

  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

const RainbowText = styled.span`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(to right, ${({ colors }) => colors.join(", ")});
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 400% 100%;

  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

// Styled component for glowing jackpot text
const JackpotText = styled.p`
  font-size: clamp(1rem, 2.13vw, 2.13rem);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  background: ${({ isJackpot, colors }) =>
    isJackpot ? `linear-gradient(to right, ${colors.join(", ")})` : "inherit"};
  -webkit-background-clip: ${({ isJackpot }) =>
    isJackpot ? "text" : "inherit"};
  background-clip: ${({ isJackpot }) => (isJackpot ? "text" : "inherit")};
  color: ${({ isJackpot }) => (isJackpot ? "transparent" : "inherit")};
  animation: ${({ isJackpot }) =>
    isJackpot ? "rainbow_animation 3s ease-in-out infinite" : "none"};
  background-size: 400% 100%;

  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
  position: relative;
`;

// Modal overlay and processing popup styled components (for the overlay)
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProcessingPopup = styled(Window)`
  width: 300px;
  text-align: center;
`;

const CompetitionDetailsPage = () => {
  const { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [entries, setEntries] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { wallet, publicKey } = useWallet();
  const [ticketAmount, setTicketAmount] = useState(1);
  const [counterSize, setCounterSize] = useState("lg");
  const [activeTab, setActiveTab] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // Instead of a simple confirmation flag, we now use popupPhase:
  // null = no popup, "confirm" = show confirmation UI, "processing" = show processing UI
  const [popupPhase, setPopupPhase] = useState(null);
  const [feePercentages, setFeePercentages] = useState(null);
  const { showError } = useError();
  const { showAlert } = useAlert();
  const { socketRef } = useContext(NotificationContext);
  const [transactions, setTransactions] = useState([]);

  const { connection } = useConnection();
  const { program } = useProgram();

  const randomColors = useMemo(() => {
    return [
      getRandomColor(),
      getRandomColor(),
      getRandomColor(),
      getRandomColor(),
    ];
  }, []);

  // 1. Define calculateRemainingTime first
  const calculateRemainingTime = (endTime) => {
    const now = new Date().getTime();
    const distance = new Date(endTime).getTime() - now;

    if (distance < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  useEffect(() => {
    if (!socketRef.current) return;
    const handleCompetitionUpdate = (data) => {
      if (data.competition_id === id) {
        console.log(
          "Competition update received in CompetitionDetailsPage:",
          data
        );
        fetchEntries();
        fetchTransactions();
        fetchCompetition();
      }
    };

    socketRef.current.on("competitionUpdated", handleCompetitionUpdate);
    return () => {
      socketRef.current.off("competitionUpdated", handleCompetitionUpdate);
    };
  }, [id]);

  // 2. Define fetchCompetition after calculateRemainingTime
  const fetchCompetition = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/competitions/${id}`);
      const competition = response.data;
      setCompetition(competition);
      setRemainingTime(calculateRemainingTime(competition.end_time));
    } catch (error) {
      console.error("Error fetching competition:", error);
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Error"
            message="Failed to load competition details. Please try again."
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    }
  };

  // 3. Define fetchEntries
  const fetchEntries = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/competitions/${id}/entries`
      );
      setEntries(response.data);
    } catch (error) {
      console.error("Error fetching entries:", error);
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Error"
            message="Failed to load competition entries. Please try again."
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    }
  };

  // Function to fetch user's token accounts
  const fetchTokenAccountData = async (connection, publicKey) => {
    const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, {
      programId: new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
    });

    return {
      tokenAccounts: tokenAccounts.value.map((accountInfo) => ({
        publicKey: accountInfo.pubkey.toBase58(),
        mint: new PublicKey(accountInfo.account.data.slice(0, 32)).toBase58(),
      })),
    };
  };

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/competitions/${id}/transactions`
      );
      setTransactions(response.data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Error"
            message="Failed to load transactions. Please try again."
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    }
  };

  // A component that renders the ticket numbers for a transaction
  const TransactionTicketNumbers = ({ transaction }) => {
    // Assuming transaction.details.ticket_numbers is already populated from the DB.
    const ticketNumbers = transaction.details?.ticket_numbers;

    if (Array.isArray(ticketNumbers) && ticketNumbers.length > 0) {
      return (
        <Marquee
          pauseOnHover
          gradient={false}
          speed={50}
          style={{ width: "150px" }}
        >
          {[...ticketNumbers, ""].join(" _ ")}
        </Marquee>
      );
    }
    return <span>-</span>;
  };

  // Helper function to ensure ATA exists
  async function ensureATA(mint, owner, connection, payer) {
    const ata = await getOrCreateAssociatedTokenAccount(
      connection,
      payer,
      mint,
      owner,
      true
    );
    console.log(
      `Associated Token Account for ${mint.toBase58()}:`,
      ata.address.toBase58()
    );
    return ata.address;
  }

  const handleShareClick = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        toast(
          (toastProps) => (
            <CustomReact95Toast
              title="Copied"
              message="Competition URL has been copied to clipboard."
              closeToast={toastProps.closeToast}
            />
          ),
          {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: false,
            closeButton: false,
            hideProgressBar: false,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          }
        );
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
      });
  };

  useEffect(() => {
    const fetchProtocolConfig = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/protocol-config`);
        const feeConfigMap = response.data.reduce((acc, item) => {
          acc[item.key] = parseFloat(item.value);
          return acc;
        }, {});

        const protocol_fee_percentage =
          feeConfigMap.PROTOCOL_FEE_PERCENTAGE || 0;
        const creator_fee_percentage = feeConfigMap.CREATOR_FEE_PERCENTAGE || 0;
        const global_jackpot_fee_percentage =
          feeConfigMap.GLOBAL_JACKPOT_FEE_PERCENTAGE || 0;
        const influencer_fee_percentage =
          feeConfigMap.INFLUENCER_FEE_PERCENTAGE || 0;

        const totalFeesPercentage =
          protocol_fee_percentage +
          creator_fee_percentage +
          global_jackpot_fee_percentage +
          influencer_fee_percentage;

        setFeePercentages({
          protocol_fee_percentage,
          creator_fee_percentage,
          global_jackpot_fee_percentage,
          influencer_fee_percentage,
          totalFeesPercentage,
        });

        console.log("Protocol Configurations:", {
          protocol_fee_percentage,
          creator_fee_percentage,
          global_jackpot_fee_percentage,
          influencer_fee_percentage,
          totalFeesPercentage,
        });
      } catch (error) {
        console.error("Error fetching protocol configuration:", error);
        toast(
          (toastProps) => (
            <CustomReact95Toast
              title="Error"
              message="Failed to fetch protocol configuration. Please try again."
              closeToast={toastProps.closeToast}
            />
          ),
          {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: false,
            closeButton: false,
            hideProgressBar: false,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          }
        );
      }
    };

    fetchProtocolConfig();
  }, []);

  // 4. useEffect to fetch data
  useEffect(() => {
    fetchCompetition();
    fetchEntries();
    fetchTransactions();
  }, [id]);

  // 5. useEffect for countdown
  useEffect(() => {
    const interval = setInterval(() => {
      if (competition) {
        setRemainingTime(calculateRemainingTime(competition.end_time));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [competition]);

  // 6. useEffect for copied
  useEffect(() => {
    if (copied) {
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Copied"
            message="Contract Address has been copied to clipboard."
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    }
  }, [copied]);

  // 7. useEffect for responsive design
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      if (window.innerWidth <= 768) {
        setCounterSize("m");
      } else {
        setCounterSize("lg");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 8. Loading state
  if (!competition) {
    return;
  }

  // 9. Helper functions
  const truncateAddress = (address) =>
    `${address.slice(0, 5)}...${address.slice(-5)}`;
  const getAddressDisplay = (address, isSmallScreen) =>
    isSmallScreen ? truncateAddress(address) : address;
  const formatTime = (time) => {
    return `${time.days.toString().padStart(2, "0")}${time.hours
      .toString()
      .padStart(2, "0")}${time.minutes
      .toString()
      .padStart(2, "0")}${time.seconds.toString().padStart(2, "0")}`;
  };

  const timeString = remainingTime ? formatTime(remainingTime) : "00000000";
  const minimumPool = competition.minimum_pool;
  const currentAmount = competition.prize_pool;
  const progressPercentage = Math.min(
    ((currentAmount / minimumPool) * 100).toFixed(1),
    100
  );

  const handleInputChange = (value) => {
    if (!isNaN(value) && value > 0) {
      setTicketAmount(value);
    } else {
      setTicketAmount(1);
    }
  };

  const handleChangeTab = (value) => {
    setActiveTab(value);
  };

  const handleClose = () => {
    if (location.state?.fromCreateCompetition) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  // When BUY is clicked, show the confirmation popup
  const handleBuyClick = () => {
    setPopupPhase("confirm");
  };

  // When CANCEL is clicked, hide the popup
  const handleCancelBuy = () => {
    setPopupPhase(null);
  };

  // Handle confirmation – switch the popup into processing mode and run the transaction
  const handleConfirmBuy = async () => {
    // Switch popup UI to processing state
    setPopupPhase("processing");

    // Collect validation errors
    let errorMessages = [];
    if (!publicKey || !wallet) {
      errorMessages.push("Please connect your wallet to proceed.");
    }
    if (!feePercentages) {
      errorMessages.push(
        "Fee percentages not loaded yet. Please try again shortly."
      );
    }
    if (errorMessages.length > 0) {
      showError(errorMessages.join("\n"));
      setPopupPhase(null);
      return;
    }

    try {
      //--------------------------------------
      // 1) Pull data from the local `competition` object
      //--------------------------------------
      const ticketPriceSOL = competition.ticket_price;
      const tokenBuyPercentage = competition.token_buy_percentage;
      const totalCostSOL = ticketPriceSOL * ticketAmount;
      const tokenBuyAmountSOL = (totalCostSOL * tokenBuyPercentage) / 100;
      const programPaymentSOL = totalCostSOL - tokenBuyAmountSOL;
      const jackpotPaymentSOL =
        (totalCostSOL * competition.pool_percentage) / 100;

      // Convert SOL to lamports
      const tokenBuyLamports = web3.LAMPORTS_PER_SOL * tokenBuyAmountSOL;
      const programPaymentLamports = web3.LAMPORTS_PER_SOL * programPaymentSOL;

      const competitionCountBuffer = new BN(
        competition.competition_number
      ).toArrayLike(Buffer, "le", 8);

      //--------------------------------------
      // 2) Calculate fees using `feePercentages`
      //--------------------------------------
      const {
        protocol_fee_percentage,
        creator_fee_percentage,
        influencer_fee_percentage,
        global_jackpot_fee_percentage,
      } = feePercentages;

      const protocolFeeSOL = totalCostSOL * (protocol_fee_percentage / 100);
      const creatorFeeSOL = totalCostSOL * (creator_fee_percentage / 100);
      const influencerFeeSOL = totalCostSOL * (influencer_fee_percentage / 100);
      const globalJackpotFeeSOL =
        totalCostSOL * (global_jackpot_fee_percentage / 100);

      //--------------------------------------
      // 3) Derive PDAs
      //--------------------------------------
      const competitionPDA = new web3.PublicKey(competition.pda_address);
      const competitionCreator = new web3.PublicKey(competition.creator_id);

      const [creatorPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("creator"), competitionCreator.toBuffer()],
        program.programId
      );

      const [ticketPDA] = await web3.PublicKey.findProgramAddress(
        [competitionPDA.toBuffer(), publicKey.toBuffer()],
        program.programId
      );

      const [globalJackpotPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("global_jackpot")],
        program.programId
      );

      const [globalJackpotTicketPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("global_jackpot_ticket"), publicKey.toBuffer()],
        program.programId
      );

      const [protocolFeeAccountPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_fee_account")],
        program.programId
      );

      const [influencerFeeAccountPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("influencer_fee_account")],
        program.programId
      );

      console.log("Ticket Amount:", ticketAmount);

      //--------------------------------------
      // 4) Build the purchaseTickets instruction
      //--------------------------------------
      const ticketCountBN = new BN(ticketAmount);
      console.log("TicketCountBN:", ticketCountBN);

      const transaction = new web3.Transaction();

      const instruction = await program.methods
        .purchaseTickets(ticketCountBN)
        .accounts({
          competition: competitionPDA,
          ticket: ticketPDA,
          participant: publicKey,
          protocolFeeAccount: protocolFeeAccountPDA,
          globalJackpot: globalJackpotPDA,
          influencerFeeAccount: influencerFeeAccountPDA,
          creator: creatorPDA,
          systemProgram: web3.SystemProgram.programId,
          globalJackpotTicket: globalJackpotTicketPDA,
        })
        .instruction();

      transaction.add(instruction);

      // 6) Set recent blockhash and fee payer
      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      // 7) Sign transaction
      const signedTx = await wallet.adapter.signTransaction(transaction);

      // 8) Send, wait for confirmation, and finalize transaction
      const rawTx = signedTx.serialize();
      const txSignature = await connection.sendRawTransaction(rawTx, {
        skipPreflight: true,
        preflightCommitment: "finalized",
      });
      await connection.confirmTransaction(
        { blockhash, lastValidBlockHeight, signature: txSignature },
        "finalized"
      );

      console.log("Transaction Signature:", txSignature);

      //--------------------------------------
      // 9) Prepare payload and send details to backend
      //--------------------------------------
      const payload = {
        user_id: publicKey.toBase58(),
        competition_id: competition.id,
        competition_PDA: competitionPDA.toBase58(),
        ticket_count: ticketAmount,
        ticket_numbers: [],
        contribution_amount: programPaymentSOL,
        token_purchase_amount: tokenBuyAmountSOL,
        txSignature,
        blockhash,
        details: {
          protocolFee: protocolFeeSOL.toString(),
          creatorFee: creatorFeeSOL.toString(),
          influencerFee: influencerFeeSOL.toString(),
          globalJackpotFee: globalJackpotFeeSOL.toString(),
          creatorPubkey: competition.creator_id,
          jackpotContribution: jackpotPaymentSOL,
        },
      };

      console.log("Sending transaction details to backend:", payload);

      const response = await axios.post(
        `${API_BASE_URL}/competitions/${competition.id}/entries`,
        payload
      );

      if (response.status === 201) {
        console.log("Backend logging successful:", response.data);
        fetchCompetition();
        fetchEntries();
      } else {
        showAlert("Transaction successful, but backend logging failed.");
        console.error("Backend error:", response.data);
      }
    } catch (error) {
      console.error("Error purchasing tickets:", error);
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Error"
            message={error.message || "Error buying tickets. Please try again."}
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    } finally {
      setPopupPhase(null);
    }
  };

  const ticketPrice = competition.ticket_price;
  const totalCost = (ticketPrice * ticketAmount).toFixed(2);
  const poolPercentage = competition.pool_percentage;
  const tokenBuyPercentage = competition.token_buy_percentage;
  const protocolFeePercentage = competition.total_fees_percentage;
  const userEntry = entries.find((e) => e.user_id === publicKey?.toBase58());
  const updatedTicketNumbers = userEntry ? userEntry.ticket_numbers : [];

  const userTickets = entries.reduce(
    (acc, entry) =>
      entry.user_id === publicKey?.toBase58() ? acc + entry.ticket_count : acc,
    0
  );
  const uniqueParticipants = new Set(entries.map((entry) => entry.user_id))
    .size;
  const totalTicketsSold = entries.reduce(
    (acc, entry) => acc + entry.ticket_count,
    0
  );

  // Compute the header title with condition suffix inserted before ".exe"
  const baseTitle =
    competition.header && competition.header.trim() !== ""
      ? `${competition.header.trim().replace(/\s+/g, "_")}_${
          competition.token_symbol
        }_${tokenBuyPercentage}_${poolPercentage}`
      : `${competition.token_symbol}_${tokenBuyPercentage}_${poolPercentage}_${protocolFeePercentage}`;

  let conditionSuffix = "";
  if (timeString === "00000000") {
    conditionSuffix = progressPercentage < 100 ? "_FAILED" : "_FINISHED";
  } else if (progressPercentage === 100) {
    conditionSuffix = "_LIVE";
  }
  // Append 5 non-breaking spaces after ".exe"
  const finalTitle = `${baseTitle}${conditionSuffix}.exe${"\u00A0".repeat(5)}`;

  return (
    <div>
      <CompetitionStyles />
      <div className="competition-item-wrapper">
        <Window className="competition-window">
          <WindowHeader className="window-header-styled">
            <Marquee gradient={false} speed={50}>
              {conditionSuffix ? (
                <RainbowTextHeader colors={randomColors}>
                  {finalTitle}
                </RainbowTextHeader>
              ) : (
                <span>{finalTitle}</span>
              )}
            </Marquee>
            <Button
              onClick={handleClose}
              style={{ marginLeft: "auto", padding: "0 10px" }}
            >
              X
            </Button>
          </WindowHeader>

          <Tabs value={activeTab} onChange={handleChangeTab}>
            <Tab value={0}>Competition</Tab>
            <Tab value={1}>Participants</Tab>
            <Tab value={2}>Chat Room</Tab>
          </Tabs>
          <TabBody>
            {activeTab === 0 && (
              <WindowContent className="window-content-styled">
                <img
                  src={competition.banner_image || "/default-banner.png"}
                  alt={`${competition.token_symbol} Banner`}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                    paddingBottom: "2%",
                  }}
                />
                <div className="token-name-wrapper">
                  <p>
                    {competition.token_name}
                    <img
                      src={shareIcon}
                      alt="Share Competition"
                      style={{
                        width: "16px",
                        height: "16px",
                        cursor: "pointer",
                        marginLeft: "8px",
                        verticalAlign: "middle",
                      }}
                      onClick={handleShareClick}
                    />
                  </p>
                </div>

                {LOGOS.some((logo) => competition[logo.linkField]) && (
                  <LogoContainer>
                    {LOGOS.map((logo, index) => {
                      const link = competition[logo.linkField];
                      if (!link) return null;
                      return (
                        <a
                          key={index}
                          href={formatURL(link)}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          aria-label={`Open ${logo.linkField.replace(
                            "_",
                            " "
                          )} link`}
                        >
                          <LogoImage
                            src={logo.src}
                            alt={`${logo.linkField.replace("_", " ")} logo`}
                          />
                        </a>
                      );
                    })}
                  </LogoContainer>
                )}

                <div className="token-text-wrapper">
                  <p>{competition.description}</p>
                </div>
                <div className="competition-content-2">
                  <div className="competition-content">
                    <div className="token-details">
                      <Avatar className="token-avatar">
                        <img
                          src={competition.token_icon}
                          alt={competition.token_symbol}
                        />
                      </Avatar>

                      <p>
                        <div className="contract-address">
                          {truncateAddress(competition.token)}
                          <CopyToClipboard
                            text={competition.token}
                            onCopy={() => setCopied(true)}
                          >
                            <FaCopy className="copy-icon" />
                          </CopyToClipboard>
                        </div>
                      </p>
                    </div>
                    <div className="competition-details-3">
                      <GroupBox
                        className="group-box-styled-ticket-price"
                        label="Ticket Price"
                      >
                        <p>{competition.ticket_price} SOL</p>
                      </GroupBox>
                    </div>
                  </div>

                  <div className="wrapper">
                    <div className="competition-details-2">
                      <GroupBox className="group-box-styled-2" label="Jackpot">
                        <JackpotText
                          isJackpot={progressPercentage >= 100}
                          colors={randomColors}
                        >
                          {competition?.prize_pool
                            ? (() => {
                                const prizePool = Number(
                                  competition.prize_pool
                                );
                                if (prizePool < 1) {
                                  return `${
                                    Math.floor(prizePool * 1000) / 1000
                                  } SOL`;
                                } else if (prizePool < 10) {
                                  return `${
                                    Math.floor(prizePool * 100) / 100
                                  } SOL`;
                                } else if (prizePool < 100) {
                                  return `${
                                    Math.floor(prizePool * 10) / 10
                                  } SOL`;
                                } else {
                                  return `${Math.floor(prizePool)} SOL`;
                                }
                              })()
                            : "Loading..."}
                        </JackpotText>
                      </GroupBox>
                    </div>
                  </div>
                </div>

                <div className="responsive-frame">
                  <Frame
                    variant="outside"
                    className="countdown-timer"
                    style={{
                      margin: "1rem",
                      padding: ".5rem",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                      minWidth: "270px",
                    }}
                  >
                    <div className="countdown-timer">
                      TIME LEFT
                      <Frame className="styled-frame">
                        <Counter value={timeString} size={counterSize} />
                      </Frame>
                      <div className="progress-wrapper">
                        PROGRESS
                        <ProgressWrapper>
                          <ProgressBar
                            value={progressPercentage}
                            hideValue={
                              progressPercentage === 100 ||
                              timeString === "00000000"
                            }
                          />

                          {timeString === "00000000" ? (
                            progressPercentage < 100 ? (
                              <RainbowText colors={randomColors}>
                                FAILED
                              </RainbowText>
                            ) : (
                              <RainbowText colors={randomColors}>
                                FINISHED
                              </RainbowText>
                            )
                          ) : progressPercentage === 100 ? (
                            <RainbowText colors={randomColors}>
                              LIVE!
                            </RainbowText>
                          ) : null}
                        </ProgressWrapper>
                      </div>
                    </div>
                  </Frame>

                  <div className="wrapper-2">
                    <GroupBox
                      className="group-box-styled-responsive"
                      label="Total Tickets"
                    >
                      <div className="number-input-wrapper">
                        <NumberInput
                          value={ticketAmount}
                          step={1}
                          min={1}
                          max={1000}
                          onChange={(value) => handleInputChange(value)}
                          width={130}
                        />
                      </div>
                    </GroupBox>

                    <Button
                      className="large-button"
                      primary
                      onClick={handleBuyClick}
                      disabled={
                        remainingTime &&
                        remainingTime.days === 0 &&
                        remainingTime.hours === 0 &&
                        remainingTime.minutes === 0 &&
                        remainingTime.seconds === 0
                      }
                    >
                      {remainingTime &&
                      remainingTime.days === 0 &&
                      remainingTime.hours === 0 &&
                      remainingTime.minutes === 0 &&
                      remainingTime.seconds === 0
                        ? "FINISHED"
                        : "BUY"}
                    </Button>
                  </div>
                </div>
              </WindowContent>
            )}

            {activeTab === 1 && (
              <WindowContent className="window-content-styled">
                <div className="group-boxes-container">
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Your Tickets"
                  >
                    <p>{publicKey ? userTickets : "?"}</p>
                  </GroupBox>
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Total Participants"
                  >
                    <p>{uniqueParticipants}</p>
                  </GroupBox>
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Total Tickets"
                  >
                    <p>{totalTicketsSold}</p>
                  </GroupBox>
                </div>

                <Table className="custom-table">
                  <TableHead className="custom-thead">
                    <TableRow className="custom-tr">
                      <TableHeadCell className="custom-th">User</TableHeadCell>
                      <TableHeadCell className="custom-th">Tx ID</TableHeadCell>
                      <TableHeadCell className="custom-th">
                        Tickets
                      </TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="custom-tbody">
                    {transactions.map((transaction, index) => (
                      <TableRow key={index} className="custom-tr">
                        <TableDataCell className="custom-td">
                          {getAddressDisplay(
                            transaction.user_id,
                            isSmallScreen
                          )}
                        </TableDataCell>

                        <TableDataCell className="custom-td">
                          {transaction.txID ? (
                            <a
                              href={`https://solscan.io/tx/${transaction.txID}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="tx-link"
                            >
                              {truncateAddress(transaction.txID)}
                            </a>
                          ) : (
                            "-"
                          )}
                        </TableDataCell>
                        <TableDataCell className="custom-td">
                          <TransactionTicketNumbers transaction={transaction} />
                        </TableDataCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </WindowContent>
            )}

            {activeTab === 2 && (
              <WindowContent className="window-content-styled">
                <p>Coming Soon!</p>
              </WindowContent>
            )}
          </TabBody>
        </Window>
      </div>

      {/* Render the overlay popup via createPortal if popupPhase is set */}
      {popupPhase &&
        createPortal(
          <ModalOverlay>
            <ProcessingPopup>
              {popupPhase === "confirm" && (
                <>
                  <WindowHeader className="window-header-styled">
                    <span>ConfirmPurchase.eXe</span>
                    <Button
                      onClick={handleCancelBuy}
                      style={{ marginLeft: "auto", padding: "0 10px" }}
                    >
                      X
                    </Button>
                  </WindowHeader>
                  <WindowContent>
                    <div className="group-boxes-container2">
                      <GroupBox
                        className="group-box-styled"
                        label="Ticket Price"
                      >
                        <p>{competition.ticket_price} SOL</p>
                      </GroupBox>
                      <GroupBox className="group-box-styled" label="Split %">
                        {tokenBuyPercentage}% / {poolPercentage}% /{" "}
                        {protocolFeePercentage}%
                      </GroupBox>
                    </div>
                    <div className="group-boxes-container2">
                      <GroupBox
                        className="group-box-styled"
                        label="Buy Tickets"
                      >
                        <p>{ticketAmount}</p>
                      </GroupBox>
                      <GroupBox className="group-box-styled" label="Total Cost">
                        <p>{totalCost} SOL</p>
                      </GroupBox>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        onClick={handleConfirmBuy}
                        style={{ width: "100px" }}
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={handleCancelBuy}
                        style={{ width: "100px" }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </WindowContent>
                </>
              )}
              {popupPhase === "processing" && (
                <>
                  <WindowHeader style={{ display: "flex" }}>
                    Processing.eXe
                  </WindowHeader>
                  <WindowContent>
                    <p>
                      Your tickets are being processed.
                      <br />
                      <img
                        src={BfSticker1}
                        alt="Ticket"
                        style={{ width: "100%", maxWidth: "100px" }}
                      />
                      <br />
                      Please wait...
                    </p>
                  </WindowContent>
                </>
              )}
            </ProcessingPopup>
          </ModalOverlay>,
          document.body
        )}
    </div>
  );
};

export default CompetitionDetailsPage;
