// src/pages/CompetitionPage.js

import React, { useState, useEffect, useRef, useContext } from "react";
import { createPortal } from "react-dom";
import {
  Button,
  TextInput,
  Checkbox,
  NumberInput,
  Window,
  WindowContent,
  WindowHeader,
  Hourglass,
  Slider,
  GroupBox,
} from "react95";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import { useProgram } from "../App";
import BN from "bn.js";
import validator from "validator";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import { NotificationContext } from "../context/NotificationContext";
import io from "socket.io-client";
import CustomReact95Toast from "../components/CustomReact95Toast";
import BfSticker2 from "../assets/gif/bfsticker2.gif";
import BfSticker4 from "../assets/gif/bfsticker4.gif";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const { Buffer } = require("buffer");

// Helper function to ensure token image URLs use ipfs.io instead of cf-ipfs.com
const getWorkingImageUrl = (url) => {
  if (url && url.includes("cf-ipfs.com")) {
    return url.replace("cf-ipfs.com", "ipfs.io");
  }
  return url;
};

// Helper function to validate a Solana address
const isValidSolanaAddress = (address) => {
  try {
    new web3.PublicKey(address);
    return true;
  } catch (error) {
    return false;
  }
};

const CompetitionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const ResponsiveWindow = styled(Window)`
  width: 100%;
  max-width: 600px;
  pointer-events: auto;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
`;

const TokenInfo = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 32px;
    height: 32px;
  }
`;

const InputWithButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SearchButton = styled(Button)`
  width: 90px;
`;

const SliderWrapper = styled.div`
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const SliderDetails = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
`;

const customTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#64b5f6",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "#90caf9",
          "&.Mui-selected": {
            backgroundColor: "#64b5f6",
            color: "#ffffff",
          },
          "&:hover": {
            backgroundColor: "#42a5f5",
          },
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e1e",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#64b5f6",
            },
            "&:hover fieldset": {
              borderColor: "#ffffff",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#90caf9",
            },
          },
          "& .MuiInputBase-input": {
            color: "#ffffff",
          },
          "& .MuiInputLabel-root": {
            color: "#90caf9",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#ffffff",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212",
        },
      },
    },
  },
});

const isValidTelegram = (url) => {
  const telegramRegex =
    /^(https?:\/\/)?(t\.me|telegram\.me)\/[A-Za-z0-9_]{5,32}$/;
  return telegramRegex.test(url);
};

const isValidDiscord = (url) => {
  const discordRegex = /^(https?:\/\/)?(www\.)?discord\.gg\/[A-Za-z0-9]+$/;
  return discordRegex.test(url);
};

const isValidX = (url) => {
  const xRegex =
    /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]{1,15}$/;
  return xRegex.test(url);
};

const isValidURL = (url) => {
  return validator.isURL(url, {
    require_protocol: false,
    require_valid_protocol: false,
    protocols: ["http", "https"],
  });
};

const formatURL = (url) => {
  if (!url) return "";
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const TextInputStyled = styled(TextInput)`
  border: ${(props) => (props.isInvalid ? "2px solid red" : "1px solid #ccc")};
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.85rem;
  margin-top: -8px;
  margin-bottom: 8px;
`;

// Popup for processing (used for both creator and competition phases)
const ProcessingPopup = styled(Window)`
  width: 300px;
  text-align: center;
`;

// Modal overlay that covers the entire viewport and prevents background clicks
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompetitionPage = () => {
  // processingPhase: null = not processing; "creator" = processing creator PDA; "competition" = processing competition creation.
  const [processingPhase, setProcessingPhase] = useState(null);
  const [formData, setFormData] = useState({
    contractAddress: "",
    ticketPrice: 0,
    seedAmount: 0,
    endDate: "",
    endTime: "",
    customAllocationEnabled: false,
    poolPercentage: 20,
    description: "",
    telegramLink: "",
    discordLink: "",
    websiteLink: "",
    xLink: "",
  });
  const [selectedToken, setSelectedToken] = useState(null);
  const [feePercentages, setFeePercentages] = useState({
    totalFeesPercentage: 5,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { wallet, publicKey } = useWallet();
  const { connection } = useConnection();
  const { program } = useProgram();
  const [isWalletErrorDismissed, setIsWalletErrorDismissed] = useState(false);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const ENFORCE_MIN_5_MINUTES = false;
  const socketRef = useRef(null);
  const { addNotification } = useContext(NotificationContext);

  // New state for custom header editing
  const [headerInput, setHeaderInput] = useState("");
  const [isEditingHeader, setIsEditingHeader] = useState(false);
  // Create a ref for the header text input
  const headerInputRef = useRef(null);

  // Auto-focus header input when editing starts
  useEffect(() => {
    if (isEditingHeader && headerInputRef.current) {
      headerInputRef.current.focus();
    }
  }, [isEditingHeader]);

  // Preload both GIFs
  useEffect(() => {
    const BfSticker2Img = new Image();
    BfSticker2Img.src = BfSticker2;
    const BfSticker4Img = new Image();
    BfSticker4Img.src = BfSticker4;
  }, []);

  useEffect(() => {
    const fetchProtocolConfig = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/protocol-config`);
        const feeConfigMap = response.data.reduce((acc, item) => {
          acc[item.key] = parseFloat(item.value);
          return acc;
        }, {});
        const protocol_fee_percentage =
          feeConfigMap.PROTOCOL_FEE_PERCENTAGE || 0;
        const creator_fee_percentage = feeConfigMap.CREATOR_FEE_PERCENTAGE || 0;
        const global_jackpot_fee_percentage =
          feeConfigMap.GLOBAL_JACKPOT_FEE_PERCENTAGE || 0;
        const influencer_fee_percentage =
          feeConfigMap.INFLUENCER_FEE_PERCENTAGE || 0;
        const totalFeesPercentage =
          protocol_fee_percentage +
          creator_fee_percentage +
          global_jackpot_fee_percentage +
          influencer_fee_percentage;
        const globalJackpotEndMS = feeConfigMap.GLOBAL_JACKPOT_END_TIME || 0;
        console.log("Global Jackpot End:", globalJackpotEndMS);
        const globalJackpotDeadline = new Date(
          globalJackpotEndMS * 1000 - 60_000
        );
        console.log("Global Jackpot Deadline:", globalJackpotDeadline);
        setFeePercentages({
          protocol_fee_percentage,
          creator_fee_percentage,
          global_jackpot_fee_percentage,
          influencer_fee_percentage,
          totalFeesPercentage,
          globalJackpotEndMS,
          globalJackpotDeadline,
        });
      } catch (error) {
        console.error("Error fetching protocol configuration:", error);
        setErrors((prev) => ({
          ...prev,
          protocol: "Failed to fetch protocol configuration.",
        }));
      }
    };
    fetchProtocolConfig();
  }, []);

  useEffect(() => {
    socketRef.current = io(API_BASE_URL);
    socketRef.current.on("notification", (data) => {
      console.log("New notification received:", data);
      addNotification(data);
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!publicKey && !isWalletErrorDismissed) {
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Wallet_Connection"
            message="Please connect your wallet to proceed."
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
      console.error("Wallet not connected.");
    } else if (publicKey && !isWalletErrorDismissed) {
      setIsWalletErrorDismissed(true);
    }
  }, [publicKey, isWalletErrorDismissed]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
    let errorMsg = "";
    switch (name) {
      case "telegramLink":
        if (value && !isValidTelegram(value)) {
          errorMsg = "Please enter a valid Telegram link.";
        }
        break;
      case "discordLink":
        if (value && !isValidDiscord(value)) {
          errorMsg = "Please enter a valid Discord link.";
        }
        break;
      case "xLink":
        if (value && !isValidX(value)) {
          errorMsg = "Please enter a valid X (Twitter) link.";
        }
        break;
      case "websiteLink":
        if (value && !isValidURL(value)) {
          errorMsg = "Please enter a valid Website link.";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: errorMsg });
  };

  // Update fetchTokenInfo to first validate the contract address and trim description to 500 characters
  const fetchTokenInfo = async () => {
    setIsLoading(true);
    setErrors({});
    try {
      if (!formData.contractAddress) {
        toast(
          (toastProps) => (
            <CustomReact95Toast
              title="Invalid Contract Address"
              message="Please enter a valid contract address."
              closeToast={toastProps.closeToast}
            />
          ),
          {
            position: "top-right",
            autoClose: 5000,
            closeButton: false,
            hideProgressBar: false,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          }
        );
        setIsLoading(false);
        return;
      }
      // Validate address
      if (!isValidSolanaAddress(formData.contractAddress)) {
        toast(
          (toastProps) => (
            <CustomReact95Toast
              title="Invalid Address"
              message="The contract address is not a valid Solana address."
              closeToast={toastProps.closeToast}
            />
          ),
          {
            position: "top-right",
            autoClose: 5000,
            closeButton: false,
            hideProgressBar: false,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          }
        );
        setIsLoading(false);
        return;
      }
      const response = await axios.get(`${API_BASE_URL}/api/token-info`, {
        params: { address: formData.contractAddress },
      });
      if (
        response.data &&
        response.data.data &&
        Object.keys(response.data.data).length > 0
      ) {
        const tokenData = response.data.data;
        if (tokenData.icon) {
          tokenData.icon = getWorkingImageUrl(tokenData.icon);
        }
        console.log(
          "Token Info fetched successfully:",
          JSON.stringify(tokenData, null, 2)
        );
        setSelectedToken(tokenData);
        const metadata = tokenData.metadata || {};
        const extensions = metadata.extensions || {};
        // Trim description to 500 characters if needed
        const trimmedDescription =
          metadata.description && metadata.description.length > 500
            ? metadata.description.substring(0, 500)
            : metadata.description || "";
        const fiveMinutesLater = new Date(Date.now() + 5 * 60_000);
        const newDate = fiveMinutesLater.toLocaleDateString("en-CA");
        const newTime = fiveMinutesLater.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
        setFormData((prevFormData) => ({
          ...prevFormData,
          description: trimmedDescription,
          telegramLink: extensions.telegram || "",
          discordLink: extensions.discord || "",
          websiteLink: extensions.website || "",
          xLink: extensions.twitter || "",
          ticketPrice: 0.5,
          endDate: newDate,
          endTime: newTime,
        }));
      } else {
        throw new Error(
          "Failed to fetch token information. Please check the contract address."
        );
      }
    } catch (error) {
      console.error("Error fetching token metadata:", error.message);
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Token_Error"
            message={
              error.response?.data?.errors?.message ||
              "Failed to fetch token information. Please check the contract address."
            }
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetTokenInfo = () => {
    setFormData({
      contractAddress: "",
      ticketPrice: 0,
      seedAmount: 0,
      endDate: "",
      endTime: "",
      customAllocationEnabled: false,
      poolPercentage: 20,
      description: "",
      telegramLink: "",
      discordLink: "",
      websiteLink: "",
      xLink: "",
    });
    setSelectedToken(null);
    setErrors({});
  };

  const handleNumberChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSliderChange = (newValue) => {
    setFormData({ ...formData, poolPercentage: newValue });
  };

  const isFormValid = () => {
    const {
      contractAddress,
      ticketPrice,
      endDate,
      endTime,
      description,
      seedAmount,
      customAllocationEnabled,
      poolPercentage,
    } = formData;
    if (
      !contractAddress ||
      !ticketPrice ||
      ticketPrice <= 0 ||
      !endDate ||
      !endTime ||
      !description.trim()
    ) {
      return false;
    }
    if (seedAmount < 0) {
      return false;
    }
    if (
      customAllocationEnabled &&
      (!poolPercentage || poolPercentage < 5 || poolPercentage > 90)
    ) {
      return false;
    }
    return true;
  };

  // Compute the window title.
  // If a custom header exists, replace all spaces with underscores.
  const computeWindowTitle = () => {
    let title = "CreateCompetition.eXe";
    if (selectedToken) {
      const buyTokens =
        100 - formData.poolPercentage - feePercentages.totalFeesPercentage;
      const formattedHeader =
        headerInput && headerInput.trim() !== ""
          ? headerInput.trim().replace(/\s+/g, "_")
          : "";
      title = formattedHeader
        ? `${formattedHeader}_${selectedToken.symbol}_${buyTokens}_${formData.poolPercentage}.eXe`
        : `${selectedToken.symbol}_${buyTokens}_${formData.poolPercentage}.eXe`;
    }
    return title;
  };

  // Handler for clicking on the title to edit custom header (only active when token info is available)
  const handleTitleClick = () => {
    if (selectedToken) {
      setIsEditingHeader(true);
    }
  };

  // Handler for when header input loses focus
  const handleHeaderBlur = () => {
    setIsEditingHeader(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errorsArr = [];
    const { telegramLink, discordLink, websiteLink, xLink, contractAddress } =
      formData;
    const userEndTimeMS = new Date(
      `${formData.endDate}T${formData.endTime}`
    ).getTime();
    const deadlineMS = feePercentages.globalJackpotEndMS * 1000 - 60_000;
    if (userEndTimeMS > deadlineMS) {
      errorsArr.push(
        "End date/time cannot exceed the global jackpot deadline (1 min before)."
      );
    }
    const numericPrice = parseFloat(formData.ticketPrice);
    if (!contractAddress) {
      errorsArr.push("Contract address is required.");
    } else if (!isValidSolanaAddress(contractAddress)) {
      errorsArr.push("Please enter a valid Solana contract address.");
    }
    if (!selectedToken) {
      errorsArr.push("Please search and validate the token address.");
    }
    if (isNaN(numericPrice) || numericPrice <= 0 || numericPrice < 0.01) {
      errorsArr.push(
        "Ticket price must be a positive number greater than or equal to 0.01 SOL"
      );
    }
    if (!formData.endDate) {
      errorsArr.push("End date is required.");
    }
    if (!formData.endTime) {
      errorsArr.push("End time is required.");
    }
    if (!formData.description.trim()) {
      errorsArr.push("Description is required.");
    }
    if (formData.seedAmount < 0) {
      errorsArr.push("Seed amount cannot be negative.");
    }
    if (formData.customAllocationEnabled) {
      if (
        !formData.poolPercentage ||
        formData.poolPercentage < 5 ||
        formData.poolPercentage > 90
      ) {
        errorsArr.push("Pool percentage must be between 5 and 90.");
      }
    }
    if (telegramLink && !isValidTelegram(telegramLink)) {
      errorsArr.push("Please enter a valid Telegram link.");
    }
    if (discordLink && !isValidDiscord(discordLink)) {
      errorsArr.push("Please enter a valid Discord link.");
    }
    if (xLink && !isValidX(xLink)) {
      errorsArr.push("Please enter a valid X (Twitter) link.");
    }
    if (websiteLink && !isValidURL(websiteLink)) {
      errorsArr.push("Please enter a valid Website link.");
    }
    if (errorsArr.length > 0) {
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Form Error"
            message={errorsArr.join("\n")}
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
      setIsLoading(false);
      return;
    }
    try {
      if (!publicKey || !wallet) {
        throw new Error("Wallet not connected.");
      }
      if (!(publicKey instanceof web3.PublicKey)) {
        throw new Error("Invalid public key.");
      }
      const [creatorPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("creator"), publicKey.toBuffer()],
        program.programId
      );
      console.log("Creator PDA:", creatorPDA.toBase58());
      const creatorAccount = await connection.getAccountInfo(creatorPDA);
      if (!creatorAccount) {
        console.log("Initializing creator account...");
        // Set processingPhase to "creator"
        setProcessingPhase("creator");
        try {
          const instruction = await program.methods
            .initializeCreator()
            .accounts({
              creator: creatorPDA,
              authority: publicKey,
              systemProgram: web3.SystemProgram.programId,
            })
            .instruction();
          const transaction = new web3.Transaction().add(instruction);
          const { blockhash, lastValidBlockHeight } =
            await connection.getLatestBlockhash();
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = publicKey;
          let signedTransaction;
          try {
            signedTransaction = await wallet.adapter.signTransaction(
              transaction
            );
          } catch (error) {
            // If user cancels the wallet prompt, close the overlay
            if (
              error?.message &&
              error.message.toLowerCase().includes("user rejected")
            ) {
              setProcessingPhase(null);
              return;
            }
            throw error;
          }
          const signature = await connection.sendRawTransaction(
            signedTransaction.serialize(),
            {
              skipPreflight: false,
              preflightCommitment: "processed",
            }
          );
          console.log("Creator account transaction signature:", signature);
          await connection.confirmTransaction(
            { signature, blockhash, lastValidBlockHeight },
            "finalized"
          );
          const creatorAccountInfo = await connection.getAccountInfo(
            creatorPDA,
            { commitment: "finalized" }
          );
          if (!creatorAccountInfo) {
            throw new Error(
              "Failed to fetch creator account data even at finalized commitment."
            );
          }
          console.log("Creator account transaction CONFIRMED!");
        } finally {
          // Immediately switch to competition phase without a gap
          setProcessingPhase("competition");
        }
      } else {
        console.log("Creator account already exists.");
        setProcessingPhase("competition");
      }
      console.log("Fetching creator account data...");
      const creatorAccountInfo = await connection.getAccountInfo(creatorPDA);
      if (!creatorAccountInfo) {
        throw new Error("Failed to fetch creator account data.");
      }
      const dataView = new DataView(creatorAccountInfo.data.buffer);
      const competitionCount = Number(dataView.getBigUint64(72, true));
      console.log("Competition count:", competitionCount);
      const competitionCountBuffer = Buffer.alloc(8);
      competitionCountBuffer.writeBigUInt64LE(BigInt(competitionCount));
      const [competitionPDA] = await web3.PublicKey.findProgramAddress(
        [
          Buffer.from("competition"),
          creatorPDA.toBuffer(),
          competitionCountBuffer,
        ],
        program.programId
      );
      console.log("Competition PDA:", competitionPDA.toBase58());
      const [protocolConfigPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_config")],
        program.programId
      );
      const endTimestamp = new BN(
        Math.floor(
          new Date(`${formData.endDate}T${formData.endTime}`).getTime() / 1000
        )
      );
      const formattedTicketPrice = new BN(
        Math.floor(formData.ticketPrice * web3.LAMPORTS_PER_SOL)
      );
      const formattedSeedAmount = new BN(
        Math.floor(formData.seedAmount * web3.LAMPORTS_PER_SOL)
      );
      const poolPercentage = formData.customAllocationEnabled
        ? formData.poolPercentage
        : 20;
      const tokenBuyPercentage =
        100 - poolPercentage - feePercentages.totalFeesPercentage;
      if (isNaN(tokenBuyPercentage)) {
        throw new Error("Invalid tokenBuyPercentage calculation");
      }
      console.log("Token Buy Percentage:", tokenBuyPercentage);
      const poolPercentageBN = new BN(poolPercentage);
      console.log("Competition creation parameters:", {
        description: formData.description,
        ticketPrice: formattedTicketPrice.toString(),
        endTime: endTimestamp.toString(),
        tokenBuyPercentage: tokenBuyPercentage.toString(),
        poolPercentage: poolPercentageBN.toString(),
        tokenAddress: formData.contractAddress,
        seedAmount: formattedSeedAmount.toString(),
      });
      const tokenBuyPercentageBPS = tokenBuyPercentage * 100;
      const poolPercentageBPS = poolPercentage * 100;
      const tokenPubkey = new web3.PublicKey(formData.contractAddress);
      console.log("Token PublicKey:", tokenPubkey.toBase58());
      const instruction = await program.methods
        .createCompetition(
          formData.description,
          formattedTicketPrice,
          endTimestamp,
          tokenBuyPercentageBPS,
          poolPercentageBPS,
          tokenPubkey,
          formattedSeedAmount
        )
        .accounts({
          creator: creatorPDA,
          authority: publicKey,
          competition: competitionPDA,
          protocolConfig: protocolConfigPDA,
          systemProgram: web3.SystemProgram.programId,
        })
        .instruction();
      const transaction = new web3.Transaction().add(instruction);
      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
      console.log(
        "Transaction prepared for competition creation:",
        transaction
      );
      if (wallet.adapter.signTransaction) {
        console.log("Using wallet adapter for signing competition creation...");
        let signedTransaction;
        try {
          signedTransaction = await wallet.adapter.signTransaction(transaction);
        } catch (error) {
          // If the user cancels the wallet prompt, close the overlay
          if (
            error?.message &&
            error.message.toLowerCase().includes("user rejected")
          ) {
            setProcessingPhase(null);
            return;
          }
          throw error;
        }
        let txSignature = await connection.sendRawTransaction(
          signedTransaction.serialize(),
          {
            skipPreflight: false,
            preflightCommitment: "confirmed",
          }
        );
        console.log("Competition created with signature:", txSignature);
        await connection.confirmTransaction(
          { signature: txSignature, blockhash, lastValidBlockHeight },
          "confirmed"
        );
        console.log("Competition creation transaction CONFIRMED!");
        // Clear overlay after competition creation is done
        setProcessingPhase(null);
        const solanaLink = `https://solscan.io/token/${formData.contractAddress}`;
        const formattedTelegramLink = formatURL(formData.telegramLink);
        const formattedDiscordLink = formatURL(formData.discordLink);
        const formattedWebsiteLink = formatURL(formData.websiteLink);
        const formattedXLink = formatURL(formData.xLink);
        console.log("Payload being sent to backend:", {
          creator_id: publicKey.toBase58(),
          ticket_price: formData.ticketPrice,
          duration: Math.floor(endTimestamp.toNumber() - Date.now() / 1000),
          end_time: new Date(endTimestamp.toNumber() * 1000).toISOString(),
          token: formData.contractAddress,
          pool_percentage: poolPercentage,
          seed_amount: formData.seedAmount,
          description: formData.description,
          header: headerInput, // include custom header
          txSignature,
          pda_address: competitionPDA.toBase58(),
          token_buy_percentage: tokenBuyPercentage,
          token_name: selectedToken?.name || "",
          token_symbol: selectedToken?.symbol || "",
          token_icon: getWorkingImageUrl(selectedToken?.icon),
          telegram_link: formattedTelegramLink || null,
          discord_link: formattedDiscordLink || null,
          solana_link: solanaLink,
          website_link: formattedWebsiteLink || null,
          x_link: formattedXLink || null,
        });
        try {
          const response = await axios.post(`${API_BASE_URL}/competitions`, {
            creator_id: publicKey.toBase58(),
            ticket_price: formData.ticketPrice,
            duration: Math.floor(endTimestamp.toNumber() - Date.now() / 1000),
            end_time: new Date(endTimestamp.toNumber() * 1000).toISOString(),
            token: formData.contractAddress,
            pool_percentage: poolPercentage,
            seed_amount: formData.seedAmount,
            description: formData.description,
            header: headerInput, // send the new header field
            txSignature,
            pda_address: competitionPDA.toBase58(),
            token_buy_percentage: tokenBuyPercentage,
            token_name: selectedToken?.name || "",
            token_symbol: selectedToken?.symbol || "",
            token_icon: getWorkingImageUrl(selectedToken?.icon),
            telegram_link: formattedTelegramLink || null,
            discord_link: formattedDiscordLink || null,
            solana_link: solanaLink,
            website_link: formattedWebsiteLink || null,
            x_link: formattedXLink || null,
          });
          navigate(`/competition/${response.data.competition.id}`);
          setFormData({
            contractAddress: "",
            ticketPrice: 0,
            seedAmount: 0,
            endDate: "",
            endTime: "",
            customAllocationEnabled: false,
            poolPercentage: 20,
            description: "",
            telegramLink: "",
            discordLink: "",
            websiteLink: "",
            xLink: "",
          });
          setSelectedToken(null);
          setErrors({});
          setHeaderInput(""); // clear custom header input on success
        } catch (apiError) {
          console.error("Error sending data to the backend:", apiError);
          toast(
            (toastProps) => (
              <CustomReact95Toast
                title="Submission Failed"
                message="Failed to save competition to the backend. Please try again."
                closeToast={toastProps.closeToast}
              />
            ),
            {
              position: "top-right",
              autoClose: 5000,
              closeButton: false,
              hideProgressBar: false,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
            }
          );
        }
      } else {
        throw new Error("Wallet does not support signing transactions.");
      }
    } catch (error) {
      console.error("Error during competition creation:", error);
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Competition Creation Failed"
            message={
              error.message || "Failed to create competition. Please try again."
            }
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Compute the default window title
  let windowTitle = computeWindowTitle();

  const handleBackNavigation = () => {
    navigate(-1);
  };

  // The form should be active only when token info has been fetched
  const tokenFetched = selectedToken !== null;

  return (
    <Wrapper>
      <ResponsiveWindow>
        <WindowHeader
          className="window-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            cursor: tokenFetched ? "pointer" : "default",
          }}
          onClick={tokenFetched ? handleTitleClick : undefined}
        >
          {selectedToken?.icon && (
            <img
              src={getWorkingImageUrl(selectedToken?.icon)}
              alt={selectedToken?.symbol}
              style={{
                position: "absolute",
                left: 5,
                top: 5,
                width: 24,
                height: 24,
                pointerEvents: "none",
              }}
            />
          )}
          {isEditingHeader ? (
            <TextInput
              ref={headerInputRef}
              value={headerInput}
              onChange={(e) => setHeaderInput(e.target.value)}
              onBlur={handleHeaderBlur}
              placeholder="Enter custom header"
              fullWidth
              disabled={!tokenFetched}
              style={{ flex: 1 }}
            />
          ) : (
            <span style={{ marginLeft: selectedToken?.icon ? "30px" : 0 }}>
              {windowTitle}
            </span>
          )}
          {/* Only show the X button when not editing header */}
          {!isEditingHeader && (
            <Button
              onClick={handleBackNavigation}
              style={{ marginLeft: "auto", padding: "0 10px" }}
            >
              X
            </Button>
          )}
        </WindowHeader>
        <WindowContent>
          <CompetitionForm onSubmit={handleSubmit}>
            <label style={{ marginTop: "1rem" }}>Token Address</label>
            <InputWithButton>
              <TextInput
                placeholder="Enter Contract Address"
                name="contractAddress"
                value={formData.contractAddress}
                onChange={handleChange}
                fullWidth
                // Disable editing the address once token info is fetched
                disabled={tokenFetched}
              />
              <SearchButton
                type="button"
                onClick={selectedToken ? resetTokenInfo : fetchTokenInfo}
                disabled={isLoading || !formData.contractAddress}
              >
                {selectedToken ? (
                  "Reset"
                ) : isLoading ? (
                  <Hourglass size={16} />
                ) : (
                  "Search"
                )}
              </SearchButton>
            </InputWithButton>
            {errors.fetchToken && (
              <ErrorMessage>{errors.fetchToken}</ErrorMessage>
            )}
            {errors.wallet && <ErrorMessage>{errors.wallet}</ErrorMessage>}
            <label style={{ marginTop: "1rem" }}>Description</label>
            <TextInput
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              maxLength={500}
              fullWidth
              disabled={!tokenFetched}
            />
            {errors.description && (
              <ErrorMessage>{errors.description}</ErrorMessage>
            )}
            <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
              <div style={{ flex: 1 }}>
                <label>Ticket Price</label>
                <TextInput
                  type="number"
                  step="0.01"
                  min="0.01"
                  name="ticketPrice"
                  value={formData.ticketPrice}
                  onChange={(e) => {
                    setFormData({ ...formData, ticketPrice: e.target.value });
                  }}
                  placeholder="Enter ticket price"
                  disabled={!tokenFetched}
                />
                {errors.ticketPrice && (
                  <ErrorMessage>{errors.ticketPrice}</ErrorMessage>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <label>Seed Amount</label>
                <NumberInput
                  defaultValue={formData.seedAmount}
                  step={0.1}
                  min={0}
                  onChange={(value) => {
                    if (value >= 0) {
                      handleNumberChange("seedAmount", value);
                    }
                  }}
                  width="100%"
                  disabled={!tokenFetched}
                />
                {errors.seedAmount && (
                  <ErrorMessage>{errors.seedAmount}</ErrorMessage>
                )}
              </div>
            </div>
            <Checkbox
              label="Customize Allocation?"
              name="customAllocationEnabled"
              checked={formData.customAllocationEnabled}
              onChange={handleChange}
              disabled={!tokenFetched}
            />
            {formData.customAllocationEnabled && (
              <GroupBox>
                <SliderWrapper>
                  <Label>Allocate Ticket Price:</Label>
                  <Slider
                    size="100%"
                    min={5}
                    max={90}
                    value={formData.poolPercentage}
                    onChange={handleSliderChange}
                    disabled={!tokenFetched}
                  />
                  <SliderDetails>
                    <span>
                      Buy Tokens:{" "}
                      {100 -
                        formData.poolPercentage -
                        feePercentages.totalFeesPercentage}
                      %
                    </span>
                    <span>Jackpot/Pool: {formData.poolPercentage}%</span>
                    <span>Protocol: {feePercentages.totalFeesPercentage}%</span>
                  </SliderDetails>
                  {errors.poolPercentage && (
                    <ErrorMessage>{errors.poolPercentage}</ErrorMessage>
                  )}
                </SliderWrapper>
              </GroupBox>
            )}
            {errors.backend && <ErrorMessage>{errors.backend}</ErrorMessage>}
            {errors.submission && (
              <ErrorMessage>{errors.submission}</ErrorMessage>
            )}
            <label style={{ marginTop: "1rem" }}>End Date & Time</label>
            <ThemeProvider theme={customTheme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  value={
                    formData.endDate && formData.endTime
                      ? new Date(`${formData.endDate}T${formData.endTime}`)
                      : null
                  }
                  onChange={(date) => {
                    if (date) {
                      setFormData({
                        ...formData,
                        endDate: date.toLocaleDateString("en-CA"),
                        endTime: date.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        }),
                      });
                    }
                  }}
                  minDateTime={
                    ENFORCE_MIN_5_MINUTES
                      ? new Date(Date.now() + 5 * 60_000)
                      : new Date()
                  }
                  maxDateTime={feePercentages.globalJackpotDeadline}
                  timeSteps={{ minutes: 1 }}
                  ampm={false}
                  disabled={!tokenFetched}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      error={!!errors.endDate}
                      helperText={errors.endDate || ""}
                    />
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider>
            {errors.endDate && <ErrorMessage>{errors.endDate}</ErrorMessage>}
            <label
              style={{
                cursor: "pointer",
                marginTop: "1rem",
              }}
              onClick={() => setShowOptionalFields(!showOptionalFields)}
            >
              Socials <span>{showOptionalFields ? "▼" : "▶"} </span>
            </label>
            {showOptionalFields && (
              <>
                <GroupBox>
                  <div style={{ marginBottom: "1rem" }}>
                    {formData.websiteLink || showOptionalFields ? (
                      <>
                        <label>Website Link</label>
                        <TextInput
                          as={TextInputStyled}
                          isInvalid={!!errors.websiteLink}
                          name="websiteLink"
                          value={formData.websiteLink}
                          onChange={handleChange}
                          placeholder="e.g., google.com"
                          fullWidth
                          disabled={!tokenFetched}
                        />
                        {errors.websiteLink && (
                          <ErrorMessage>{errors.websiteLink}</ErrorMessage>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    {formData.telegramLink || showOptionalFields ? (
                      <>
                        <label>Telegram Link</label>
                        <TextInput
                          as={TextInputStyled}
                          isInvalid={!!errors.telegramLink}
                          name="telegramLink"
                          value={formData.telegramLink}
                          onChange={handleChange}
                          placeholder="e.g., t.me/your_telegram"
                          fullWidth
                          disabled={!tokenFetched}
                        />
                        {errors.telegramLink && (
                          <ErrorMessage>{errors.telegramLink}</ErrorMessage>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    {formData.discordLink || showOptionalFields ? (
                      <>
                        <label>Discord Link</label>
                        <TextInput
                          as={TextInputStyled}
                          isInvalid={!!errors.discordLink}
                          name="discordLink"
                          value={formData.discordLink}
                          onChange={handleChange}
                          placeholder="e.g., discord.gg/your_discord"
                          fullWidth
                          disabled={!tokenFetched}
                        />
                        {errors.discordLink && (
                          <ErrorMessage>{errors.discordLink}</ErrorMessage>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    {formData.xLink || showOptionalFields ? (
                      <>
                        <label>X Link</label>
                        <TextInput
                          as={TextInputStyled}
                          isInvalid={!!errors.xLink}
                          name="xLink"
                          value={formData.xLink}
                          onChange={handleChange}
                          placeholder="e.g., twitter.com/your_handle"
                          fullWidth
                          disabled={!tokenFetched}
                        />
                        {errors.xLink && (
                          <ErrorMessage>{errors.xLink}</ErrorMessage>
                        )}
                      </>
                    ) : null}
                  </div>
                </GroupBox>
              </>
            )}
            <Button
              type="submit"
              disabled={isLoading || !isFormValid() || !tokenFetched}
              style={{ marginTop: "1rem" }}
            >
              {isLoading ? "Creating..." : "Create Competition"}
            </Button>
          </CompetitionForm>
        </WindowContent>
      </ResponsiveWindow>
      {processingPhase &&
        createPortal(
          <ModalOverlay>
            <ProcessingPopup>
              {processingPhase === "creator" ? (
                <>
                  <WindowHeader style={{ display: "flex" }}>
                    Processing.eXe
                  </WindowHeader>
                  <WindowContent>
                    <p>
                      Initializing creator account. <br />
                      Please wait...
                      <br />
                      <img
                        src={BfSticker2}
                        alt="Processing"
                        style={{ width: "100%", maxWidth: "100px" }}
                      />
                      <br />
                      It's a one time thing...
                    </p>
                  </WindowContent>
                </>
              ) : processingPhase === "competition" ? (
                <>
                  <WindowHeader style={{ display: "flex" }}>
                    Creating Competition
                  </WindowHeader>
                  <WindowContent>
                    <p>
                      Your competition is being created.
                      <br />
                      <img
                        src={BfSticker4}
                        alt="Creating"
                        style={{ width: "100%", maxWidth: "100px" }}
                      />
                      <br />
                      Please wait...
                    </p>
                  </WindowContent>
                </>
              ) : null}
            </ProcessingPopup>
          </ModalOverlay>,
          document.body
        )}
    </Wrapper>
  );
};

export default CompetitionPage;
