// NotificationContext.js

import React, { createContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { io } from "socket.io-client";
import CustomReact95Toast from "../components/CustomReact95Toast";

export const NotificationContext = createContext();

const NotificationProvider = ({ children, walletAddress }) => {
  // 1) Keep track of the unread notifications if you want
  const [notifications, setNotifications] = useState([]);

  // 2) Also track "live updates enabled"
  const [liveUpdatesEnabled, setLiveUpdatesEnabled] = useState(true);

  const [jackpot, setJackpot] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [participants, setParticipants] = useState(0);

  const socketRef = useRef(null);

  // In NotificationContext.js
  useEffect(() => {
    if (!walletAddress) return;

    const fetchInitialJackpotData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/global-jackpot/stats`
        );
        // Adjust the property names based on your API response:
        // If your API returns { totalJackpot, totalTickets, totalParticipants }
        const { totalJackpot, totalTickets, totalParticipants } = response.data;
        setJackpot(totalJackpot);
        setTotalTickets(totalTickets);
        // Map totalParticipants to your context’s participants state:
        setParticipants(totalParticipants);
      } catch (error) {
        console.error("Error fetching initial jackpot data:", error);
      }
    };

    fetchInitialJackpotData();
  }, [walletAddress]);

  // 3) On mount / walletAddress change: set up a single socket connection
  useEffect(() => {
    if (!walletAddress) {
      // If no wallet address, or user logs out, maybe disconnect socket
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
      return;
    }

    // If we already have a socket, disconnect to create a fresh one
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    // Create the socket
    const socket = io(process.env.REACT_APP_API_BASE_URL);

    // Identify user for real-time notifications
    if (walletAddress) {
      socket.emit("identify", walletAddress);
    }

    // Listen for "notification"
    socket.on("notification", async (data) => {
      setNotifications((prev) => [data, ...prev]);

      // Show toast
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title={data.title || data.type || "Notification"}
            message={data.message}
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );

      // Immediately mark this notification as read in the backend
      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/notifications/mark-as-read`,
          {
            notificationIds: [data.id], // Only mark the received notification
          }
        );

        // Optional: Remove the notification from unread state
        setNotifications((prev) => prev.filter((n) => n.id !== data.id));
      } catch (error) {
        console.error("Error marking notification as read:", error);
      }
    });

    socket.on("newCompetition", (competition) => {
      if (!liveUpdatesEnabled) return;

      if (competition.creator_id === walletAddress) {
        return;
      }
    });

    // Listen for "competitionUpdated"
    socket.on("competitionUpdated", (updatedComp) => {
      if (!liveUpdatesEnabled) return;
      console.log("Competition updated:", updatedComp);
      // e.g. update local state or show toast
    });

    // Listen for "globalJackpotUpdate"
    socket.on(
      "globalJackpotUpdate",
      ({ totalJackpot, totalTickets, participants }) => {
        if (!liveUpdatesEnabled) return;
        // console.log("Jackpot updated:", totalJackpot);
        //console.log("Total tickets:", totalTickets);
        //console.log("Participants:", participants);

        // Instead of setLiveUpdatesEnabled(...),
        // use your separate pieces of state:
        setJackpot(totalJackpot);
        setTotalTickets(totalTickets);
        setParticipants(participants);
      }
    );

    socket.on("connect", () => {
      console.log("Socket connected:", socket.id);
    });
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socketRef.current = socket; // store reference

    return () => {
      socket.disconnect();
      socketRef.current = null;
    };
  }, [walletAddress, liveUpdatesEnabled]);

  // 4) Fetch unread notifications from the DB once wallet is known
  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      if (!walletAddress) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/notifications/${walletAddress}`
        );
        const unread = response.data; // real rows from DB

        // Show them
        unread.forEach((notification) => {
          toast(
            (toastProps) => (
              <CustomReact95Toast
                title={
                  notification.title || notification.type || "Notification"
                }
                message={notification.message}
                closeToast={toastProps.closeToast}
              />
            ),
            {
              position: "top-right",
              autoClose: 5000,
              closeButton: false,
              hideProgressBar: false,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
            }
          );
        });

        // Mark them as read
        const notificationIds = unread.map((n) => n.id);
        if (notificationIds.length) {
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/notifications/mark-as-read`,
            { notificationIds }
          );
        }
      } catch (err) {
        console.error("Error fetching unread notifications:", err);
      }
    };

    fetchUnreadNotifications();
  }, [walletAddress]);

  // 5) Provide any context values needed
  return (
    <NotificationContext.Provider
      value={{
        socketRef,
        notifications,
        setNotifications,
        liveUpdatesEnabled,
        setLiveUpdatesEnabled,
        jackpot,
        setJackpot,
        totalTickets,
        setTotalTickets,
        participants,
        setParticipants,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
