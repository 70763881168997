import React from "react";
import { Window, WindowHeader, Button, WindowContent } from "react95";
import styled from "styled-components";

// No overlay, just a small Window
function CustomReact95Toast({ title, message, closeToast }) {
  return (
    <Window style={{ width: "100%" }}>
      <WindowHeader
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{title || "Alert"}.eXe</span>
        <Button onClick={closeToast}>X</Button>
      </WindowHeader>
      <WindowContent>{message}</WindowContent>
    </Window>
  );
}

export default CustomReact95Toast;
