// src/pages/ProfilePage.js

import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Window,
  WindowContent,
  WindowHeader,
  Button,
  Tabs,
  Tab,
  TabBody,
  Avatar,
  Frame,
  GroupBox,
  Counter,
  ProgressBar,
} from "react95";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CompetitionCreator from "../components/CompetitionCreator";
import ProfileAvatarSelector from "../components/ProfileAvatarSelector";
import { ThemeContext } from "../context/ThemeContext"; // Import ThemeContext
import Marquee from "react-fast-marquee";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useProgram } from "../App";
import BN from "bn.js";
import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import CustomReact95Toast from "../components/CustomReact95Toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Styled Components
const ProfileWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;

const ProfileWindow = styled(Window)`
  height: auto;
  width: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1290px) {
    width: 98%;
  }
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 2%;
`;

const WindowContentStyled = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const WindowContentStyled2 = styled(WindowContent)`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TicketsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: space-evenly;
`;

const TicketCard = styled(Window)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TokenAvatar = styled(Avatar)`
  width: 75px;
  height: 75px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ContractAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0 10px;
`;

const InfoGroup = styled(GroupBox)`
  margin: 0 10px;
  height: 100%;
  padding: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    margin: 5px;
    font-size: 1rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
  position: relative; /* Make the wrapper relative so the LIVE text can be absolutely positioned */
`;

const CompetitionList = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr;
`;

const TokenNameWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const NestedTabBody = styled(TabBody)`
  width: 100%;
  @media (max-width: 512px) {
    width: 113%;
  }
`;

const LargeButton = styled(Button)`
  font-size: 2rem;
  padding: 1rem 1rem;
  width: 25%;
  height: 50px;
  variant: primary;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RainbowText = styled.span`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(to right, ${({ colors }) => colors.join(", ")});
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 400% 100%;

  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

const RainbowTextHeader = styled.span`
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(to right, ${({ colors }) => colors.join(", ")});
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 400% 100%;

  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }
`;

// Helper Functions
const formatPrizeAmount = (amount) => {
  const num = parseFloat(amount);
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

const calculateRemainingTime = (endTime) => {
  const now = new Date().getTime();
  const distance = new Date(endTime).getTime() - now;

  if (distance < 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

const formatTime = (time) => {
  return `${time.days.toString().padStart(2, "0")}${time.hours
    .toString()
    .padStart(2, "0")}${time.minutes.toString().padStart(2, "0")}${time.seconds
    .toString()
    .padStart(2, "0")}`;
};

const truncateAddress = (address) =>
  `${address.slice(0, 5)}...${address.slice(-5)}`;

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [userCompetitions, setUserCompetitions] = useState([]);
  const [ticketCompetitions, setTicketCompetitions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTicketTab, setActiveTicketTab] = useState(0);
  const [feePercentages, setFeePercentages] = useState(null); // State for fee percentages
  const { wallet_address } = useParams();
  const navigate = useNavigate();
  const { changeTheme } = useContext(ThemeContext); // Use changeTheme from context
  const [unclaimedWinners, setUnclaimedWinners] = useState([]);
  const { program } = useProgram();
  const { publicKey, wallet } = useWallet();
  const { connection } = useConnection();
  const [userWinners, setUserWinners] = useState([]);
  const [isProcessingPrize, setIsProcessingPrize] = useState(false);

  // Fetch fee percentages from backend
  useEffect(() => {
    const fetchFeePercentages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/fee-percentages`);
        setFeePercentages(response.data);
      } catch (error) {
        console.error("Error fetching fee percentages:", error);
      }
    };
    fetchFeePercentages();
  }, []);

  useEffect(() => {
    const fetchUnclaimedWinners = async () => {
      if (!wallet_address) return;

      try {
        // GET /api/claim/:wallet_address
        const res = await axios.get(
          `${API_BASE_URL}/api/claim/${wallet_address}`
        );
        setUnclaimedWinners(res.data); // store in state
      } catch (err) {
        console.error("Error fetching unclaimed winners:", err);
        // optionally show an error toast
      }
    };

    fetchUnclaimedWinners();
  }, [wallet_address]);

  useEffect(() => {
    const fetchUserWinners = async () => {
      if (!wallet_address) return;
      try {
        // GET /api/winners/user/:walletAddress
        const res = await axios.get(
          `${API_BASE_URL}/api/winners/user/${wallet_address}`
        );
        setUserWinners(res.data);
      } catch (err) {
        console.error("Error fetching user winners:", err);
      }
    };

    fetchUserWinners();
  }, [wallet_address]);

  async function handleClaimPrize(winnerRow) {
    // Optional: track UI state (spinner, button disable, etc.)
    setIsProcessingPrize(true);

    try {
      // 1) Basic checks
      if (!publicKey || !wallet) {
        throw new Error("Please connect your wallet first!");
      }

      // 2) Fetch the competition from backend
      const competitionId = winnerRow.competition_id;
      const { data: competition } = await axios.get(
        `${API_BASE_URL}/competitions/${competitionId}`
      );

      // 3) Derive PDAs according to your seeds
      //    seeds: b"competition", competition.creator, competition_number.to_le_bytes()
      const competitionPda = new PublicKey(competition.pda_address);
      const [ticketPda] = await PublicKey.findProgramAddress(
        [competitionPda.toBuffer(), publicKey.toBuffer()],
        program.programId
      );

      // console.log("Competition PDA:", competitionPda.toBase58());
      // console.log("Ticket PDA:", ticketPda.toBase58());

      // 4) Build the claim transaction manually
      const transaction = new Transaction();
      const instruction = await program.methods
        .claimPrize()
        .accounts({
          competition: competitionPda,
          winner: publicKey, // must be the signer
          ticket: ticketPda,
          // If your program requires it:
          // systemProgram: web3.SystemProgram.programId,
        })
        .instruction();

      transaction.add(instruction);

      // 5) Get latest blockhash & set fee payer
      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      // 6) Sign
      const signedTx = await wallet.adapter.signTransaction(transaction);

      // 7) Send the transaction
      const txSignature = await connection.sendRawTransaction(
        signedTx.serialize(),
        {
          skipPreflight: false,
          preflightCommitment: "processed",
        }
      );

      console.log("Transaction signature:", txSignature);

      // 8) Confirm transaction (optional but recommended for finalization)
      await connection.confirmTransaction(
        {
          signature: txSignature,
          blockhash,
          lastValidBlockHeight,
        },
        "finalized"
      );
      console.log("Transaction finalized!");

      // 9) Post to /api/winners/claim => mark as claimed & log in DB
      const payload = {
        winnerId: winnerRow.id,
        user_id: publicKey.toBase58(),
        txSignature,
        blockhash,
        // any other info you'd like, e.g. details, etc.
      };

      const response = await axios.post(
        `${API_BASE_URL}/api/winners/claim`,
        payload
      );
      if (response.status === 200) {
        console.log("Successfully logged claim in backend:", response.data);
      } else {
        console.warn("Claim was successful but logging failed:", response.data);
      }

      console.log("Prize Claimed");
      setUnclaimedWinners((prev) => prev.filter((w) => w.id !== winnerRow.id));
    } catch (err) {
      console.error("Error claiming prize:", err);
      toast(
        (toastProps) => (
          <CustomReact95Toast
            title="Error"
            message={err.message || "Unable to claim prize."}
            closeToast={toastProps.closeToast}
          />
        ),
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: false,
          closeButton: false,
          hideProgressBar: false,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        }
      );
    }
  }

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const randomColors = useMemo(() => {
    return [
      getRandomColor(),
      getRandomColor(),
      getRandomColor(),
      getRandomColor(),
    ];
  }, []);

  const sortedWinners = useMemo(() => {
    const sorted = [...userWinners].sort((a, b) => {
      if (!a.claimed && b.claimed) return -1;
      if (a.claimed && !b.claimed) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    return sorted;
  }, [userWinners]);

  // Fetch user profile, tickets, and competitions
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/users/profile/${wallet_address}`
        );
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    const fetchTickets = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/users/${wallet_address}/entries`
        );
        // console.log("Fetched tickets:", response.data);
        setTickets(response.data);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    const fetchUserCompetitions = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/users/${wallet_address}/competitions`
        );
        let competitions = response.data;

        // Current time for comparison
        const now = new Date().getTime();

        // Sort competitions based on the defined criteria
        competitions.sort((a, b) => {
          // 1. Determine if competitions can be claimed
          const aCanClaim =
            a.status === "completed" && parseFloat(a.creator_pending_fee) > 0;
          const bCanClaim =
            b.status === "completed" && parseFloat(b.creator_pending_fee) > 0;

          if (aCanClaim && !bCanClaim) return -1;
          if (!aCanClaim && bCanClaim) return 1;

          // 2. Determine if competitions are ongoing
          const aEndTime = new Date(a.end_time).getTime();
          const bEndTime = new Date(b.end_time).getTime();

          const aIsOngoing = aEndTime > now;
          const bIsOngoing = bEndTime > now;

          if (aIsOngoing && bIsOngoing) {
            // Both ongoing: sort by ascending remaining time
            return aEndTime - bEndTime;
          }

          if (aIsOngoing && !bIsOngoing) {
            // a ongoing, b ended: a comes before b
            return -1;
          }

          if (!aIsOngoing && bIsOngoing) {
            // b ongoing, a ended: b comes before a
            return 1;
          }

          // 3. Both ended and cannot be claimed: sort by descending creation time
          const aCreatedAt = new Date(a.createdAt || a.created_at).getTime();
          const bCreatedAt = new Date(b.createdAt || b.created_at).getTime();

          return bCreatedAt - aCreatedAt;
        });

        setUserCompetitions(competitions);
      } catch (error) {
        console.error("Error fetching competitions:", error);
      }
    };

    fetchProfile();
    fetchTickets();
    fetchUserCompetitions();
  }, [wallet_address]);

  useEffect(() => {
    const fetchTicketCompetitions = async () => {
      if (tickets.length === 0) {
        setTicketCompetitions([]);
        return;
      }

      try {
        const competitionIds = tickets.map((ticket) => ticket.competition_id);
        const uniqueCompetitionIds = [...new Set(competitionIds)];

        const responses = await Promise.all(
          uniqueCompetitionIds.map((id) =>
            axios
              .get(`${API_BASE_URL}/competitions/${id}`)
              .then((response) => response.data)
              .catch((error) => {
                console.error(
                  `Error fetching competition with id ${id}:`,
                  error
                );
                return null;
              })
          )
        );

        const validCompetitions = responses.filter((comp) => comp != null);

        setTicketCompetitions(validCompetitions);
      } catch (error) {
        console.error("Error fetching competitions:", error);
      }
    };

    fetchTicketCompetitions();
  }, [tickets]);

  useEffect(() => {
    const updateRemainingTimes = () => {
      setTickets((currentTickets) =>
        currentTickets.map((ticket) => {
          const competition = ticketCompetitions.find(
            (comp) => comp.id === ticket.competition_id
          );
          const remainingTime = competition
            ? calculateRemainingTime(competition.end_time)
            : null;
          return { ...ticket, remainingTime };
        })
      );
    };

    const interval = setInterval(updateRemainingTimes, 1000);

    return () => clearInterval(interval);
  }, [ticketCompetitions]);

  const handleChangeTab = (value) => {
    setActiveTab(value);
  };

  const handleChangeTicketTab = (value) => {
    setActiveTicketTab(value);
  };

  const handleCompetitionClick = (id) => {
    navigate(`/competition/${id}`);
  };

  // Handle competition updates after a fee is claimed
  const handleFeeClaimed = (updatedCompetition) => {
    setUserCompetitions((prevCompetitions) =>
      prevCompetitions.map((comp) =>
        comp.id === updatedCompetition.id ? updatedCompetition : comp
      )
    );
  };

  // Function to update the profile state
  const updateProfile = (newData) => {
    setProfile((prevProfile) => ({ ...prevProfile, ...newData }));
  };

  // Filter tickets based on activeTicketTab
  let filteredTickets = tickets.filter((ticket) => {
    const competition = ticketCompetitions.find(
      (comp) => comp.id === ticket.competition_id
    );

    if (!competition) return false; // Ensure competition exists

    const minimumPool = competition.minimum_pool;
    const currentAmount = formatPrizeAmount(competition.prize_pool || 0);
    const progressPercentage = Math.min(
      ((currentAmount / minimumPool) * 100).toFixed(1),
      100
    );

    if (activeTicketTab === 0)
      return new Date(competition.end_time) >= new Date(); // "New"
    if (activeTicketTab === 1)
      return new Date(competition.end_time) < new Date(); // "Finished"
    if (activeTicketTab === 2) return false; // "Winners"
    if (activeTicketTab === 3)
      return (
        new Date(competition.end_time) >= new Date() &&
        progressPercentage >= 100
      ); // "Live"
    if (activeTicketTab === 4)
      return (
        new Date(competition.end_time) < new Date() && progressPercentage < 100
      ); // "Refunds"

    return false;
  });

  if (activeTicketTab === 3) {
    filteredTickets = filteredTickets.sort((a, b) => {
      const competitionA = ticketCompetitions.find(
        (comp) => comp.id === a.competition_id
      );
      const competitionB = ticketCompetitions.find(
        (comp) => comp.id === b.competition_id
      );
      return new Date(competitionA.end_time) - new Date(competitionB.end_time);
    });
  }

  if (!profile) {
    return;
  }

  // Render individual ticket cards
  const renderTicketCard = (ticket, competition) => {
    const timeString = ticket.remainingTime
      ? formatTime(ticket.remainingTime)
      : "00000000";
    const tokenBuyPercentage = competition.token_buy_percentage;
    const poolPercentage = competition.pool_percentage;
    const protocolFeePercentage = competition.total_fees_percentage;
    const progressPercentage = Math.min(
      (
        (formatPrizeAmount(competition.prize_pool || 0) /
          competition.minimum_pool) *
        100
      ).toFixed(1),
      100
    );
    // Compute title using header if exists
    // Determine if the competition is live
    const isLive = progressPercentage === 100 && timeString !== "00000000";

    // Build the base title from the header (if provided) or default values.
    const computedTitleBase =
      competition.header && competition.header.trim() !== ""
        ? `${competition.header.trim().replace(/\s+/g, "_")}_${
            competition.token_symbol
          }_${tokenBuyPercentage}_${poolPercentage}`
        : `${competition.token_symbol}_${tokenBuyPercentage}_${poolPercentage}_${protocolFeePercentage}`;

    // Append "_LIVE" before the .exe if the competition is live.
    const computedTitle = isLive
      ? `${computedTitleBase}_LIVE.exe`
      : `${computedTitleBase}.exe`;

    return (
      <TicketCard key={ticket.id}>
        <WindowHeaderStyled>
          <Marquee pauseOnHover gradient={false} speed={50}>
            {isLive ? (
              <RainbowTextHeader colors={randomColors}>
                {computedTitle}
              </RainbowTextHeader>
            ) : (
              <span>{computedTitle}</span>
            )}
          </Marquee>
          <Counter value={timeString} size="sm" />
        </WindowHeaderStyled>

        <TokenNameWrapper>
          <p>{competition.token_name}</p>
        </TokenNameWrapper>
        <WindowContentStyled2>
          <TokenAvatar>
            <img src={competition.token_icon} alt={competition.token_symbol} />
          </TokenAvatar>
          <ContractAddress>
            {competition.token && truncateAddress(competition.token)}
            <CopyToClipboard
              text={competition.token || "No Address"}
              onCopy={() =>
                toast(
                  (toastProps) => (
                    <CustomReact95Toast
                      title="Clipboard"
                      message="Copied to clipboard!"
                      closeToast={toastProps.closeToast}
                    />
                  ),
                  {
                    position: "top-right",
                    autoClose: 5000,
                    closeButton: false,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                  }
                )
              }
            >
              <FaCopy className="copy-icon" />
            </CopyToClipboard>
          </ContractAddress>
          {activeTicketTab === 2 && renderWinnersTab()}
          <InfoGroup label="Jackpot">
            <p>{formatPrizeAmount(competition.prize_pool || 0)} SOL</p>
          </InfoGroup>
          <InfoGroup label="Tickets">
            <Marquee pauseOnHover gradient={false} speed={50}>
              {[...ticket.ticket_numbers, ""].join(" _ ")}
            </Marquee>
          </InfoGroup>
        </WindowContentStyled2>
        <ProgressWrapper>
          <ProgressBar
            value={progressPercentage}
            hideValue={progressPercentage === 100 || timeString === "00000000"}
          />
          {renderProgressStatus(timeString, progressPercentage)}
        </ProgressWrapper>
      </TicketCard>
    );
  };

  // Render winners card
  const renderWinningsCard = (winner, competition) => {
    if (!competition) return null;
    const tokenBuyPercentage = competition.token_buy_percentage;
    const poolPercentage = competition.pool_percentage;
    const protocolFeePercentage = competition.total_fees_percentage;
    // Compute title for winner card using header if exists
    const computedWinnerTitle =
      competition.header && competition.header.trim() !== ""
        ? `${competition.header.trim().replace(/\s+/g, "_")}_${
            competition.token_symbol
          }_${tokenBuyPercentage}_${poolPercentage}_WINNER.exe`
        : `${competition.token_symbol}_${tokenBuyPercentage}_${poolPercentage}_${protocolFeePercentage}_WINNER.exe`;

    return (
      <TicketCard key={winner.id}>
        <WindowHeaderStyled>
          <Marquee pauseOnHover gradient={false} speed={50}>
            <RainbowTextHeader colors={randomColors}>
              {computedWinnerTitle}
            </RainbowTextHeader>
          </Marquee>
        </WindowHeaderStyled>

        <TokenNameWrapper>
          <p>{competition.token_name}</p>
        </TokenNameWrapper>

        <WindowContentStyled2>
          <TokenAvatar>
            <img src={competition.token_icon} alt={competition.token_symbol} />
          </TokenAvatar>

          <ContractAddress>
            {competition.token && truncateAddress(competition.token)}
            <CopyToClipboard
              text={competition.token || "No Address"}
              onCopy={() =>
                toast(
                  (toastProps) => (
                    <CustomReact95Toast
                      title="Clipboard"
                      message="Copied to clipboard!"
                      closeToast={toastProps.closeToast}
                    />
                  ),
                  {
                    position: "top-right",
                    autoClose: 5000,
                    closeButton: false,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                  }
                )
              }
            >
              <FaCopy className="copy-icon" />
            </CopyToClipboard>
          </ContractAddress>

          <InfoGroup label="Jackpot">
            <p>{formatPrizeAmount(winner.prize_amount)} SOL</p>
          </InfoGroup>

          {/* If you want to show the winning ticket number */}
          <InfoGroup label="Winning Ticket">
            <p>{winner.winning_ticket}</p>
          </InfoGroup>
        </WindowContentStyled2>

        <div
          style={{ display: "flex", justifyContent: "center", padding: "1rem" }}
        >
          <Button
            style={{ width: "100%" }}
            disabled={isProcessingPrize || winner.claimed}
            onClick={() => handleClaimPrize(winner)}
          >
            {isProcessingPrize
              ? "Processing..."
              : winner.claimed
              ? "Claimed"
              : "Claim"}
          </Button>
        </div>
      </TicketCard>
    );
  };

  // Render winners tab content
  const renderWinnersTab = () => (
    <NestedTabBody>
      {unclaimedWinners.length > 0 ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {unclaimedWinners.map((winner) => (
            <div
              key={winner.id}
              style={{
                border: "1px solid #ccc",
                padding: "1rem",
                borderRadius: "4px",
              }}
            >
              <p>Competition ID: {winner.competition_id}</p>
              <p>Winning Ticket: {winner.winning_ticket}</p>
              <p>Prize Amount: {winner.prize_amount} SOL</p>
              <Button onClick={() => handleClaimPrize(winner)}>Claim</Button>
            </div>
          ))}
        </div>
      ) : (
        <p>No unclaimed winners found.</p>
      )}
    </NestedTabBody>
  );

  // Render progress status for tickets
  const renderProgressStatus = (timeString, progressPercentage) => {
    if (timeString === "00000000") {
      return progressPercentage < 100 ? (
        <RainbowText colors={randomColors}>FAILED</RainbowText>
      ) : (
        <RainbowText colors={randomColors}>FINISHED</RainbowText>
      );
    } else if (progressPercentage === 100) {
      return <RainbowText colors={randomColors}>LIVE!</RainbowText>;
    }
    return null;
  };

  return (
    <ProfileWrapper>
      <ProfileWindow>
        <WindowHeaderStyled>
          <span>ProfilePage.exe</span>
          <Button
            onClick={() => navigate(-1)}
            style={{ marginLeft: "auto", padding: "0 10px" }}
          >
            X
          </Button>
        </WindowHeaderStyled>

        {/* Top-level tabs: Profile, Tickets, Competitions, Winnings */}
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab value={0}>Profile</Tab>
          <Tab value={1}>Tickets</Tab>
          <Tab value={2}>Competitions</Tab>
          <Tab value={3}>Winnings</Tab>
        </Tabs>

        <TabBody>
          {/* TAB 0: Profile */}
          {activeTab === 0 && (
            <ProfileAvatarSelector
              walletAddress={wallet_address}
              initialAvatar={profile.avatar_url}
              initialTheme={profile.theme}
              updateProfile={(newData) =>
                setProfile((prev) => ({ ...prev, ...newData }))
              }
            />
          )}

          {/* TAB 1: Tickets */}
          {activeTab === 1 && (
            <WindowContentStyled>
              <Tabs value={activeTicketTab} onChange={handleChangeTicketTab}>
                <Tab value={0}>New</Tab>
                <Tab value={3}>Live</Tab>
                <Tab value={1}>Finished</Tab>
              </Tabs>

              <NestedTabBody>
                {filteredTickets.length > 0 ? (
                  <TicketsRow>
                    {filteredTickets.map((ticket) => {
                      const competition = ticketCompetitions.find(
                        (comp) => comp.id === ticket.competition_id
                      );
                      if (!competition) return null;
                      return renderTicketCard(ticket, competition);
                    })}
                  </TicketsRow>
                ) : (
                  <p>No tickets found for this category.</p>
                )}
              </NestedTabBody>
            </WindowContentStyled>
          )}

          {/* TAB 2: Competitions */}
          {activeTab === 2 && (
            <WindowContentStyled>
              <CompetitionList>
                {userCompetitions.length > 0 ? (
                  userCompetitions.map((competition) => (
                    <div
                      key={competition.id}
                      onClick={() => handleCompetitionClick(competition.id)}
                      style={{ position: "relative", width: "100%" }}
                    >
                      <CompetitionCreator
                        competition={competition}
                        updateCompetitions={(updated) => {
                          // update local competitions if a fee is claimed, etc.
                          setUserCompetitions((prev) =>
                            prev.map((comp) =>
                              comp.id === updated.id ? updated : comp
                            )
                          );
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <p>No competitions found.</p>
                )}
              </CompetitionList>
            </WindowContentStyled>
          )}

          {/* TAB 3: Winnings */}
          {activeTab === 3 && (
            <WindowContentStyled>
              {sortedWinners.length === 0 ? (
                <p>You have no winners yet.</p>
              ) : (
                <TicketsRow>
                  {sortedWinners.map((winner) => {
                    // find competition data to display
                    const comp = ticketCompetitions.find(
                      (c) => c.id === winner.competition_id
                    );
                    return renderWinningsCard(winner, comp);
                  })}
                </TicketsRow>
              )}
            </WindowContentStyled>
          )}
        </TabBody>
      </ProfileWindow>
    </ProfileWrapper>
  );
};

export default ProfilePage;
