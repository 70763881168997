import React, { useState, useEffect, useRef, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  MenuList,
  MenuListItem,
  Separator,
} from "react95";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import axios from "axios";
import "@solana/wallet-adapter-react-ui/styles.css";
import { ThemeContext } from "../context/ThemeContext";
import logo from "../assets/svgs/logo.svg";
import eye from "../assets/svgs/eye.png";
import assure from "../assets/svgs/assure.png";
import { motion } from "framer-motion";
import { AdminContext } from "../context/AdminContext";

// ----------------- Styles -----------------
const StyledAppBar = styled(AppBar)`
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 5000;
`;

const StyledToolbar = styled(Toolbar)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 1920px;
  margin: 0 auto;
`;

const WalletButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledWalletButton = styled(Button)`
  width: 125px;
  text-align: center;
  white-space: nowrap;
`;

const DropdownMenu = styled(MenuList)`
  position: absolute;
  right: 0;
  top: 100%;
  width: 150px;
  z-index: 1000;
`;

const CreatePool = styled(Button)`
  width: 125px;
`;

/**
 * Keyframe for a simple float/bounce.
 * Note that we ONLY do `translateY` here – no x or % shifting
 * because that would conflict with your parent’s transforms.
 */
const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
`;

/**
 * EyeParent
 *  - Holds the "desktop vs. mobile" transform offsets
 *  - On desktop, puts the eye at (top: 250%, left: 85%, translate(-50%, -30%))
 *  - On mobile, centers it at top: 50%, left: 50%, translate(-50%, -50%)
 */
const EyeParent = styled.div`
  position: absolute;
  top: 250%;
  left: 85%;
  /* The "original" desktop transform: */
  transform: translate(-50%, -30%);

  @media (max-width: 768px) {
    /* On mobile, move it to the true center (or wherever you like) */
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

/**
 * EyeFloating
 *  - Handles the float animation + the hover/tap scale
 *  - Because it’s a *child*, it composes its transform with EyeParent’s transform
 */
const EyeFloating = styled(motion.div)`
  animation: ${floatAnimation} 3s ease-in-out infinite;
  cursor: pointer;
  z-index: 99;
`;

const StyledImage = styled.img`
  width: 100px;

  @media (max-width: 1024px) {
    width: 70px;
  }

  @media (min-width: 1920px) {
    width: 100px;
  }
`;

const StyledImage2 = styled.img`
  width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  cursor: pointer;

  /* Hidden on small screens */
  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1920px) {
    width: 150px;
    transform: translate(-50%, -50%);
  }
`;

const AssureImage = styled(motion.img)`
  width: 50px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 9999;

  @media (max-width: 1024px) {
    width: 40px;
    bottom: 10px;
    right: 10px;
  }
`;

// ----------------- Component -----------------
const AppBarComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { setVisible } = useWalletModal();
  const { publicKey, disconnect } = useWallet();
  const { changeTheme } = useContext(ThemeContext);
  const { isAdmin } = useContext(AdminContext);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [imageSize, setImageSize] = useState("100px");

  // Use environment variable for API base URL
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest(".wallet-button")
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Create or fetch user on wallet connect, set theme from user
  useEffect(() => {
    const createOrFetchUserProfile = async (walletAddress) => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/users`, {
          wallet_address: walletAddress,
        });
        const userData = response.data;

        if (userData && userData.theme) {
          changeTheme(userData.theme);
        } else {
          changeTheme("original");
        }
      } catch (error) {
        console.error("Error creating/fetching user profile:", error);
      }
    };

    if (publicKey) {
      createOrFetchUserProfile(publicKey.toBase58());
    } else {
      changeTheme("original");
    }
  }, [publicKey, API_BASE_URL, changeTheme]);

  // Adjust image size based on screen width
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      const newSize = Math.max(70, window.innerWidth / 15) + "px";
      setImageSize(newSize);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle wallet interactions
  const handleConnectWallet = () => {
    setVisible(true);
  };

  const handleDisconnectWallet = () => {
    disconnect().then(() => {
      changeTheme("original");
      setMenuOpen(false);
      navigate("/");
    });
  };

  const truncatedAddress = publicKey
    ? `${publicKey.toBase58().slice(0, 4)}...${publicKey.toBase58().slice(-4)}`
    : "";

  // Handlers for navigation
  const handleCreatePool = () => {
    navigate("/create-competition");
  };

  const handleAccountClick = () => {
    navigate(`/profile/${publicKey?.toBase58()}`);
    setMenuOpen(false);
  };

  const handleAdminDashboardClick = () => {
    navigate("/admin-dashboard");
    setMenuOpen(false);
  };

  const handleHomeClick = () => {
    navigate(`/`);
    setMenuOpen(false);
  };

  const handleAssureClick = () => {
    window.open(
      "https://projects.assuredefi.com/project/blind-faith",
      "_blank"
    );
    setMenuOpen(false);
  };

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          {publicKey ? (
            <CreatePool type="button" onClick={handleCreatePool}>
              Create Pool
            </CreatePool>
          ) : (
            <CreatePool type="button" onClick={() => navigate("/faq")}>
              FAQ
            </CreatePool>
          )}

          {/* 
            EyeParent handles desktop transform: translate(-50%, -30%)
            On mobile, it uses translate(-50%, -50%)
          */}
          <EyeParent>
            <motion.div
              onClick={handleHomeClick}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
              transition={{ type: "spring", stiffness: 500, damping: 20 }}
            >
              <EyeFloating>
                <StyledImage src={eye} alt="Eye" style={{ width: imageSize }} />
              </EyeFloating>
            </motion.div>
          </EyeParent>

          <motion.div
            onClick={handleHomeClick}
            whileHover={{ scale: 1.2, rotateY: 360 }}
            whileTap={{ scale: 0.8 }}
            transition={{ type: "spring", stiffness: 100, damping: 20 }}
          >
            <StyledImage2 src={logo} alt="Logo" />
          </motion.div>

          <WalletButtonWrapper>
            <StyledWalletButton
              onClick={() =>
                publicKey ? setMenuOpen(!menuOpen) : handleConnectWallet()
              }
              className="wallet-button"
            >
              {publicKey ? truncatedAddress : "Connect"}
            </StyledWalletButton>

            {menuOpen && publicKey && (
              <DropdownMenu ref={menuRef}>
                <MenuListItem onClick={handleHomeClick}>🏠 Home</MenuListItem>
                <Separator />
                <MenuListItem onClick={handleAccountClick}>
                  👨‍💻 Account
                </MenuListItem>
                <Separator />
                <MenuListItem
                  onClick={() => {
                    navigate("/faq");
                    setMenuOpen(false);
                  }}
                >
                  ❓ FAQ
                </MenuListItem>

                {isAdmin && (
                  <>
                    <Separator />
                    <MenuListItem onClick={handleAdminDashboardClick}>
                      🛠️ Admin
                    </MenuListItem>
                  </>
                )}

                <Separator />
                <MenuListItem onClick={handleDisconnectWallet}>
                  🔙 Disconnect
                </MenuListItem>
              </DropdownMenu>
            )}
          </WalletButtonWrapper>
        </StyledToolbar>
      </StyledAppBar>

      {/* Assure image fixed at the bottom right */}
      <AssureImage
        src={assure}
        alt="Assure"
        onClick={handleAssureClick}
        whileHover={{ scale: 2 }}
        whileTap={{ scale: 0.8 }}
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
      />
    </>
  );
};

export default AppBarComponent;
