import { createGlobalStyle } from "styled-components";
import { styleReset } from "react95";

const GlobalStyles = createGlobalStyle`
  ${styleReset}


 html, body {
     font-family: 'ms_sans_serif';
  margin: 0;
  padding: 0;
  height: 100%;
  background: ${({ theme }) => theme.desktopBackground};
  /* No overflow: hidden here */
  }

  /* If you want to hide the scrollbar but still allow scrolling */
  body {
    overflow-y: auto; 
    
    -ms-overflow-style: none !important; /* hides scrollbar in IE/Edge */
  }
  body::-webkit-scrollbar {
    display: none !important; /* hides scrollbar in Chrome/Safari/Opera */
      width: 0 !important;
  }
    

  #root {
  min-height: 100vh;
  width: 100%;
  
  /* If #root is the one that scrolls: */
  overflow-y: auto;           /* enable scrolling here */
  scrollbar-width: none !important;     /* hides scrollbar in Firefox */
  -ms-overflow-style: none !important;   /* hides scrollbar in IE/Edge */
  }
  #root::-webkit-scrollbar {
    display: none !important; /* hides scrollbar in Chrome/Safari/Opera */
  }


  .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    text-align: center;
    background: none;
  }

  .carousel img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }


    /* Remove the standard background, box shadow, margins, etc. */
  .Toastify__toast {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
  }

  .Toastify__toast-body {
    margin: 0 !important;
    padding: 0 !important;
  }

 

  .custom-table {
    display: block;
    width: 100%;
    max-height: 600px;  // Adjust based on your UI requirements
    overflow-y: auto;   // Enables scrolling on the y-axis
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer 10+ */

    /* For Webkit browsers like Chrome, Safari, and Opera */
    &::-webkit-scrollbar { 
      display: none;
    }
  }

.shape {
  position: absolute;
  transition: transform 0.1s linear; /* Smooth transitions */
  will-change: transform; /* Optimization hint */
}



`;

export default GlobalStyles;
