import React, { useEffect } from "react";
import Matter from "matter-js";

import shape1 from "../assets/svgs/shape1.svg";
import shape2 from "../assets/svgs/shape2.svg";
import shape3 from "../assets/svgs/shape3.svg";
import shape4 from "../assets/svgs/shape4.svg";
import shape5 from "../assets/svgs/shape5.svg";

const FloatingShapes = () => {
  useEffect(() => {
    // Create Matter.js engine and world
    const engine = Matter.Engine.create();
    const world = engine.world;

    // Tiny negative gravity so shapes float upward
    engine.world.gravity.y = -0.005;

    // Setup a container for shapes
    const shapesContainer = document.createElement("div");
    shapesContainer.style.position = "fixed"; // <-- changed to fixed
    shapesContainer.style.top = "0";
    shapesContainer.style.left = "0";
    shapesContainer.style.width = "100vw";
    shapesContainer.style.height = "100vh";
    shapesContainer.style.zIndex = "0";
    shapesContainer.style.overflow = "hidden";
    document.body.appendChild(shapesContainer);

    const svgArray = [shape1, shape2, shape3, shape4, shape5];
    const floatingShapes = [];

    const createShape = (startX, startY) => {
      const size = Math.random() * 100 + 50;
      const mass = Math.random() * 7.5 + 0.5;
      const randomSvg = svgArray[Math.floor(Math.random() * svgArray.length)];

      // Create an img element for the SVG
      const shape = document.createElement("img");
      shape.src = randomSvg;
      shape.style.width = `${size}px`;
      shape.style.height = `${size}px`;
      shape.style.position = "absolute";

      // Random rotation + color
      const initialRotation = Math.random() * 360;
      const rotationSpeed = (Math.random() - 0.5) * 0.5;
      shape.style.transform = `translate(${startX}px, ${startY}px) rotate(${initialRotation}deg)`;
      const randomHue = Math.random() * 360;
      shape.style.filter = `sepia(1) saturate(10) brightness(0.6) hue-rotate(${randomHue}deg)`;

      shapesContainer.appendChild(shape);

      // Air resistance based on mass
      const frictionAir = mass * 0.001;
      const rectWidth = size * 0.9;
      const rectHeight = size * 0.9;

      // Matter body
      const body = Matter.Bodies.rectangle(
        startX || Math.random() * window.innerWidth,
        startY || window.innerHeight + size,
        rectWidth,
        rectHeight,
        {
          restitution: 1.5,
          friction: 0,
          frictionAir: frictionAir,
          mass: mass,
        }
      );

      // Random velocity
      const randomVelocityX = (Math.random() - 0.5) * 0.5;
      const randomVelocityY = (Math.random() - 0.5) * 0.5;
      Matter.Body.setVelocity(body, {
        x: randomVelocityX,
        y: randomVelocityY - 0.1,
      });

      floatingShapes.push({ element: shape, body, rotationSpeed });
      Matter.World.add(world, body);

      // Remove shape if it drifts off top
      Matter.Events.on(engine, "beforeUpdate", () => {
        if (body.position.y < -size) {
          Matter.World.remove(world, body);
          if (shapesContainer.contains(shape)) {
            shapesContainer.removeChild(shape);
          }
        }
      });
    };

    const createInitialShapes = () => {
      for (let i = 0; i < 20; i++) {
        createShape(
          Math.random() * window.innerWidth,
          Math.random() * window.innerHeight
        );
      }
    };

    // Continuously spawn shapes from the bottom
    const createNewShapesFromBottom = () => {
      const shapeInterval = setInterval(() => {
        createShape(
          Math.random() * window.innerWidth,
          window.innerHeight + 300
        );
      }, 2000);
      return shapeInterval;
    };

    createInitialShapes();
    const shapeInterval = createNewShapesFromBottom();

    // Animation loop
    const updateShapes = () => {
      Matter.Engine.update(engine);
      floatingShapes.forEach(({ element, body, rotationSpeed }) => {
        // Current rotation from CSS transform
        const currentRotation =
          parseFloat(element.style.transform.split("rotate(")[1]) || 0;
        element.style.transform = `translate(${body.position.x}px, ${
          body.position.y
        }px) rotate(${currentRotation + rotationSpeed}deg)`;
      });
      requestAnimationFrame(updateShapes);
    };
    updateShapes();

    // Cleanup
    return () => {
      clearInterval(shapeInterval);
      if (document.body.contains(shapesContainer)) {
        document.body.removeChild(shapesContainer);
      }
      Matter.World.clear(world);
      Matter.Engine.clear(engine);
    };
  }, []);

  return null; // No visible UI from this component
};

export default FloatingShapes;
