import React, { useState, useContext, useEffect, useRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import SortButtons from "../components/SortButtons";
import axios from "axios";
import styled from "styled-components";
import CompetitionItem from "../components/Competition";
import { motion } from "framer-motion";
import { Checkbox } from "react95";
import GlobalJackpotDisplay from "../components/GlobalJackpotDisplay";
import { NotificationContext } from "../context/NotificationContext";
import BfSticker5 from "../assets/gif/bfsticker5.gif";

// 1) Import the InfiniteScroll component
import InfiniteScroll from "react-infinite-scroll-component";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// ----------------------------------
// Styled Components
// ----------------------------------

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
  height: 100%;
  overflow-y: auto;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 1920px;
  min-width: 320px;
  gap: 20px;

  @media (min-width: 768px) {
    width: 80%;
  }
  @media (min-width: 1024px) {
    width: 70%;
  }
  @media (min-width: 1280px) {
    width: 60%;
  }
`;

const CompetitionList = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 1000px;
  }

  @media (min-width: 1360px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1360px;
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(4, 1fr);
    max-width: 1920px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  font-weight: normal;
  height: 20px;
  margin: 0;
  padding: 0;
  color: white;
  & > span {
    font-weight: normal;
    line-height: 1;
  }
  & > input {
    margin: 0;
  }
`;

// ----------------------------------
// TiltShineCard & CompetitionCard
// ----------------------------------

const PERSPECTIVE = "1500px";
// The tilt shift effect has been removed, so ROTATION_RANGE and related constants are no longer used.
const TiltShineCard = ({ children }) => {
  return (
    <div style={{ perspective: PERSPECTIVE }} className="relative">
      <motion.div
        // No tilt event handlers or hover effects applied
        style={{
          backgroundSize: "cover",
        }}
        className="relative aspect-[9/13] w-80 overflow-hidden bg-zinc-950 shadow-2xl shadow-zinc-950"
      >
        {children}
      </motion.div>
    </div>
  );
};

const CompetitionCardWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  transform-origin: center;
  transform: translateZ(0);
`;

const generateRandomColor = () => {
  const randomColorValue = () => Math.floor(Math.random() * 256);
  return `rgba(${randomColorValue()}, ${randomColorValue()}, ${randomColorValue()}, 0.9)`;
};

const CompetitionCard = memo(({ competition, onClick, glow }) => {
  const colorsRef = useRef({
    color1: generateRandomColor(),
    color2: generateRandomColor(),
    color3: generateRandomColor(),
    color4: generateRandomColor(),
  });
  const colors = colorsRef.current;

  const glowVariants = {
    glowing: {
      filter: [
        `drop-shadow(0 0 10px ${colors.color1})`,
        `drop-shadow(0 0 15px ${colors.color2})`,
        `drop-shadow(0 0 10px ${colors.color1})`,
      ],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
    initial: {
      filter: "none",
    },
  };

  return (
    <CompetitionCardWrapper
      onClick={onClick}
      variants={glowVariants}
      initial="initial"
      animate={glow ? "glowing" : "initial"}
    >
      <CompetitionItem competition={competition} />
    </CompetitionCardWrapper>
  );
});

// ----------------------------------
// HomePage Component
// ----------------------------------

const HomePage = () => {
  const [competitions, setCompetitions] = useState([]);
  const [globalJackpot, setGlobalJackpot] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    type: "hot",
    direction: "desc",
  });
  const [showFinishedCompetitions, setShowFinishedCompetitions] =
    useState(false);

  // Pagination / loading states
  const [page, setPage] = useState(1);
  const [limit] = useState(12);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { socketRef, liveUpdatesEnabled, setLiveUpdatesEnabled } =
    useContext(NotificationContext);
  const navigate = useNavigate();

  // ----------------------------------
  // Fetching Data
  // ----------------------------------

  // Reusable function to fetch competitions
  const fetchCompetitionsFromServer = async (pageParam, limitParam) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/competitions/query`, {
        params: {
          page: pageParam,
          limit: limitParam,
          showFinished: showFinishedCompetitions,
          searchTerm: searchTerm,
          sort: sortConfig.type,
          direction: sortConfig.direction,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching competitions:", error);
      return [];
    }
  };

  // Fetch the global jackpot
  const fetchGlobalJackpot = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/global-jackpot`);
      setGlobalJackpot(response.data.totalJackpot);
    } catch (error) {
      console.error("Error fetching global jackpot:", error);
    }
  };

  useEffect(() => {
    fetchGlobalJackpot();
  }, []);

  // Load initial data or refresh when filters change
  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      setPage(1);
      setHasMore(true);

      const newCompetitions = await fetchCompetitionsFromServer(1, limit);
      setCompetitions(newCompetitions);
      console.log("Competitions Length:", newCompetitions.length);
      if (newCompetitions.length < limit) {
        setHasMore(false);
      }
      setIsLoading(false);
    };

    loadInitialData();
  }, [searchTerm, sortConfig, showFinishedCompetitions]);

  // Infinite Scroll "next" function
  const fetchMoreData = async () => {
    setIsLoading(true);
    const nextPage = page + 1;

    const newCompetitions = await fetchCompetitionsFromServer(nextPage, limit);
    setCompetitions((prev) => [...prev, ...newCompetitions]);

    if (newCompetitions.length < limit) {
      setHasMore(false);
    }

    setPage(nextPage);
    setIsLoading(false);
  };

  // Socket Logic (optional)
  useEffect(() => {
    if (!socketRef.current) return;

    const handleNewCompetition = () => {
      console.log("New competition detected.");
    };

    const handleCompetitionUpdated = (updatedCompetition) => {
      console.log("Competition update detected:", updatedCompetition);
      setCompetitions((prev) =>
        prev.map((comp) =>
          comp.id === updatedCompetition.id ? updatedCompetition : comp
        )
      );
    };

    const handleJackpotUpdated = (data) => {
      console.log("Global jackpot updated:", data.totalJackpot);
      setGlobalJackpot(data.totalJackpot);
    };

    const handleSocketConnected = () => {
      console.log("WebSocket connected:", socketRef.current.id);
    };

    const handleSocketDisconnected = () => {
      console.log("WebSocket disconnected");
    };

    socketRef.current.on("newCompetition", handleNewCompetition);
    socketRef.current.on("competitionUpdated", handleCompetitionUpdated);
    socketRef.current.on("globalJackpotUpdate", handleJackpotUpdated);
    socketRef.current.on("connect", handleSocketConnected);
    socketRef.current.on("disconnect", handleSocketDisconnected);

    return () => {
      socketRef.current.off("newCompetition", handleNewCompetition);
      socketRef.current.off("competitionUpdated", handleCompetitionUpdated);
      socketRef.current.off("globalJackpotUpdate", handleJackpotUpdated);
      socketRef.current.off("connect", handleSocketConnected);
      socketRef.current.off("disconnect", handleSocketDisconnected);
    };
  }, [socketRef]);

  // Handlers
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSort = (type, direction) => {
    setSortConfig({ type, direction });
  };

  const handleCompetitionClick = (id) => {
    navigate(`/competition/${id}`);
  };

  const handleJackpotClick = () => {
    navigate("/global-jackpot");
  };

  const getProgressPercentage = (competition) => {
    const minimumPool = competition.minimum_pool || 1;
    const currentAmount = competition.prize_pool || 0;
    return Math.min(((currentAmount / minimumPool) * 100).toFixed(1), 100);
  };

  // Render
  return (
    <Wrapper>
      <MainContent>
        <GlobalJackpotDisplay
          jackpotAmount={globalJackpot}
          liveUpdatesEnabled={liveUpdatesEnabled}
          onClick={handleJackpotClick}
        />

        <SearchBar onSearch={handleSearch} />
        <SortButtons onSort={handleSort} />

        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <StyledCheckbox
            label="Show Finished?"
            checked={showFinishedCompetitions}
            onChange={() =>
              setShowFinishedCompetitions(!showFinishedCompetitions)
            }
          />
          <StyledCheckbox
            label="Live Updates"
            checked={liveUpdatesEnabled}
            onChange={() => setLiveUpdatesEnabled(!liveUpdatesEnabled)}
          />
        </div>

        <InfiniteScroll
          dataLength={competitions.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div style={{ color: "#fff", margin: "1rem 0" }}>
              Loading more competitions...
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center", color: "#fff" }}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <img
                src={BfSticker5}
                alt="Sleepy"
                style={{ width: "100%", maxWidth: "100px" }}
              />
              <br />
              <b>Hmm... this is the end</b>
            </p>
          }
          style={{ overflow: "visible" }}
        >
          <CompetitionList>
            {competitions.map((competition) => {
              const progressPercentage = getProgressPercentage(competition);
              return (
                <TiltShineCard key={competition.id}>
                  <CompetitionCard
                    competition={competition}
                    onClick={() => handleCompetitionClick(competition.id)}
                    glow={progressPercentage >= 100}
                  />
                </TiltShineCard>
              );
            })}
          </CompetitionList>
        </InfiniteScroll>

        {isLoading && (
          <div style={{ color: "#fff", margin: "1rem 0" }}>
            Fetching competitions...
          </div>
        )}
      </MainContent>
    </Wrapper>
  );
};

export default HomePage;
