import React, { useState } from "react";
import styled from "styled-components";
import {
  Window,
  WindowHeader,
  WindowContent,
  GroupBox,
  Button,
  ScrollView,
  Tabs,
  Tab,
  TabBody,
  Toolbar,
} from "react95";

// Custom responsive button for the toolbar
const ResponsiveButton = styled(Button)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: clamp(0.7rem, 2vw, 0.9rem);
`;

// Ensure the toolbar remains a row and distribute buttons evenly
const ResponsiveToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  & > button {
    flex: 1; /* Each button takes equal space */
    margin: 0 0.25rem; /* Optional: add horizontal spacing */
  }
  @media (max-width: 480px) {
    /* Optionally adjust font size or padding on mobile if needed */
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const PopupWrapper = styled.div`
  margin: 0 auto 2rem auto;
  width: 98%;
  max-width: 600px;

  /* When the viewport is narrower than 600px, scale down the popup */
  @media (max-width: 600px) {
    /* This calculates a scale factor based on the current viewport width */
    transform: scale(calc(100vw / 800));
    transform-origin: top center;
  }
`;

const SectionTitle = styled.h3`
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const SectionContent = styled.div`
  font-size: 0.9rem;
  margin: 0.5rem 0;
  line-height: 1.3;
  text-align: center;
`;

const CenteredWindowContent = styled(WindowContent)`
  text-align: center;
  padding: 2px;
`;

const HeaderContent = styled(WindowHeader)`
  display: flex;
  align-items: center;
`;

const IntroPopup = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedToolbar, setSelectedToolbar] = useState("home");

  const renderMainContent = () => {
    switch (selectedToolbar) {
      case "home":
        return (
          <>
            <p>
              Blind Faith is the most secure, transparent, and simple buying
              competition platform on Solana. Each competition is fully powered
              by verifiable randomness, so there’s zero human tampering.
            </p>
            <br />
            <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
              <Tab value={0}>Participants</Tab>
              <Tab value={1}>Creators</Tab>
            </Tabs>
            <TabBody>
              {activeTab === 0 && (
                <SectionContent>
                  Step 1: Choose a Competition
                  <br />
                  Find a contest that sparks your interest
                  <br />
                  <br />
                  Step 2: Buy Tickets!
                  <br />
                  Part of your purchase buys the token, the rest goes into the
                  prize pool
                  <br />
                  <br />
                  Step 3: Wait for the Timer
                  <br />
                  When time’s up, one lucky ticket-holder wins the entire prize!
                </SectionContent>
              )}
              {activeTab === 1 && (
                <SectionContent>
                  Step 1: Create a Competition
                  <br />
                  Pick any Solana token as the focus of your contest
                  <br />
                  <br />
                  Step 2: Set the Details
                  <br />
                  Decide your ticket price, competition rules, and prize details
                  <br />
                  <br />
                  Step 3: Automated Winner Selection
                  <br />
                  When the timer ends, our verifiable randomness picks a winner
                  automatically
                </SectionContent>
              )}
            </TabBody>
            <br />
            <Button
              onClick={onClose}
              style={{ marginTop: ".5rem", marginBottom: ".25rem" }}
            >
              I have Blind Faith!
            </Button>
            <br />
            <small
              style={{ fontSize: "0.75rem", color: "#888", cursor: "pointer" }}
            >
              Clicking means you agree to the{" "}
              <span
                style={{
                  textDecoration: "underline",
                  color: "#00f",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedToolbar("tos")}
              >
                Terms & Conditions
              </span>{" "}
              & are 18+
            </small>
          </>
        );

      case "privacy":
        return (
          <>
            <SectionContent>
              <GroupBox
                variant="flat"
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",

                  padding: "0.5rem",
                }}
              >
                <h2>Blind Faith Privacy Notice</h2>
                <br />
                <p>
                  <strong>Last Updated: 20 February 2025</strong>
                </p>
                <br />
                <p>
                  This “Privacy Notice” describes how Blind Faith and its
                  affiliated entities (collectively, “Blind Faith,” “our,” “us,”
                  or “we”) handle Personal Data in connection with our
                  website(s) and the Blind Faith platform (collectively, the
                  “Services”). It also explains the rights and choices
                  individuals have regarding their information.
                </p>
                <br />
                <p>
                  Please read this Privacy Notice carefully. If you do not agree
                  with our policies and practices, please do not use the Blind
                  Faith platform. By accessing or using our platform, you
                  acknowledge and agree to this Privacy Notice.
                </p>
                <br />
                <p>
                  We may update this Privacy Notice over time to keep up with
                  evolving laws, regulations, and industry standards, or to
                  reflect changes to our Services. If we make changes that
                  materially affect your privacy rights, we will provide
                  appropriate notice. Your continued use of the Blind Faith
                  platform after we make changes is understood as acceptance of
                  those changes. If you disagree with the changes, you should
                  discontinue use of the platform.
                </p>
                <br />
                <p>
                  Our website(s) and the Blind Faith platform are not directed
                  to individuals under 18. We do not knowingly collect Personal
                  Data from anyone under 18. If a parent or guardian believes
                  that their child has provided Personal Data, please contact us
                  so we can delete such information promptly.
                </p>
                <br />
                <h3>Personal Data Controller</h3>
                <br />
                <p>
                  <strong>Definition of Personal Data:</strong> As used here,
                  “Personal Data” refers to information that can be linked to a
                  specific person and potentially identify them. Data that is
                  aggregated or anonymized so that it no longer identifies an
                  individual is not considered Personal Data.
                </p>
                <br />
                <p>
                  <strong>Role of the Controller:</strong> A Personal Data
                  “controller” is the organization or individual that makes
                  decisions about how to collect, hold, process, or use Personal
                  Data (or that instructs others to do so).
                </p>
                <br />
                <h3>Types of Personal Data Collected</h3>
                <br />
                <p>
                  The Personal Data we collect depends on how you interact with
                  us and our Services. It may include:
                </p>
                <ul>
                  <li>
                    <strong>Financial Information:</strong> Such as
                    cryptocurrency wallet addresses.
                  </li>
                  <li>
                    <strong>Transaction Details:</strong> For instance, wallet
                    addresses of senders and recipients, digital asset
                    activities on the Blind Faith platform, your inquiries to
                    us, and our responses.
                  </li>
                  <li>
                    <strong>Usage Data:</strong> This may include IP address,
                    country of origin, browser and operating system
                    characteristics, and how you use the platform (e.g., pages
                    visited, time spent on each page).
                  </li>
                  <li>
                    <strong>
                      Additional Personal or Commercial Information:
                    </strong>{" "}
                    We may collect further details as needed to comply with
                    anti-money laundering or other applicable regulations.
                  </li>
                </ul>
                <br />
                <p>
                  We also obtain Personal Data from third parties and publicly
                  available sources.
                </p>
                <br />
                <p>Personal Data is typically collected:</p>
                <ul>
                  <li>
                    When you provide it directly on the Blind Faith platform.
                  </li>
                  <li>Through automated means while you use the platform.</li>
                  <li>From third parties or public sources, as noted above.</li>
                </ul>
                <br />
                <p>
                  If certain Personal Data is mandatory and you fail to provide
                  it, you may be unable to use specific features of the Blind
                  Faith platform. Where certain data fields are optional, you
                  are free not to provide that information with no effect on
                  availability or functionality.
                </p>
                <br />
                <h3>Information We Automatically Collect</h3>
                <br />
                <p>
                  When you visit the Blind Faith platform, we may automatically
                  gather details about your device and browsing, such as:
                </p>
                <ul>
                  <li>
                    Web browser, IP address, domain name, device ID (e.g.,
                    IMEI), login data, browser and operating system versions,
                    time zone, cookies installed, and location data.
                  </li>
                  <li>The specific web pages or Services viewed.</li>
                  <li>Referring websites or search terms.</li>
                  <li>
                    How you navigate or interact with our site or platform.
                  </li>
                </ul>
                <br />
                <h3>Cookies &amp; Similar Technologies</h3>
                <br />
                <p>
                  We use cookies and related technologies (“Cookies”) to improve
                  and personalize your experience and to help analyze how our
                  Services are used.
                </p>
                <br />
                <h4>How We Use Cookies</h4>
                <br />
                <ul>
                  <li>
                    Track usage statistics to see how visitors interact with the
                    Blind Faith platform.
                  </li>
                  <li>
                    Deliver updated information and enable account
                    authentication.
                  </li>
                </ul>
                <br />
                <h4>Types of Cookies</h4>
                <br />
                <ul>
                  <li>
                    <strong>Strictly Necessary:</strong> Essential for site
                    operation, including authentication.
                  </li>
                  <li>
                    <strong>Functionality:</strong> Help remember your
                    preferences and settings.
                  </li>
                  <li>
                    <strong>Performance/Analytical:</strong> Collect usage info
                    to improve site performance and usability.
                  </li>
                  <li>
                    <strong>Targeting:</strong> May be used to deliver relevant
                    information (not personally identifiable) to devices that
                    have visited our site.
                  </li>
                </ul>
                <br />
                <h4>Controlling and Deleting Cookies</h4>
                <br />
                <p>
                  You can block or delete Cookies via your browser settings. If
                  you do, some parts of our Services may not function properly.
                  For more details, visit{" "}
                  <a
                    href="http://www.allaboutcookies.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.allaboutcookies.org
                  </a>
                  .
                </p>
                <br />
                <h4>Google Analytics</h4>
                <br />
                <p>
                  We may use Google Analytics to understand how visitors use our
                  site. You can opt out by installing the Google Analytics
                  browser add-on.
                </p>
                <br />
                <h3>Method and Basis of Processing Personal Data</h3>
                <br />
                <h4>Methods of Processing</h4>
                <br />
                <p>
                  We take security measures to protect data from unauthorized
                  access or misuse. Processing is done by authorized staff,
                  contractors, and third-party service providers only as
                  necessary.
                </p>
                <br />
                <h4>Legal Bases for Processing</h4>
                <br />
                <p>
                  <strong>Consent:</strong> You have given permission for
                  specific uses.
                  <br />
                  <strong>Performance of a Contract:</strong> Certain Personal
                  Data is needed to fulfill agreements.
                  <br />
                  <strong>Legal Obligation:</strong> We may process data to
                  comply with laws.
                  <br />
                  <strong>Public Interest or Official Authority:</strong>{" "}
                  Processing may be necessary for tasks in the public interest.
                  <br />
                  <strong>Legitimate Interests:</strong> We may process data to
                  pursue legitimate business interests unless overridden by your
                  data rights.
                </p>
                <br />
                <h4>How We Use Personal Data</h4>
                <br />
                <p>We use your data to:</p>
                <ul>
                  <li>
                    Provide, maintain, and improve the Blind Faith platform.
                  </li>
                  <li>Process transactions and keep you updated.</li>
                  <li>Detect malicious or fraudulent activity.</li>
                  <li>Analyze user behaviors to enhance platform features.</li>
                  <li>Handle beta testing and contact you for feedback.</li>
                  <li>
                    Manage social features, productivity tasks, tag management,
                    and location-based interactions.
                  </li>
                  <li>
                    Comply with legal obligations or enforcement requests.
                  </li>
                  <li>Protect our rights and interests, and those of users.</li>
                </ul>
                <br />
                <h3>How We Share Personal Data</h3>
                <br />
                <p>
                  <strong>With Service Providers:</strong> We share with vendors
                  or contractors who need the data to do work on our behalf.
                  They are authorized to use your data only as necessary to
                  provide services to us, under obligations of confidentiality.
                </p>
                <br />
                <p>
                  <strong>Within Our Organization:</strong> We may share among
                  our affiliated teams for consistent privacy practices.
                </p>
                <br />
                <p>
                  <strong>Business Transactions:</strong> If we undergo a
                  merger, acquisition, bankruptcy, or asset sale, your data may
                  be transferred as part of that transaction.
                </p>
                <br />
                <p>
                  <strong>Compliance and Legal:</strong> We may disclose
                  information if legally required or in connection with a legal
                  process or enforceable governmental request.
                </p>
                <br />
                <h3>Retention of Personal Data</h3>
                <br />
                <p>
                  We keep Personal Data only as long as needed to fulfill the
                  purpose for which it was collected, or as required by law (for
                  example, tax or legal compliance). After that, we securely
                  delete or anonymize it.
                </p>
                <br />
                <p>
                  Please note that not all data can be deleted or de-identified
                  (especially for public blockchain transactions—see below).
                </p>
                <br />
                <h3>Blockchain Transactions</h3>
                <br />
                <p>
                  Your use of digital assets on the Blind Faith platform may
                  involve public, distributed blockchain networks that we do not
                  operate. These may record transactions permanently and
                  publicly. As a result:
                </p>
                <ul>
                  <li>
                    Transaction details can be analyzed or combined with other
                    data to reveal identities.
                  </li>
                  <li>
                    We do not control blockchain-based data nor can we erase,
                    modify, or alter it.
                  </li>
                </ul>
                <br />
                <h3>Privacy Technology Practices</h3>
                <br />
                <h4>Links to Third-Party Sites</h4>
                <br />
                <p>
                  Our platform may link to external sites or include content
                  from them. We are not responsible for their actions or their
                  data practices. Please review their privacy notices if you
                  visit those third-party sites.
                </p>
                <br />
                <h4>Data Security</h4>
                <br />
                <p>
                  We use administrative, technical, and physical safeguards to
                  protect your data. You also have a role in security. Do not
                  share your personal login or other sensitive information with
                  anyone. No transmission over the internet is fully secure; you
                  transmit data at your own risk.
                </p>
                <br />
                <h3>Overseas Disclosures and Your Privacy Rights</h3>
                <br />
                <p>
                  Blind Faith may have operations around the world, which could
                  involve transferring your Personal Data across borders. Where
                  these transfers happen, we rely on recognized legal
                  mechanisms, including data transfer agreements, so that your
                  data is protected to recognized standards.
                </p>
                <br />
                <p>
                  Residents of the EEA and the UK have rights under the GDPR or
                  equivalent laws, including the right to access, correct,
                  erase, restrict, object to processing, and withdraw consent
                  (where processing is based on consent).
                </p>
                <br />
                <p>
                  You can contact us to request any of these rights. If we
                  refuse your request, we will explain why, if legally required
                  to do so.
                </p>
                <br />
                <h3>Complaints or Questions</h3>
                <br />
                <p>
                  If you believe we have not respected your privacy or complied
                  with applicable laws, please contact Support so we can
                  investigate and address your concerns. You may also contact
                  your local data protection authority.
                </p>
                <br />
                <h3>General</h3>
                <br />
                <p>
                  If you want to exercise any data rights or have questions
                  regarding this Privacy Notice, please reach out to Support.
                  Please include enough details for us to locate your
                  information and address your request.
                </p>
              </GroupBox>
            </SectionContent>
            <Button onClick={() => setSelectedToolbar("home")}>Back</Button>
          </>
        );

      case "tos":
        return (
          <>
            <SectionContent>
              <GroupBox
                variant="flat"
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",

                  padding: "0.5rem",
                }}
              >
                <h2>Blind Faith Terms of Use</h2>
                <br />
                <p>
                  <strong>Last Updated: 16 January 2025</strong>
                </p>
                <br />
                <p>
                  These Terms of Use (“Terms”) form a legally binding agreement
                  between you (“you” or “your”) and Blind Faith (“Blind Faith,”
                  “we,” “our,” or “us”). They govern your use of Blind Faith
                  services (“Blind Faith Services”) made available to you via
                  our platform (the “Blind Faith platform”) or otherwise. Blind
                  Faith Services may be provided by Blind Faith or any Blind
                  Faith Affiliate.
                </p>
                <br />
                <p>
                  By accessing the Blind Faith platform and/or using the Blind
                  Faith Services, you agree that you have read, understood, and
                  accept these Terms, along with any additional terms or
                  documents referenced herein. Your continued use of the Blind
                  Faith platform signifies that you are bound by and will comply
                  with these Terms, as updated and amended from time to time.
                </p>
                <br />
                <p>
                  If you do not understand or accept these Terms in their
                  entirety, you should not use the Blind Faith platform.
                </p>
                <br />

                <h3>RISK WARNING</h3>
                <br />
                <p>
                  Digital Assets can be highly volatile, and there is a material
                  risk of economic loss when buying, selling, holding, or
                  investing in Digital Assets. Before using the Blind Faith
                  platform, ensure you fully understand these risks, taking into
                  account your personal circumstances and financial situation.
                </p>
                <br />
                <p>
                  You acknowledge that we are not your broker, intermediary,
                  agent, or advisor and have no fiduciary duty to you. We do not
                  offer investment or consulting advice, and no communications
                  or information from us should be construed as advice. You are
                  solely responsible for determining whether a particular
                  investment, strategy, or transaction is appropriate in light
                  of your own objectives, financial situation, and risk
                  tolerance, including any loss or liability that may result. We
                  do not recommend buying, selling, or holding any particular
                  Digital Asset. You must conduct your own due diligence and
                  consult a financial advisor if needed. We are not responsible
                  for the decisions you make to buy, sell, or hold Digital
                  Assets, including any losses arising from such decisions.
                </p>
                <br />

                <h3>1. Introduction</h3>
                <br />
                <p>
                  <strong>1.1</strong> Blind Faith is a platform designed to
                  assist in the creation and trading of Digital Assets. The
                  Blind Faith group provides users an environment to create,
                  manage, and exchange Digital Assets.
                </p>
                <br />
                <p>
                  <strong>1.2</strong> By using the Blind Faith platform, you
                  enter into a legally binding agreement with us. These Terms
                  govern that usage.
                </p>
                <br />
                <p>
                  <strong>1.3</strong> You must read these Terms, along with any
                  referenced documents, carefully. If you have any questions,
                  contact us before continuing.
                </p>
                <br />
                <p>
                  <strong>1.4</strong> You agree that you will be bound by and
                  comply with any relevant additional terms and conditions that
                  apply to your use of the Blind Faith platform.
                </p>
                <br />

                <h3>2. Eligibility</h3>
                <br />
                <p>
                  <strong>2.1</strong> To be eligible to use the Blind Faith
                  platform, you must:
                </p>
                <ul>
                  <li>
                    (a) be an individual, corporation, legal person, entity, or
                    organization with full power and authority to (i) access and
                    use the Blind Faith platform, and (ii) enter into and comply
                    with these Terms;
                  </li>
                  <li>
                    (b) if acting as an employee or agent of a legal entity, be
                    duly authorized to bind that legal entity to these Terms;
                    and
                  </li>
                  <li>
                    (c) not be located in, or have operations in:
                    <ul>
                      <li>
                        a jurisdiction where it would be unlawful under
                        applicable law for you to access or use the Blind Faith
                        platform or cause us or any third party to contravene
                        any applicable law;
                      </li>
                      <li>
                        a country listed on our List of Prohibited Countries.
                      </li>
                    </ul>
                  </li>
                </ul>
                <br />
                <p>
                  <strong>2.2</strong> We may amend our eligibility criteria at
                  any time, at our sole discretion. Where possible, we will give
                  advance notice, but changes may be required immediately under
                  circumstances such as:
                </p>
                <ul>
                  <li>(a) a legal or regulatory requirement;</li>
                  <li>(b) a change that is in your interest;</li>
                  <li>
                    (c) other valid reasons leaving no time to notify you.
                  </li>
                </ul>
                <br />
                <p>
                  Where immediate changes are made without advance notice, we
                  will notify you as soon as practicable thereafter.
                </p>
                <br />

                <h3>3. Blind Faith Platform</h3>
                <br />
                <p>
                  <strong>3.1</strong> Your usage of the Blind Faith platform is
                  at our sole discretion. We reserve the right to refuse or
                  restrict usage at any time, without obligation to provide
                  reasons.
                </p>
                <br />
                <p>
                  <strong>3.2</strong> You must not post, upload, or publish on
                  the Blind Faith platform any abusive, defamatory, dishonest,
                  or obscene material, nor any content intended to manipulate
                  markets or spread false or misleading information. Violating
                  this rule may result in termination or restricted access.
                </p>
                <br />
                <p>
                  <strong>3.3</strong> You must not post, upload, or publish
                  similar prohibited content on other platforms or media
                  regarding Digital Assets created on the Blind Faith platform,
                  or you may likewise face termination or restricted access.
                </p>
                <br />

                <h3>4. Fees and Calculations</h3>
                <br />
                <p>
                  <strong>4.1</strong> Fees for using the Blind Faith platform
                  can be found on our fee schedule (or otherwise indicated on
                  our website or platform).
                </p>
                <br />
                <p>
                  <strong>4.2</strong> You agree to pay all applicable fees in
                  connection with your use of the Blind Faith platform.
                </p>
                <br />
                <p>
                  <strong>4.3</strong> You authorize us to deduct all applicable
                  fees, commissions, interest, charges, and other sums owed from
                  the wallet (“Wallet”) you connect to the Blind Faith platform.
                </p>
                <br />
                <p>
                  <strong>4.4</strong> We may adjust our fees from time to time
                  under Clause 14.4.
                </p>
                <br />
                <p>
                  <strong>4.5</strong> Any calculations made by Blind Faith
                  relating to your usage of the platform are final and binding
                  in the absence of manifest error.
                </p>
                <br />

                <h3>5. Records</h3>
                <br />
                <p>
                  We maintain your data to facilitate your continued use of the
                  Blind Faith platform and as required by law, including but not
                  limited to tax, accounting, and anti-money-laundering
                  regulations.
                </p>
                <br />

                <h3>6. Accessing the Blind Faith Platform</h3>
                <br />
                <p>
                  <strong>6.1</strong> To access the Blind Faith platform, you
                  must have appropriate equipment (e.g., computer or smartphone)
                  and internet access. Access may be via bots or other methods
                  we permit.
                </p>
                <br />
                <p>
                  <strong>6.2</strong> Additional terms may govern specific
                  methods of access and will be communicated to you accordingly.
                </p>
                <br />

                <h3>7. Transactions</h3>
                <br />
                <p>
                  <strong>7.1</strong> You acknowledge and agree that Blind
                  Faith may cancel or void any transaction (“Transaction”)
                  executed with improper intent or manifest error, as described
                  in these Terms, to the extent allowed by applicable law.
                </p>
                <br />
                <p>
                  <strong>7.2</strong> We may be required by these Terms or
                  applicable law to share information about your activities with
                  third parties or within the Blind Faith group. You acknowledge
                  our right to disclose such information where so required.
                </p>
                <br />

                <h3>8. Submission of Instructions</h3>
                <br />
                <p>
                  <strong>8.1</strong> You are responsible for ensuring that any
                  instruction or request (“Instruction”) you submit is accurate
                  and complete. We are not required to verify its accuracy or
                  validity and may refuse to act on or defer any Instruction if
                  we have doubts.
                </p>
                <br />
                <p>
                  <strong>8.2</strong> Instructions are considered irrevocable
                  once submitted, and are deemed received only when they reach
                  our server. Our records of Instructions are conclusive and
                  binding on you.
                </p>
                <br />
                <p>
                  <strong>8.3</strong> By submitting an Instruction, you
                  authorize us to initiate the relevant Transaction. If you have
                  insufficient Digital Assets in your Wallet to cover a
                  Transaction (including associated fees), we may refuse to
                  proceed. It is your responsibility to maintain sufficient
                  Digital Assets in your Wallet.
                </p>
                <br />
                <p>
                  <strong>8.4</strong> Instructions and data transmitted over
                  the internet can pass through public, transnational networks
                  not specifically protected. You accept the associated risk
                  that such data may be intercepted or accessed by unauthorized
                  parties.
                </p>
                <br />

                <h3>9. Transactions</h3>
                <br />
                <p>
                  <strong>9.1</strong> We do not represent or guarantee that any
                  action you undertake on the Blind Faith platform will complete
                  successfully or within a particular timeframe.
                </p>
                <br />
                <p>
                  <strong>9.2</strong> You agree that we may maintain records of
                  Transactions and related data for as long as needed to fulfill
                  their intended purposes, or as required by applicable law.
                </p>
                <br />

                <h3>10. Material Interests and Conflicts</h3>
                <br />
                <p>
                  <strong>10.1</strong> Nothing in your relationship with Blind
                  Faith, nor any services we provide, imposes fiduciary
                  obligations on us or on any Blind Faith Affiliate. Blind Faith
                  or its Affiliates may act in multiple capacities and receive
                  fees from multiple users, which you acknowledge and accept.
                </p>
                <br />
                <p>
                  <strong>10.2</strong> We are under no obligation to disclose
                  or use certain information for your specific benefit. We may
                  receive general market information as part of providing the
                  Blind Faith platform and can use it freely in our business.
                </p>
                <br />
                <p>
                  <strong>10.3</strong> We have internal measures to identify
                  and manage conflicts of interest, and reserve the right to
                  decline to act for you when conflicts cannot be otherwise
                  managed.
                </p>
                <br />

                <h3>11. Transaction Limits</h3>
                <br />
                <p>
                  We may impose usage or transaction limits at any time at our
                  sole discretion.
                </p>
                <br />

                <h3>12. Security</h3>
                <br />
                <p>
                  <strong>12.1</strong> You are responsible for protecting your
                  hardware, software, and data from malware. Except as required
                  by law, you are also responsible for any backups of
                  information you store or transfer via the Blind Faith
                  platform.
                </p>
                <br />
                <p>
                  <strong>12.2</strong> You must maintain adequate security over
                  all credentials and must:
                </p>
                <ul>
                  <li>(a) follow all our security mechanisms or procedures;</li>
                  <li>
                    (b) never allow remote access or share your device screen
                    while logged in;
                  </li>
                  <li>
                    (c) remember that we will never ask for your password or
                    2-factor authentication code.
                  </li>
                </ul>
                <br />
                <p>
                  <strong>12.3</strong> You must prevent unauthorized access to
                  your credentials.
                </p>
                <br />
                <p>
                  <strong>12.4</strong> Regularly monitor your activity to
                  identify any unauthorized or suspicious actions. A “Security
                  Breach” may cause the loss or theft of any Digital Assets in
                  your Wallet.
                </p>
                <br />
                <p>
                  <strong>12.5</strong> If you suspect or detect a Security
                  Breach:
                </p>
                <ul>
                  <li>
                    (a) notify us immediately, providing accurate and up-to-date
                    information;
                  </li>
                  <li>
                    (b) take other steps we deem necessary to reduce, manage, or
                    report it.
                  </li>
                </ul>
                <br />
                <p>
                  <strong>12.6</strong> We reserve the right to request any
                  information we deem relevant regarding an actual or suspected
                  Security Breach, and to share such information with third
                  parties (e.g., law enforcement) if necessary.
                </p>
                <br />

                <h3>13. Privacy</h3>
                <br />
                <p>
                  <strong>13.1</strong> Our handling of personal data is
                  described in our Privacy Notice, which is incorporated by
                  reference. By using the Blind Faith platform, you acknowledge
                  that your personal data may be processed in connection with
                  these Terms.
                </p>
                <br />
                <p>
                  <strong>13.2</strong> You represent and warrant that:
                </p>
                <ul>
                  <li>
                    (a) you have read and understood our Privacy Notice; and
                  </li>
                  <li>
                    (b) if we provide you with a replacement version of the
                    Privacy Notice, you will promptly review it.
                  </li>
                </ul>
                <br />

                <h3>14. Amending the Terms</h3>
                <br />
                <p>
                  <strong>14.1</strong> We may change these Terms (including
                  referenced policies) at any time, and your continued use of
                  the Blind Faith platform signifies acceptance of those
                  changes. Updated Terms will typically be posted on our website
                  or communicated by other means at our sole discretion.
                </p>
                <br />
                <p>
                  <strong>14.2</strong> We generally attempt to notify users in
                  advance of changes. However, circumstances may require
                  immediate changes (e.g., to comply with legal obligations or
                  to clarify Terms). We will notify you as soon as possible
                  after such changes take effect.
                </p>
                <br />
                <p>
                  <strong>14.3</strong> If you do not wish to accept any
                  updates, discontinue your use of the Blind Faith platform.
                  Otherwise, continued usage will be taken as acceptance of the
                  revised Terms.
                </p>
                <br />
                <p>
                  <strong>14.4</strong> We may also change our fees or introduce
                  new fees. If you do not wish to accept changes to fees,
                  discontinue use of the Blind Faith platform. Continued access
                  or use indicates acceptance of the new fee structure.
                </p>
                <br />

                <h3>15. Termination, Suspensions, Restrictions</h3>
                <br />
                <p>
                  <strong>15.1</strong> We may, at any time, modify or
                  discontinue any portion of the Blind Faith platform. In
                  particular, we may:
                </p>
                <ul>
                  <li>
                    (a) refuse, block, cancel, or reverse any usage or
                    Transaction;
                  </li>
                  <li>(b) terminate, suspend, or restrict your access;</li>
                  <li>
                    (c) refuse to transmit data or instructions to third
                    parties;
                  </li>
                  <li>(d) take any action we consider necessary,</li>
                </ul>
                <p>
                  with immediate effect and for any reason, including (but not
                  limited to):
                </p>
                <ul>
                  <li>
                    (a) you are no longer eligible to use the Blind Faith
                    platform;
                  </li>
                  <li>
                    (b) we suspect fraudulent, illegal, or unauthorized usage;
                  </li>
                  <li>
                    (c) your information is false, outdated, or incomplete;
                  </li>
                  <li>
                    (d) legal or regulatory requirements compel such action;
                  </li>
                  <li>
                    (e) we suspect money laundering, terrorism financing, or
                    other crimes;
                  </li>
                  <li>
                    (f) we perceive a heightened risk of legal or regulatory
                    noncompliance;
                  </li>
                  <li>(g) you owe us amounts unsatisfied through any basis;</li>
                  <li>
                    (h) you have circumvented, or tried to circumvent, our
                    controls without written consent.
                  </li>
                </ul>
                <br />
                <p>
                  We will make reasonable efforts to notify you, if possible.
                </p>
                <br />
                <p>
                  <strong>15.2</strong> Our decision to terminate, suspend, or
                  restrict your usage may be based on confidential criteria
                  essential to our risk management. We are under no obligation
                  to disclose details of our procedures.
                </p>
                <br />
                <p>
                  <strong>15.3</strong> If we terminate or restrict your access:
                </p>
                <ul>
                  <li>
                    (a) any open Instructions or Transactions may be closed by
                    you or by us, depending on the circumstances;
                  </li>
                  <li>
                    (b) you authorize us to deduct costs and fees from any
                    assets in the Wallet connected to the Blind Faith platform.
                  </li>
                </ul>
                <br />
                <p>
                  <strong>15.4</strong> If we believe that Digital Assets in
                  your Wallet are stolen or otherwise not lawfully possessed, we
                  may terminate your access. We will not mediate or resolve
                  ownership disputes regarding Digital Assets.
                </p>
                <br />

                <h3>16. Blind Faith IP</h3>
                <br />
                <p>
                  All intellectual property (“IP”) belonging to or licensed by
                  Blind Faith (“Blind Faith IP”) remains vested in Blind Faith
                  or its licensors.
                </p>
                <br />

                <h3>17. License of Blind Faith IP</h3>
                <br />
                <p>
                  We grant you a non-exclusive license, during the validity of
                  these Terms or until we suspend or terminate your access
                  (whichever is sooner), to use Blind Faith IP solely as needed
                  to access and use the Blind Faith platform for personal,
                  non-commercial purposes, in accordance with these Terms.
                </p>
                <br />

                <h3>18. License of User IP</h3>
                <br />
                <p>
                  <strong>18.1</strong> You grant us a perpetual, irrevocable,
                  royalty-free, non-exclusive, worldwide license to use,
                  reproduce, modify, display, and distribute any intellectual
                  property you own or control (“User IP”) to the extent it:
                </p>
                <ul>
                  <li>
                    (a) forms part of, or is necessary for the use of, any
                    created digital assets (“Created IP”); and
                  </li>
                  <li>
                    (b) is necessary for us to provide or improve the Blind
                    Faith platform.
                  </li>
                </ul>
                <br />
                <p>
                  <strong>18.2</strong> This license includes our right to
                  sublicense to third parties, as required for providing the
                  Blind Faith platform.
                </p>
                <br />

                <h3>19. Created IP</h3>
                <br />
                <p>
                  <strong>19.1</strong> All IP you create pursuant to these
                  Terms (“Created IP”) automatically vests in Blind Faith at the
                  time of creation.
                </p>
                <br />
                <p>
                  <strong>19.2</strong> You hereby assign to us, with full title
                  guarantee, all present and future rights and interest in such
                  Created IP.
                </p>
                <br />
                <p>
                  <strong>19.3</strong> If requested, you shall execute any
                  documents and take actions necessary to perfect the assignment
                  under this clause at your expense.
                </p>
                <br />

                <h3>20. General</h3>
                <br />
                <p>
                  <strong>20.1</strong> Blind Faith is not responsible for any
                  user-generated content (“User Material”), whether provided by
                  you or third parties, on or through the Blind Faith platform.
                  Use such material at your own risk.
                </p>
                <br />
                <p>
                  <strong>20.2</strong> We may remove, modify, or reject any
                  User Material at our sole discretion. We may also suspend or
                  terminate your access or pursue other remedies if you violate
                  these Terms.
                </p>
                <br />
                <p>
                  <strong>20.3</strong> You agree that Blind Faith may record
                  communications (electronic, telephone, chat, etc.) in
                  connection with these Terms. Such records constitute evidence
                  of the communications between you and us.
                </p>
                <br />

                <h3>21. Prohibited Use</h3>
                <br />
                <p>
                  <strong>21.1</strong> By using the Blind Faith platform or
                  completing Transactions, you agree not to:
                </p>
                <ul>
                  <li>(a) breach these Terms;</li>
                  <li>(b) violate any Prohibited Use Policy we issue;</li>
                  <li>
                    (c) use the platform for commercial purposes without our
                    written approval;
                  </li>
                  <li>
                    (d) manipulate, or attempt to manipulate, the market for a
                    Digital Asset;
                  </li>
                  <li>
                    (e) engage in fraudulent, unauthorized, or illegal
                    activities;
                  </li>
                  <li>
                    (f) take or fail to take any action that may result in
                    claims, chargebacks, disputes, or other liabilities for us
                    or others;
                  </li>
                  <li>(g) provide false or misleading information;</li>
                  <li>
                    (h) use bots, spiders, or scrapers to improperly access,
                    copy, or monitor the platform or to tamper with its
                    infrastructure;
                  </li>
                  <li>
                    (i) modify or adapt the Blind Faith platform or
                    reverse-engineer its source code;
                  </li>
                  <li>
                    (j) replicate, duplicate, copy, download, or otherwise
                    exploit the Blind Faith IP beyond the license granted in
                    these Terms;
                  </li>
                  <li>
                    (k) spread viruses, Trojan horses, or other malicious code
                    via the platform;
                  </li>
                  <li>
                    (l) use an anonymizing proxy or any device/software to
                    interfere with the platform’s operations;
                  </li>
                  <li>
                    (m) create any security interest over your Digital Assets
                    without our written consent;
                  </li>
                  <li>
                    (n) violate the IP or privacy rights of any third party or
                    any applicable law;
                  </li>
                  <li>
                    (o) access or use the Blind Faith platform in jurisdictions
                    under sanctions, including (without limitation) those that
                    we consider high-risk or sanctioned by relevant authorities.
                  </li>
                </ul>
                <br />

                <h3>22. Representations and Warranties</h3>
                <br />
                <p>
                  <strong>22.1</strong> You represent and warrant that:
                </p>
                <ul>
                  <li>
                    (a) your decisions and transactions are solely based on your
                    own judgment;
                  </li>
                  <li>
                    (b) you have full capacity to access and use the Blind Faith
                    platform and to perform under these Terms;
                  </li>
                  <li>
                    (c) all necessary consents and authorizations have been
                    obtained to enter into and comply with these Terms,
                    including rights for uploading images or IP to the Blind
                    Faith platform if applicable;
                  </li>
                  <li>
                    (d) these Terms constitute valid, legally binding
                    obligations on you;
                  </li>
                  <li>
                    (e) if you are a legal entity, you are duly organized,
                    validly existing, and have the power to conduct business;
                    and
                  </li>
                  <li>
                    (f) your usage of the platform, and your performance under
                    these Terms, do not breach any law or third-party rights.
                  </li>
                </ul>
                <br />

                <h3>23. Technology Disclaimers</h3>
                <br />
                <p>
                  <strong>23.1</strong> The Blind Faith platform is provided “as
                  is” and “as available,” without any warranties, express or
                  implied. We disclaim any implied warranties of
                  merchantability, fitness for a particular purpose, and
                  non-infringement, to the extent permitted by law. We do not
                  guarantee continuous, uninterrupted, or error-free access.
                </p>
                <br />
                <p>
                  <strong>23.2</strong> We may suspend access for scheduled or
                  emergency maintenance. We do not guarantee processing times
                  for Transactions, which depend on factors beyond our control.
                </p>
                <br />
                <p>
                  <strong>23.3</strong> Although we strive to keep information
                  updated, we make no warranties regarding the accuracy or
                  timeliness of content on the Blind Faith platform.
                </p>
                <br />
                <p>
                  <strong>23.4</strong> The Blind Faith platform may link to
                  external sites or content as a convenience. We are not
                  responsible for third-party content, and you access it at your
                  own risk.
                </p>
                <br />
                <p>
                  <strong>23.5</strong> You are responsible for obtaining data
                  network access and compatible hardware. Blind Faith does not
                  guarantee operation on particular devices, and the platform
                  may be subject to delays inherent to the internet and
                  electronic communications.
                </p>
                <br />

                <h3>24. Indemnity</h3>
                <br />
                <p>
                  <strong>24.1</strong> You agree to indemnify and hold Blind
                  Faith (and its affiliates) harmless from third-party claims,
                  actions, or demands (including attorneys’ fees and other
                  costs) arising from or relating to:
                </p>
                <ul>
                  <li>(a) your use of the Blind Faith platform;</li>
                  <li>(b) your breach of these Terms;</li>
                  <li>(c) any violation of law; or</li>
                  <li>(d) your infringement of others’ rights.</li>
                </ul>
                <br />
                <p>
                  <strong>24.2</strong> You release Blind Faith from any
                  disputes with other users or third parties arising out of your
                  use of the Blind Faith platform.
                </p>
                <br />

                <h3>25. Liability</h3>
                <br />
                <p>
                  <strong>25.1</strong> Neither Blind Faith nor any Blind Faith
                  Affiliate has or will have liability for any loss you or a
                  third party suffer, except to the extent arising solely from
                  our willful misconduct or fraud.
                </p>
                <br />
                <p>
                  <strong>25.2</strong> If liability is established, it is
                  capped at the aggregate fees paid by you to the Blind Faith
                  group for the transaction giving rise to the claim.
                </p>
                <br />
                <p>
                  <strong>25.3</strong> We do not owe special or equitable
                  remedies; damages are your sole remedy.
                </p>
                <br />
                <p>
                  <strong>25.4</strong> Under no circumstances are we or any
                  Blind Faith Affiliate responsible for:
                </p>
                <ul>
                  <li>
                    (a) direct, indirect, or consequential losses, including
                    loss of business or profit;
                  </li>
                  <li>
                    (b) the operation, availability, or functionality of any
                    underlying blockchain, or any “Network Event” outside our
                    control;
                  </li>
                  <li>
                    (c) malicious software, hacks, or unauthorized access if you
                    fail to take reasonable security measures;
                  </li>
                  <li>(d) service interruptions or maintenance delays;</li>
                  <li>(e) fraudulent activities by third parties;</li>
                  <li>
                    (f) any event beyond our reasonable control (Force Majeure
                    Events).
                  </li>
                </ul>
                <br />

                <h3>26. Governing Law</h3>
                <br />
                <p>
                  Except as otherwise specified by applicable law, these Terms
                  (including the arbitration agreement in Clause 27) shall be
                  governed by and construed in accordance with the laws of
                  England.
                </p>
                <br />

                <h3>27. Dispute Resolution</h3>
                <br />
                <p>
                  <strong>27.1</strong> Please contact Blind Faith first to
                  resolve any concerns. We aim to settle disputes internally if
                  possible.
                </p>
                <br />
                <p>
                  <strong>27.2</strong> If still unresolved, submit a “Notice of
                  Claim” describing the dispute to our support channel. After
                  that, either you or we may proceed to arbitration, if
                  necessary.
                </p>
                <br />
                <p>
                  <strong>27.3</strong> Providing this Notice of Claim and
                  attempting resolution is a prerequisite to filing any formal
                  arbitration or legal proceedings.
                </p>
                <br />
                <p>
                  <strong>27.4</strong> Any arbitration shall be administered by
                  the London Court of International Arbitration (“LCIA”),
                  following LCIA rules, seat in London, in English, with a
                  single arbitrator.
                </p>
                <br />
                <p>
                  <strong>27.5</strong> Claims must be commenced within one year
                  of when you first knew or should have known about the event,
                  unless applicable law provides otherwise.
                </p>
                <br />
                <p>
                  <strong>27.6</strong> All arbitration proceedings and related
                  information will be confidential.
                </p>
                <br />

                <h3>28. Class Action Waiver</h3>
                <br />
                <p>
                  You agree that any claims must be brought on an individual
                  basis, not as part of a class or representative action, to the
                  fullest extent permitted by law.
                </p>
                <br />

                <h3>29. Contact</h3>
                <br />
                <p>
                  <strong>29.1</strong> If you have questions, feedback, or
                  complaints, contact us via our Support channel.
                </p>
                <br />
                <p>
                  <strong>29.2</strong> We may contact you using details you
                  provide, or by other methods we deem appropriate (e.g., direct
                  message on social media).
                </p>
                <br />

                <h3>30. General Terms</h3>
                <br />
                <p>
                  <strong>30.1</strong> You must comply with all applicable laws
                  and third-party rights in your use of the Blind Faith
                  platform.
                </p>
                <br />
                <p>
                  <strong>30.2</strong> We may give you notices electronically,
                  and you must follow our directions for sending notices to us.
                  Notices are deemed received unless a delivery failure message
                  is returned.
                </p>
                <br />
                <p>
                  <strong>30.3</strong> Official announcements and news may be
                  posted on social media or on our website. You are responsible
                  for staying updated on these announcements.
                </p>
                <br />
                <p>
                  <strong>30.4</strong> These Terms form the entire agreement
                  between you and Blind Faith regarding the platform,
                  superseding any prior representations.
                </p>
                <br />
                <p>
                  <strong>30.5</strong> You may not transfer or assign your
                  rights or obligations under these Terms without our prior
                  written consent. We may freely assign or transfer ours,
                  including in cases of mergers or reorganizations.
                </p>
                <br />
                <p>
                  <strong>30.6</strong> If any provision of these Terms is held
                  invalid or unenforceable, it does not affect the remaining
                  clauses.
                </p>
                <br />
                <p>
                  <strong>30.7</strong> We may record communications with you
                  (phone, email, chat) for evidentiary or compliance purposes.
                </p>
                <br />
                <p>
                  <strong>30.8</strong> In case of conflicts, the English
                  version of these Terms prevails over any translation.
                </p>
                <br />
                <p>
                  <strong>30.9</strong> Nothing in these Terms confers rights on
                  any third party not party to them, except for Blind Faith
                  Affiliates where stated.
                </p>
                <br />
                <p>
                  <strong>30.10</strong> Provisions that by nature extend beyond
                  termination or expiration will survive.
                </p>
                <br />
                <p>
                  <strong>30.11</strong> Blind Faith is not acting as your
                  agent; no joint venture or partnership is formed by these
                  Terms.
                </p>
                <br />
                <p>
                  <strong>30.12</strong> We are not liable for delays or
                  failures caused by Force Majeure Events.
                </p>
                <br />
                <p>
                  <strong>30.13</strong> Our failure or delay to exercise a
                  right does not waive that right. Rights and remedies are
                  cumulative.
                </p>
                <br />
                <p>
                  <strong>30.14</strong> We may offset amounts owed by you. All
                  amounts owed to us must be paid in full without offset or
                  withholding unless required by law.
                </p>
                <br />
                <p>
                  <strong>30.15</strong> If you obtain information about another
                  user, you must keep it confidential and only use it for lawful
                  transactions with Blind Faith.
                </p>
                <br />
                <p>
                  <strong>30.16</strong> If you breach these Terms, Blind Faith
                  may publish details of the breach to protect other users,
                  where consistent with applicable law.
                </p>
                <br />
                <p>
                  <strong>30.17</strong> You are responsible for determining and
                  remitting any applicable taxes in connection with your use of
                  the Blind Faith platform. We do not collect or remit taxes on
                  your behalf unless required by law.
                </p>
                <br />

                <h3>31. Wallets</h3>
                <br />
                <p>
                  <strong>31.1</strong> Any digital wallet provided via the
                  Blind Faith mobile app (e.g., by a third-party service)
                  remains under that provider’s responsibility and yours.
                </p>
                <br />
                <p>
                  <strong>31.2</strong> Neither Blind Faith nor its affiliates
                  is responsible for the wallet’s operation or for any losses
                  incurred through its use.
                </p>
                <br />

                <h3>32. Definitions and Interpretation</h3>
                <br />
                <p>
                  <strong>32.1</strong> Clause headings are for convenience only
                  and do not affect meaning or priority.
                </p>
                <br />
                <p>
                  <strong>32.2</strong> “Include” or “including” means
                  “including without limitation.”
                </p>
                <br />
                <p>
                  <strong>32.3</strong> References to the singular also apply to
                  the plural, and vice versa. References to a document include
                  that document as amended or varied.
                </p>
                <br />
                <p>
                  <strong>32.4</strong> In the event of inconsistency, the
                  Privacy Notice prevails over these Terms.
                </p>
                <br />
                <p>
                  <strong>32.5</strong> The following definitions apply:
                </p>
                <ul>
                  <li>
                    “Activity History”: The record of your Transactions and
                    activity on the Blind Faith platform.
                  </li>
                  <li>
                    “Applicable Law”: All statutes, laws, regulations,
                    directives, codes, or requirements that apply to the Blind
                    Faith platform or associated activities.
                  </li>
                  <li>
                    “Blind Faith Affiliates”: Any person or entity officially
                    affiliated with Blind Faith in providing the Blind Faith
                    platform.
                  </li>
                  <li>
                    “Claim”: Any dispute, claim, or controversy relating to
                    these Terms, your relationship with us, or Digital Assets.
                  </li>
                  <li>
                    “Created IP”: IP you create under these Terms that vests in
                    Blind Faith upon creation.
                  </li>
                  <li>
                    “Digital Assets”: Digital representations of value or
                    rights, including cryptocurrencies, stablecoins, NFTs, or
                    other tokenized assets.
                  </li>
                  <li>
                    “Force Majeure Event”: Circumstances beyond our reasonable
                    control (e.g., natural disasters, wars, epidemics,
                    cyberattacks) preventing us from performing these Terms.
                  </li>
                  <li>
                    “Instruction”: Any request or order you submit to execute a
                    Transaction or action on the Blind Faith platform.
                  </li>
                  <li>
                    “Manifest Error”: Any clear mistake or omission, including
                    data entry or misquote, that significantly deviates from
                    reasonable market standards.
                  </li>
                  <li>
                    “Network Event”: Any event on the underlying blockchain or
                    smart contract that is beyond Blind Faith’s control, such as
                    forks or consensus failures.
                  </li>
                  <li>
                    “Privacy Notice”: The privacy policy describing how Blind
                    Faith handles personal data.
                  </li>
                  <li>
                    “User IP”: IP owned or licensed by you, excluding Blind
                    Faith IP, that you upload or use on the Blind Faith
                    platform.
                  </li>
                  <li>
                    “Wallet”: A digital asset wallet connected to the Blind
                    Faith platform.
                  </li>
                  <li>
                    “Website”: Our official website hosting the Blind Faith
                    platform or related information.
                  </li>
                </ul>
              </GroupBox>
            </SectionContent>

            <Button onClick={() => setSelectedToolbar("home")}>Back</Button>
          </>
        );

      case "fees":
        return (
          <>
            <SectionContent>
              <GroupBox
                variant="flat"
                style={{
                  maxHeight: "500px",
                  overflowY: "auto",

                  padding: "0.5rem",
                }}
              >
                <h2>Fees</h2>
                <br />
                <p>
                  The total maximum fee on the Blind Faith platform is{" "}
                  <strong>5%</strong>. Below is the current fee breakdown
                  (though it may change at any time, it will never exceed 5% in
                  total):
                </p>
                <br />
                <ul>
                  <li>
                    <strong>Protocol Fee (2%)</strong>: <br />
                    May be used to buy tickets in existing competitions.
                  </li>
                  <li>
                    <strong>Marketing Fee (1%)</strong>: <br />
                    Goes directly to marketing agencies or influencers we
                    partner with.
                  </li>
                  <li>
                    <strong>Creator Fee (1%)</strong>: <br />
                    Automatically sent to the creator's wallet.
                  </li>
                  <li>
                    <strong>Global Jackpot Fee (1%)</strong>: <br />
                    Allows users to “top off” any remaining amount they need to
                    purchase a ticket.
                  </li>
                </ul>
                <br />
                <p>
                  While these percentages can be adjusted, the overall fee pool
                  will never exceed 5%.
                </p>
              </GroupBox>
            </SectionContent>

            <Button onClick={() => setSelectedToolbar("home")}>Back</Button>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Overlay>
      <PopupWrapper>
        <Window>
          <HeaderContent>
            Blind_Faith.eXe
            <Button disabled style={{ marginLeft: "auto" }}>
              X
            </Button>
          </HeaderContent>
          {/* Replace Toolbar with our responsive version */}
          <ResponsiveToolbar>
            <ResponsiveButton
              variant="menu"
              size="sm"
              active={selectedToolbar === "home"}
              onClick={() => setSelectedToolbar("home")}
            >
              Home
            </ResponsiveButton>
            <ResponsiveButton
              variant="menu"
              size="sm"
              active={selectedToolbar === "privacy"}
              onClick={() => setSelectedToolbar("privacy")}
            >
              Privacy Policy
            </ResponsiveButton>
            <ResponsiveButton
              variant="menu"
              size="sm"
              active={selectedToolbar === "tos"}
              onClick={() => setSelectedToolbar("tos")}
            >
              Terms of Service
            </ResponsiveButton>
            <ResponsiveButton
              variant="menu"
              size="sm"
              active={selectedToolbar === "fees"}
              onClick={() => setSelectedToolbar("fees")}
            >
              Fees
            </ResponsiveButton>
          </ResponsiveToolbar>
          <CenteredWindowContent>{renderMainContent()}</CenteredWindowContent>
        </Window>
      </PopupWrapper>
    </Overlay>
  );
};

export default IntroPopup;
